import {Button, FormGroup} from '@blueprintjs/core';
import {DateInput, TimePrecision} from '@blueprintjs/datetime';
import {Ice} from 'ice';
import moment from 'moment';
import React, {useMemo} from 'react';

import {Gazebo} from '@slices/Gazebo';

import {useTournamentPrx, useTournamentState} from '../../../store/tournament';
import {create} from '../../../utils/create';
import {getClasses} from '../../../utils/css';
import {Form} from '../../../utils/form';
import {useIdempotenceKey} from '../../../utils/idempotencyKey';
import {ResponseErrorToast} from '../../../utils/toast';
import {useCancelContext} from '../../../utils/useCancelContext';
import {useRequest} from '../../../utils/useRequest';

type AbstractCommandRequirement =
  Gazebo.Tournaments.CommandRequirement.AbstractCommandRequirement;
type AbstractCommand = Gazebo.Tournaments.Command.AbstractCommand;
export type CommandRequirementsStateParams = {
  tournamentId: string;
  clubId?: string;
};

const dateTimeFormat = 'DD.MM.YYYY HH:mm:ss';
const classes = getClasses({
  form: {
    padding: 15,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  submit: {
    width: 100,
  },
  commands: {
    display: 'flex',
    overflow: 'auto',
    justifyContent: 'flex-start',
    height: 'max-content',
    flexWrap: 'wrap',
    $nest: {
      '& > *': {
        margin: 5,
      },
    },
  },
  command: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export const CommandRequirements = ({
  tournamentId,
  clubId,
}: CommandRequirementsStateParams) => {
  const {applyCommand} = useTournamentPrx(tournamentId);
  const {commandRequirements} = useTournamentState();

  const requirements = useMemo(
    () => commandRequirements.data ?? [],
    [commandRequirements.data],
  );

  if (requirements.length === 0) {
    return null;
  }

  return (
    <div className={classes.commands}>
      {requirements.map((commandRequirement) => {
        if (
          commandRequirement instanceof
          Gazebo.Tournaments.CommandRequirement.Register
        ) {
          return (
            <SimpleCommand
              className={classes.command}
              text="Register"
              command={create(Gazebo.Tournaments.Command.Register, {
                referrerId: create(Gazebo.Tournaments.ReferrerId, {
                  clubId,
                }),
              })}
              applyCommand={applyCommand}
            />
          );
        }
        if (
          commandRequirement instanceof
          Gazebo.Tournaments.CommandRequirement.Unregister
        ) {
          return (
            <SimpleCommand
              className={classes.command}
              text="Unregister"
              command={create(Gazebo.Tournaments.Command.Unregister, {})}
              applyCommand={applyCommand}
            />
          );
        }
        if (
          commandRequirement instanceof
          Gazebo.Tournaments.CommandRequirement.TopUp
        ) {
          return (
            <SimpleCommand
              className={classes.command}
              text="Top up"
              command={create(Gazebo.Tournaments.Command.TopUp, {})}
              applyCommand={applyCommand}
            />
          );
        }
        if (
          commandRequirement instanceof
          Gazebo.Tournaments.CommandRequirement.AcceptRebuyRequest
        ) {
          return (
            <SimpleCommand
              className={classes.command}
              text="Accept Rebuy"
              command={create(
                Gazebo.Tournaments.Command.AcceptRebuyRequest,
                {},
              )}
              applyCommand={applyCommand}
            />
          );
        }
        if (
          commandRequirement instanceof
          Gazebo.Tournaments.CommandRequirement.DeclineRebuyRequest
        ) {
          return (
            <SimpleCommand
              className={classes.command}
              text="Decline Rebuy"
              command={create(
                Gazebo.Tournaments.Command.DeclineRebuyRequest,
                {},
              )}
              applyCommand={applyCommand}
            />
          );
        }
        if (
          commandRequirement instanceof
          Gazebo.Tournaments.CommandRequirement.CloseTournament
        ) {
          return (
            <SimpleCommand
              className={classes.command}
              text="Close Tournament"
              command={create(Gazebo.Tournaments.Command.CloseTournament, {})}
              applyCommand={applyCommand}
            />
          );
        }
        if (
          commandRequirement instanceof
          Gazebo.Tournaments.CommandRequirement.AcceptRebuyRequest
        ) {
          return (
            <SimpleCommand
              className={classes.command}
              text="Accept rebuy"
              command={create(
                Gazebo.Tournaments.Command.AcceptRebuyRequest,
                {},
              )}
              applyCommand={applyCommand}
            />
          );
        }
        if (
          commandRequirement instanceof
          Gazebo.Tournaments.CommandRequirement.DeclineRebuyRequest
        ) {
          return (
            <SimpleCommand
              className={classes.command}
              text="Decline rebuy"
              command={create(
                Gazebo.Tournaments.Command.DeclineRebuyRequest,
                {},
              )}
              applyCommand={applyCommand}
            />
          );
        }
        if (
          commandRequirement instanceof
          Gazebo.Tournaments.CommandRequirement.TopUp
        ) {
          return (
            <SimpleCommand
              className={classes.command}
              text="Top up"
              command={create(Gazebo.Tournaments.Command.TopUp, {})}
              applyCommand={applyCommand}
            />
          );
        }
        if (
          commandRequirement instanceof
          Gazebo.Tournaments.CommandRequirement.ChangeStartTime
        ) {
          return (
            <ChangeStartTimeForm
              className={classes.command}
              minStartDate={
                new Date(
                  commandRequirement.earliestPossibleStartAtMs.toNumber(),
                )
              }
              applyCommand={applyCommand}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

type SimpleCommandParams = {
  text: string;
  command: AbstractCommand;
  applyCommand: (
    idempotenceKey: string,
    command: AbstractCommand,
    ctx?: Ice.Context,
  ) => Promise<Gazebo.Tournaments.ApplyCommandResult>;
  className?: string;
};
const SimpleCommand: React.FC<SimpleCommandParams> = ({
  text,
  command,
  applyCommand,
  className,
}) => {
  const [ctx] = useCancelContext();
  const key = useIdempotenceKey([]);
  const [response, doCommand] = useRequest(
    () => applyCommand(key, command),
    [applyCommand, key, command],
    ctx,
  );
  return (
    <div className={className}>
      <Button onClick={doCommand}>{text}</Button>
      <ResponseErrorToast response={response} />
    </div>
  );
};

type ChangeStartTimeFormParams = {
  minStartDate: Date;
  applyCommand: SimpleCommandParams['applyCommand'];
  className?: string;
};
const ChangeStartTimeForm = ({
  minStartDate,
  applyCommand,
  className,
}: ChangeStartTimeFormParams) => {
  const [startDate, setStartDate] = React.useState<Date>(minStartDate);
  const handleStartDateChange = React.useCallback(
    (newStartDate: Date) => {
      setStartDate(newStartDate);
    },
    [setStartDate],
  );

  return (
    <div className={className}>
      <SimpleCommand
        text={'Change Start Time'}
        command={create(Gazebo.Tournaments.Command.ChangeStartTime, {
          startAtMs: new Ice.Long(startDate.getTime()),
        })}
        applyCommand={applyCommand}
      />
      <Form className={classes.form}>
        <FormGroup label="New start date">
          <DateInput
            formatDate={(date) => moment(date).format(dateTimeFormat)}
            closeOnSelection={false}
            onChange={handleStartDateChange}
            parseDate={(str) => moment(str, dateTimeFormat).toDate()}
            placeholder={'YYYY-MM-DD HH:mm:ss (moment)'}
            timePickerProps={{
              precision: TimePrecision.SECOND,
              showArrowButtons: true,
            }}
            defaultValue={startDate}
            minDate={minStartDate}
          />
        </FormGroup>
      </Form>
    </div>
  );
};
