import {Button, Dialog, InputGroup, Intent} from '@blueprintjs/core';
import * as React from 'react';

import {useInputState} from 'src/utils/inputState';

import {useTableRatingsPrx} from '../../store/tableRatings';
import {getClasses} from '../../utils/css';
import {useIdempotenceKey} from '../../utils/idempotencyKey';
import {ResponseErrorToast} from '../../utils/toast';
import {useCancelContext} from '../../utils/useCancelContext';
import {useRequest} from '../../utils/useRequest';

const classes = getClasses({
  dialog: {
    width: 200,
  },
  input: {
    padding: 15,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  rateTableButton: {
    position: 'absolute',
    top: 150,
    zIndex: 10,
  },
  rateTableSubmit: {
    marginTop: 15,
    width: 50,
  },
});

export const RateTableDialog: React.FunctionComponent<{tableId: string}> = ({
  tableId,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const handleClose = React.useCallback(() => setIsOpen(false), [setIsOpen]);
  const validateAmount = React.useCallback((value: number) => {
    if (value > 1 || value < 0) {
      return 'Amount must be in [0;1]';
    }
  }, []);
  const [ctx] = useCancelContext();
  const [onChangeAmount, amount] = useInputState(
    '1',
    parseFloat,
    validateAmount,
  );
  const {rateTable} = useTableRatingsPrx();
  const key = useIdempotenceKey([Date.now()]);
  const [rateTableRequest, doRateTable] = useRequest(
    () => {
      setIsOpen(false);
      return rateTable(key, tableId, amount.value);
    },
    [key, tableId, amount, rateTable],
    ctx,
  );
  return (
    <>
      <Button className={classes.rateTableButton} onClick={handleOpen}>
        Rate table
      </Button>
      <Dialog
        isOpen={isOpen}
        title="Rate table"
        onClose={handleClose}
        className={classes.dialog}
      >
        <div className={classes.input}>
          <InputGroup onChange={onChangeAmount} value={amount.rawValue} />
          <Button
            disabled={amount.error != null}
            className={classes.rateTableSubmit}
            type="submit"
            intent={Intent.PRIMARY}
            onClick={doRateTable}
          >
            Submit
          </Button>
          <ResponseErrorToast response={rateTableRequest} />
        </div>
      </Dialog>
    </>
  );
};
