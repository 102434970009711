import * as React from 'react';

import {UserGamesProvider} from 'src/store/userGames';

import {Content} from './content';
import {Layout} from './layout';

export const ActiveGames: React.FunctionComponent = () => {
  return (
    <UserGamesProvider>
      <Layout>
        <Content />
      </Layout>
    </UserGamesProvider>
  );
};
