import {
  Button,
  Classes,
  Colors,
  Dialog,
  Icon,
  Menu,
  MenuItem,
  Spinner,
  Tag,
  TextArea,
} from '@blueprintjs/core';
import {Popover2, Tooltip2} from '@blueprintjs/popover2';
import classnames from 'classnames';
import {formatDistanceToNowStrict} from 'date-fns';
import {Ice} from 'ice';
import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {useUserInfoPrx} from 'src/store/userInfo';

import {getResponseData, useSubscription} from '../../../ice-client-react';
import {useClubPlayerGameStatsSubscription} from '../../../store/clubs';
import {useTableState} from '../../../store/table';
import {useUserRelationsPrx} from '../../../store/userRelations';
import {create} from '../../../utils/create';
import {getClasses} from '../../../utils/css';
import {useIdempotenceKey} from '../../../utils/idempotencyKey';
import {centsToMainUnits} from '../../../utils/numbers';
import {ResponseErrorToast} from '../../../utils/toast';
import {useCancelContext} from '../../../utils/useCancelContext';
import {useRequest} from '../../../utils/useRequest';
import {cardsStyles} from '../../tableUtils/cards';
import {CircleProgress} from '../../tableUtils/circleProgress';
import {useClick} from '../../tableUtils/clicker';

import {Execute, PlaceStyle, Side} from '.';
import {getReactionText, useIceLong} from './utils';

const classes = getClasses({
  seatWrapper: {
    position: 'absolute',
    width: 70,
    height: 70,
  },
  seat: {
    border: '1px solid black!important',
    borderRadius: 100,
    height: '100%',
    overflow: 'hidden',
  },
  availableSeat: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.DARK_GRAY5,
    cursor: 'pointer',
    height: '100%',
  },
  freeSeat: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: Colors.GRAY1,
  },
  occupiedSeat: {
    position: 'relative',
    height: '100%',
    width: '100%',
    $nest: {
      '& > *': {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
      },
    },
  },
  seatStatus: {
    borderRadius: 10,
    display: 'inline',
    height: 'auto',
    zIndex: 1,
    textAlign: 'center',
    minWidth: '100%',
    width: 'auto',
    padding: '0 5px 0 5px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  avatarImg: {
    width: '100%',
    height: '100%',
  },
  nickname: {
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '100%',
    width: 'auto',
    alignItems: 'center',
    margin: '0 5px',
    $nest: {
      '&.left': {
        left: 'unset',
        right: '100%',
      },
    },
  },
  playerCards: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'flex-end',
    top: '70%',
    right: 0,
    $nest: {
      '& > *': {
        minWidth: 20,
        height: 30,
        fontSize: 11,
        position: 'relative',
      },
      '&[data-are-cards-folded="true"]': {
        $nest: {
          '& > *': {
            background: Colors.GRAY1,
            opacity: 0.7,
          },
          '& > *::after, & > *::before': {
            color: Colors.DARK_GRAY1,
          },
        },
      },
      '&[data-is-hero-cards="true"]': {
        left: '-100%',
        top: 0,
        alignItems: 'flex-end',
        transform: 'translateX(-10px)',
        $nest: {
          '&  > *': {
            minWidth: 40,
            height: 60,
            fontSize: 20,
            fontWeight: 600,
          },
        },
      },
    },
  },
  card: {
    border: '1px solid black!important',
    borderRadius: 5,
    background: 'white',
    overflow: 'hidden',
  },
  openedCard: {
    $nest: {
      '&::before': {
        position: 'absolute',
        top: 0,
        left: '8%',
        fontSize: '1em',
      },
      '&::after': {
        position: 'absolute',
        bottom: 0,
        right: '8%',
        fontSize: '1.5em',
      },
      ...cardsStyles,
    },
  },
  closedCard: {
    backgroundColor: Colors.FOREST3,
    backgroundImage:
      'repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255,255,255,.5) 5px, rgba(255,255,255,.5) 10px)',
  },
  colorTag: {
    position: 'absolute',
    zIndex: 1000,
    top: '-10px',
    right: 0,
    width: '10px!important',
    borderRadius: '10px',
  },
});

export const Seats: React.SFC<{
  heroSeatIndex?: number;
  seatsLayout: PlaceStyle[];
  seatsOrder: number[];
  hand?: Gazebo.Tables.Poker.Hand;
  seats: Gazebo.Tables.Poker.SeatSeq;
  canReserveSeat: boolean;
  execute: Execute;
  clubId: string | undefined;
  availableReactions: Gazebo.Tables.Poker.AbstractReactionSeq | undefined;
  relations: Array<Gazebo.UserRelations.AbstractRelation | undefined>;
}> = ({
  seatsLayout,
  seatsOrder,
  seats,
  hand,
  canReserveSeat,
  execute,
  heroSeatIndex,
  clubId,
  availableReactions,
  relations,
}) => {
  return (
    <>
      {seatsOrder.map((index, viewPosition) => (
        <Seat
          key={index}
          isHeroSeat={heroSeatIndex === seats[index].seatIndex}
          seatLayout={seatsLayout[viewPosition]}
          seat={seats[index]}
          hand={hand}
          execute={execute}
          canReserveSeat={canReserveSeat}
          clubId={clubId}
          availableReactions={
            heroSeatIndex === seats[index].seatIndex
              ? availableReactions
              : undefined
          }
          relations={relations}
        />
      ))}
    </>
  );
};

const Seat: React.SFC<{
  isHeroSeat: boolean;
  seatLayout: PlaceStyle;
  seat: Gazebo.Tables.Poker.Seat;
  hand?: Gazebo.Tables.Poker.Hand;
  execute: Execute;
  canReserveSeat: boolean;
  clubId: string | undefined;
  availableReactions: Gazebo.Tables.Poker.AbstractReactionSeq | undefined;
  relations: Array<Gazebo.UserRelations.AbstractRelation | undefined>;
}> = ({
  seatLayout,
  seat,
  canReserveSeat,
  execute,
  isHeroSeat,
  clubId,
  availableReactions,
  relations,
}) => {
  let content: React.ReactNode;
  if (seat instanceof Gazebo.Tables.Poker.FreeSeat) {
    if (canReserveSeat) {
      content = (
        <AvailableSeat
          execute={execute}
          seatIndex={seat.seatIndex}
          clubId={clubId}
        />
      );
    } else {
      content = <FreeSeat />;
    }
  } else if (seat instanceof Gazebo.Tables.Poker.OccupiedSeat) {
    content = (
      <OccupiedSeat
        seat={seat}
        viewPosition={seatLayout.side}
        isHeroSeat={isHeroSeat}
        availableReactions={availableReactions}
        relations={relations}
      />
    );
  } else if (seat instanceof Gazebo.Tables.Poker.ReservedSeat) {
    content = <ReservedSeat />;
  } else {
    throw new Error('Unexpected seat class');
  }
  return (
    <div style={seatLayout.style} className={classes.seatWrapper}>
      {content}
    </div>
  );
};

const AvailableSeat: React.SFC<{
  execute: Execute;
  seatIndex: number;
  clubId: string | undefined;
}> = ({execute, seatIndex, clubId}) => {
  const [ctx] = useCancelContext();
  const key = useIdempotenceKey([seatIndex]);
  const [response, doRequest] = useRequest(
    () => {
      return execute(
        key,
        create(Gazebo.Tables.Poker.Command.Reserve, {
          seatIndex,
          referrerId: create(Gazebo.Tables.Poker.ReferrerId, {clubId}),
        }),
      );
    },
    [execute, seatIndex, key, clubId],
    ctx,
  );

  useClick(
    doRequest,
    Gazebo.Tables.Poker.Command.Reserve.ice_staticId().split('::').pop(),
  );

  const onAir = response?.type === 'started';
  return (
    <div className={classes.seat}>
      <div className={classes.availableSeat} onClick={doRequest}>
        {!onAir && <Icon icon="plus" />}
        {onAir && <Spinner size={25} />}
        <ResponseErrorToast response={response} />
      </div>
    </div>
  );
};

const FreeSeat: React.SFC = () => {
  return (
    <div className={classes.seat}>
      <div className={classes.freeSeat}>Empty</div>
    </div>
  );
};

const ReservedSeat: React.SFC = () => {
  return (
    <div className={classes.seat}>
      <div className={classes.freeSeat}>Reserved</div>
    </div>
  );
};

const OccupiedSeat: React.SFC<{
  isHeroSeat: boolean;
  seat: Gazebo.Tables.Poker.OccupiedSeat;
  viewPosition: Side;
  availableReactions: Gazebo.Tables.Poker.AbstractReactionSeq | undefined;
  relations: Array<Gazebo.UserRelations.AbstractRelation | undefined>;
}> = ({seat, viewPosition, isHeroSeat, availableReactions, relations}) => {
  const {subscribeToUsers} = useUserInfoPrx();
  const subscribeToUserInfos = React.useCallback(
    (subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.UserInfo.User>) =>
      subscribeToUsers(subscriber, [seat.playerId]),
    [subscribeToUsers, seat.playerId],
  );

  const userInfosSubscriptionState = useSubscription(subscribeToUserInfos);
  const response = getResponseData(userInfosSubscriptionState)?.[0];
  const statusMessage = response?.statusMessage ?? '';
  const countryCode = response?.countryCode ?? 'unknown';

  const inGameStack = useIceLong(seat.inGameStack, Number.NaN);

  const colorTag = React.useMemo(
    () =>
      relations?.find(
        (relation): relation is Gazebo.UserRelations.ColorTag =>
          relation instanceof Gazebo.UserRelations.ColorTag &&
          relation.userId === seat.playerId,
      ),
    [relations, seat.playerId],
  );

  const textNote = React.useMemo(
    () =>
      relations?.find(
        (relation): relation is Gazebo.UserRelations.TextNote =>
          relation instanceof Gazebo.UserRelations.TextNote &&
          relation.userId === seat.playerId,
      )?.text ?? '',
    [relations, seat.playerId],
  );

  const isFollowed = React.useMemo(
    () =>
      relations?.find(
        (relation): relation is Gazebo.UserRelations.FollowedByHero =>
          relation instanceof Gazebo.UserRelations.FollowedByHero &&
          relation.userId === seat.playerId,
      ) != null,
    [relations, seat.playerId],
  );

  const playerGameStats = useClubPlayerGameStatsSubscription(
    seat.clubId,
    seat.playerId,
    'cash',
  );
  const vpip = playerGameStats?.vpip.toNumber() ?? 'unknown';
  const clubName = playerGameStats?.clubName ?? 'unknown';
  const handCount = playerGameStats?.handCount.toNumber() ?? 'unknown';

  const [isReportDialogOpen, setIsReportDialogOpen] = React.useState(false);
  const handleReportDialogOpen = React.useCallback(() => {
    setIsReportDialogOpen(true);
  }, [setIsReportDialogOpen]);
  const handleReportDialogClose = React.useCallback(
    () => setIsReportDialogOpen(false),
    [setIsReportDialogOpen],
  );
  const [isColorTagDialogOpen, setColorTagDialogOpen] = React.useState(false);
  const handleTagDialogOpen = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      setColorTagDialogOpen(true);
    },
    [setColorTagDialogOpen],
  );
  const handleTagDialogClose = React.useCallback(
    () => setColorTagDialogOpen(false),
    [setColorTagDialogOpen],
  );
  const [isTextNoteDialogOpen, setTextNoteDialogOpen] = React.useState(false);
  const handleTextNoteDialogOpen = React.useCallback(
    () => setTextNoteDialogOpen(true),
    [setTextNoteDialogOpen],
  );
  const handleTextNoteDialogClose = React.useCallback(
    () => setTextNoteDialogOpen(false),
    [setTextNoteDialogOpen],
  );

  const playerMenu = (
    <Menu>
      <MenuItem text={`Country ${countryCode}`} disabled={true} />
      <MenuItem text={`Club ${clubName}`} disabled={true} />
      <MenuItem text={`VPIP ${vpip}`} disabled={true} />
      <MenuItem text={`Hands played ${handCount}`} disabled={true} />
      <MenuItem text="Text note" onClick={handleTextNoteDialogOpen} />
      <Follow targetUserId={seat.playerId} isFollowed={isFollowed} />
      <MenuItem text="Report..." onClick={handleReportDialogOpen} />
      {availableReactions?.map((reaction) => (
        <MenuItem
          key={'Reaction' + getReactionText(reaction)}
          text={getReactionText(reaction)}
        />
      )) ?? null}
    </Menu>
  );

  return (
    <div className={classes.occupiedSeat}>
      {seat.seatStatus && (
        <SeatStatus status={seat.seatStatus} potType={seat?.winner?.potType} />
      )}
      <ReportDialog
        isOpen={isReportDialogOpen}
        onClose={handleReportDialogClose}
        playerId={seat.playerId}
        clubId={seat.clubId}
      />
      <ChangeColorTagDialog
        isOpen={isColorTagDialogOpen}
        onClose={handleTagDialogClose}
        playerId={seat.playerId}
      />
      <SetTextNoteDialog
        isOpen={isTextNoteDialogOpen}
        onClose={handleTextNoteDialogClose}
        currentText={textNote}
        playerId={seat.playerId}
      />
      <Popover2 content={playerMenu}>
        <Tooltip2 content={statusMessage}>
          <div className={classes.seat}>
            <TurnTimer timer={seat.timer}>
              <div>
                <div className={classes.avatar}>
                  {seat.avatar ? (
                    <AvatarPicture avatar={seat.avatar} />
                  ) : (
                    'Avatar'
                  )}
                </div>
                <Tag
                  style={{backgroundColor: colorTag?.color ?? Colors.GRAY1}}
                  interactive={true}
                  onClick={handleTagDialogOpen}
                  fill={true}
                  className={classes.colorTag}
                />
              </div>
            </TurnTimer>
          </div>
        </Tooltip2>
      </Popover2>
      <Cards
        seatIndex={seat.seatIndex}
        pocketCards={seat.pocketCards}
        isHeroSeat={isHeroSeat}
        combination={seat.winner?.combination}
      />
      <div className={classnames(viewPosition, classes.nickname)}>
        {seat.nickname}
        {!Number.isNaN(inGameStack) && (
          <div>{centsToMainUnits(inGameStack)}</div>
        )}
      </div>
    </div>
  );
};

const ChangeColorTagDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  playerId: string;
}> = ({isOpen, onClose, playerId}) => {
  const [ctx] = useCancelContext();
  const {setRelation} = useUserRelationsPrx();

  const availableColors = [Colors.RED1, Colors.BLUE1, Colors.DARK_GRAY1];

  const [setColorTagResponse, doSetColorTag] = useRequest(
    async (color: string) => {
      const colorTagRequest = create(Gazebo.UserRelations.SetColorTag, {
        color,
        targetUserId: playerId,
      });
      await setRelation(Ice.generateUUID(), colorTagRequest);
    },
    [setRelation, playerId],
    ctx,
  );

  return (
    <>
      <ResponseErrorToast response={setColorTagResponse} />
      <Dialog isOpen={isOpen} onClose={onClose} title="Set color tag">
        <div className={Classes.DIALOG_BODY}>
          {availableColors.map((color) => (
            <Tag
              style={{backgroundColor: color}}
              onClick={() => {
                doSetColorTag(color);
              }}
              key={color}
              interactive={true}
              large={true}
            />
          ))}
        </div>
      </Dialog>
      {}
    </>
  );
};

const SetTextNoteDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  playerId: string;
  currentText: string;
}> = ({isOpen, onClose, playerId, currentText}) => {
  const {setRelation} = useUserRelationsPrx();
  const [ctx] = useCancelContext();

  const [textNote, setTextNote] = React.useState('');
  const handleTextNoteChange = React.useCallback(
    (event: React.FormEvent<HTMLElement>) =>
      setTextNote((event.target as HTMLInputElement).value),
    [setTextNote],
  );

  const [setTextNoteResponse, doSetTextNote] = useRequest(
    async () => {
      const setTextNote = create(Gazebo.UserRelations.SetTextNote, {
        targetUserId: playerId,
        textNote,
      });
      await setRelation(Ice.generateUUID(), setTextNote);
    },
    [playerId, textNote, setRelation],
    ctx,
  );

  return (
    <>
      <ResponseErrorToast response={setTextNoteResponse} />
      <Dialog isOpen={isOpen} onClose={onClose} title="Text note">
        <div className={Classes.DIALOG_BODY}>
          <TextArea
            fill={true}
            onChange={handleTextNoteChange}
            defaultValue={currentText}
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button className={Classes.INTENT_PRIMARY} onClick={doSetTextNote}>
              Set text note
            </Button>
          </div>
        </div>
      </Dialog>
      {}
    </>
  );
};

const Follow: React.FC<{targetUserId: string; isFollowed: boolean}> = ({
  targetUserId,
  isFollowed,
}) => {
  const {setRelation} = useUserRelationsPrx();
  const [ctx] = useCancelContext();
  const idempotencyKey = useIdempotenceKey([targetUserId, isFollowed]);

  const [relationResponse, doSetRelation] = useRequest(
    () =>
      setRelation(
        idempotencyKey,
        isFollowed
          ? create(Gazebo.UserRelations.Unfollow, {targetUserId})
          : create(Gazebo.UserRelations.Follow, {targetUserId}),
      ),
    [setRelation, idempotencyKey, targetUserId, isFollowed],
    ctx,
  );
  return (
    <>
      <ResponseErrorToast response={relationResponse} />
      <MenuItem
        text={isFollowed ? 'Unfollow' : 'Follow'}
        onClick={doSetRelation}
      />
    </>
  );
};

const ReportDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  playerId: string;
  clubId: string | undefined;
}> = ({isOpen, onClose, playerId, clubId}) => {
  const {sendComplaint, getComplaintTimeout} = useUserRelationsPrx();
  const [ctx] = useCancelContext();

  const [getComplainTimeoutResponse, doGetComplaintTimeout] = useRequest(
    async () => await getComplaintTimeout(playerId),
    [getComplaintTimeout, playerId],
    ctx,
  );
  React.useEffect(() => {
    if (isOpen) {
      doGetComplaintTimeout();
    }
  }, [isOpen, doGetComplaintTimeout]);
  const complaintTimeout = getResponseData<
    Gazebo.UserRelations.ComplaintTimeout | undefined | null
  >(getComplainTimeoutResponse);

  const [reportText, setReportText] = React.useState('');
  const handleReportTextChange = React.useCallback(
    (event: React.FormEvent<HTMLElement>) =>
      setReportText((event.target as HTMLInputElement).value),
    [setReportText],
  );

  const [remainingTime, setRemainingTime] = React.useState<number | undefined>(
    undefined,
  );

  React.useEffect(() => {
    const endAtMs = complaintTimeout?.endAtMs.toNumber();
    if (endAtMs == null) {
      setRemainingTime(undefined);
      return;
    }
    setRemainingTime(endAtMs - Date.now());
    const timer = setInterval(() => {
      if (endAtMs < Date.now() || !isOpen) {
        setRemainingTime(undefined);
        clearInterval(timer);
        return;
      }
      setRemainingTime(endAtMs - Date.now());
    }, 1000);
    return () => {
      setRemainingTime(undefined);
      clearInterval(timer);
    };
  }, [complaintTimeout, setRemainingTime, isOpen]);

  const tableResponse = useTableState();
  const tableId = getResponseData(tableResponse)?.map(({id}) => id)[0];
  const [sendReportResponse, sendReport] = useRequest(
    async () => {
      logger.debug({tableId}, 'Sending complaint');
      if (tableId == null) {
        return;
      }
      await sendComplaint(
        Ice.generateUUID(),
        create(Gazebo.UserRelations.Complaint, {
          text: reportText,
          complaineeUserId: playerId,
          context: create(Gazebo.UserRelations.TableComplaintContext, {
            tableId,
            complaineeClubId: clubId,
          }),
        }),
      );
      onClose();
    },
    [reportText, clubId, playerId, sendComplaint, tableId, onClose],
    ctx,
  );

  return (
    <>
      <ResponseErrorToast response={sendReportResponse} />
      <Dialog isOpen={isOpen} onClose={onClose} title="Report user">
        <div className={Classes.DIALOG_BODY}>
          <TextArea fill={true} onChange={handleReportTextChange} />
          {remainingTime != null ? (
            <div className={Classes.TEXT_MUTED}>
              You can send report in{' '}
              {formatDistanceToNowStrict(Date.now() + remainingTime)}
            </div>
          ) : null}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              className={Classes.INTENT_PRIMARY}
              onClick={sendReport}
              disabled={remainingTime != null}
            >
              Report
            </Button>
          </div>
        </div>
      </Dialog>
      {}
    </>
  );
};

const AvatarPicture: React.FunctionComponent<{avatar: string}> = ({avatar}) => {
  let uri = avatar;
  if (avatar.substring(0, 8) == 'local://') {
    try {
      uri = require(`../../../resources/${avatar.substring(
        8,
        avatar.length,
      )}.png`);
    } catch (err) {
      uri = avatar;
    }
  }
  return <img className={classes.avatarImg} src={uri} />;
};

const TurnTimer: React.SFC<{timer?: Gazebo.Tables.Poker.TurnTimer}> = ({
  children,
  timer,
}) => {
  const end = useIceLong(timer?.endAtMs, Number.NaN);
  const start = useIceLong(timer?.startAtMs, Number.NaN);
  if (timer == null) {
    return <>{children}</>;
  }
  return (
    <CircleProgress start={start} end={end} current={Date.now()}>
      {children}
    </CircleProgress>
  );
};

const SeatStatus: React.SFC<{
  status: Gazebo.Tables.Poker.SeatStatus;
  potType: Gazebo.Tables.Poker.PotWinnerType | undefined;
}> = ({status, potType}) => {
  const statusName = React.useMemo(() => status.name, [status]);
  const potTypeName = React.useMemo(() => potType?.name, [potType]);

  const style = React.useMemo(() => {
    if (statusName === 'Sitout') {
      return {backgroundColor: Colors.RED3};
    }
    if (statusName === 'Fold' || statusName === 'CardsMucked') {
      return {backgroundColor: Colors.GRAY3};
    }
    if (
      statusName === 'Bet' ||
      statusName === 'AllIn' ||
      statusName === 'Raise'
    ) {
      return {backgroundColor: Colors.GOLD3, color: Colors.DARK_GRAY1};
    }
    if (statusName === 'Winner' && potTypeName === 'MainPot') {
      return {backgroundColor: Colors.GOLD3, color: Colors.DARK_GRAY1};
    }
    if (statusName === 'Winner' && potTypeName === 'SidePot') {
      return {backgroundColor: Colors.FOREST3, color: Colors.WHITE};
    }
    if (statusName === 'CardsShown') {
      return {backgroundColor: Colors.GREEN3};
    }
    return {backgroundColor: Colors.BLUE3};
  }, [statusName, potTypeName]);
  return (
    <div style={style} className={classes.seatStatus}>
      {status.name}
    </div>
  );
};

const Cards: React.FC<{
  pocketCards?: Gazebo.Tables.Poker.PocketCards;
  isHeroSeat: boolean;
  seatIndex: number;
  combination?: Gazebo.Tables.Poker.CardSeq;
}> = ({pocketCards, isHeroSeat, combination, seatIndex}) => {
  if (pocketCards == null) {
    return null;
  }
  return (
    <div
      className={classes.playerCards}
      data-is-hero-cards={isHeroSeat}
      data-are-cards-folded={pocketCards.areCardsFolded}
    >
      {pocketCards.cards.map((card, index) => (
        <PocketCard
          key={index}
          card={card}
          isInCombination={
            combination?.some((item) => item.equals(card.card))
              ? seatIndex
              : undefined
          }
        />
      ))}
    </div>
  );
};

const PocketCard: React.SFC<{
  card: Gazebo.Tables.Poker.PocketCard;
  isInCombination?: number;
}> = ({isInCombination, card}) => {
  if (card.card?.name != null) {
    return (
      <div
        className={classnames(card.card.name, classes.openedCard, classes.card)}
        data-is-in-combination={isInCombination}
      />
    );
  }
  return <div className={classnames(classes.closedCard, classes.card)} />;
};
