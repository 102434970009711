import {Button, Dialog, FormGroup, InputGroup} from '@blueprintjs/core';
import * as React from 'react';

import {getClasses} from '../utils/css';
import {useIdempotenceKey} from '../utils/idempotencyKey';
import {parseString, skipValidation, useInputState} from '../utils/inputState';
import {ResponseErrorToast} from '../utils/toast';
import {useCancelContext} from '../utils/useCancelContext';
import {useRequest} from '../utils/useRequest';

import {useTableMaintenancePrx} from '../store/tableMaintenance';

const killTableClasses = getClasses({
  form: {
    padding: 15,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  inputGroup: {
    width: 350,
  },
  dialog: {
    width: 450,
    gridTemplateColumns: '70px 250px',
  },
  submit: {
    width: 100,
  },
  kill: {
    borderRadius: 50,
    position: 'fixed',
    top: 30,
    left: 'calc(50vw + 250px)',
    zIndex: 100,
  },
});

export const KillTable: React.FunctionComponent = () => {
  const {killTable} = useTableMaintenancePrx();
  const [ctx] = useCancelContext();
  const [isOpened, setIsOpened] = React.useState<boolean>(false);
  const handleOpen = React.useCallback(() => {
    setIsOpened(true);
  }, [setIsOpened]);
  const [onTableIdChanged, tableId, setTableId] = useInputState(
    '',
    parseString,
    skipValidation,
  );
  const key = useIdempotenceKey([tableId.rawValue]);

  const [killTableResponse, doKillTable] = useRequest(
    async () => {
      const killingTableId = tableId.rawValue.includes('#')
        ? tableId.rawValue.slice(1)
        : tableId.rawValue;
      await killTable(key, killingTableId);
      setTableId({rawValue: '', value: ''});
      setIsOpened(false);
    },
    [key, killTable, tableId.rawValue, setTableId, setIsOpened],
    ctx,
  );

  const onAir = killTableResponse?.type === 'started';

  const handleClosed = React.useCallback(() => {
    if (!onAir) {
      setIsOpened(false);
    }
  }, [setIsOpened, onAir]);

  return (
    <>
      <Button
        className={killTableClasses.kill}
        onClick={handleOpen}
        icon="trash"
      />
      <Dialog
        isOpen={isOpened}
        className={killTableClasses.dialog}
        onClose={handleClosed}
        title="Kill table"
      >
        <FormGroup
          className={killTableClasses.form}
          inline={true}
          labelFor="text-input"
          label="Table ID"
        >
          <InputGroup
            className={killTableClasses.inputGroup}
            autoFocus={true}
            value={tableId.rawValue}
            id="text-input"
            onChange={onTableIdChanged}
          />
        </FormGroup>
        <ResponseErrorToast response={killTableResponse} />
        <Button
          className={killTableClasses.submit}
          loading={onAir}
          type="submit"
          onClick={doKillTable}
        >
          Kill
        </Button>
      </Dialog>
    </>
  );
};
