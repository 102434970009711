import {pick} from 'lodash';
import * as React from 'react';

import {KillTable} from '../../components/killTable';
import {getResponseData, getResponseError} from '../../ice-client-react';
import {useClubs} from '../../store/clubs';
import {useAllTables} from '../../store/tables';
import {nullableToList} from '../../utils/emptyList';

import {Swimlane, Tables, tablesClasses} from './tables';

const GLOBAL_AREA = 'global';

const sortGroupComparer = (
  a: [string, Tables],
  b: [string, Tables],
): number => {
  const [name1] = a;
  const [name2] = b;

  if (name1.toLowerCase() == GLOBAL_AREA) {
    return -1;
  }
  if (name2.toLowerCase() == GLOBAL_AREA) {
    return 1;
  }

  return name1.localeCompare(name2);
};

// Группировка по ариям стола. Для клубов - форматирование по имени и айди.
//Столы из global сортируются наверх списка.
export const AllTablesManagement: React.FunctionComponent = () => {
  const clubsResponse = useClubs();

  const clubs = nullableToList(getResponseData(clubsResponse));

  const allAreasLobby = useAllTables();
  const error = getResponseError(allAreasLobby);
  const rawTables = getResponseData(allAreasLobby);

  const tables = React.useMemo(() => {
    const groups: Record<string, Tables> = {};
    const clubsData = new Map(
      clubs.map((x) => [x.clubId, pick(x, ['name', 'displayId'])]),
    );

    for (const table of rawTables ?? []) {
      let groupKey = table.areaId;

      if (clubsData.size && table.areaId.startsWith('club')) {
        const club = clubsData.get(table.areaId)!;
        if (club != null) {
          groupKey = `${club.name} ID: (${club.displayId})`;
        }
      }

      groups[groupKey] = groups[groupKey] ?? [];
      groups[groupKey].push(table);
    }

    return [...Object.entries(groups)].sort(sortGroupComparer);
  }, [rawTables, clubs]);

  if (error != null) {
    return <div>error</div>;
  }

  return (
    <div className={tablesClasses.root}>
      <KillTable />
      {tables.map(([groupKey, tables]) => (
        <Swimlane
          key={groupKey}
          header={groupKey}
          subHeader={tables?.[0].areaId}
          tables={tables}
        />
      ))}
    </div>
  );
};
