import * as React from 'react';

import {getClasses} from '../../utils/css';

import {NavigationBar} from './navigationBar';

const classes = getClasses({
  root: {
    maxWidth: '100vw',
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  page: {
    paddingTop: 55,
    maxWidth: '100vw',
    maxHeight: '100vh',
  },
  content: {
    display: 'flex',
    maxWidth: '100vw',
    maxHeight: '100vh',
    overflow: 'auto',
    flexDirection: 'row',
    justifyContent: 'center',
    $nest: {
      '&>*': {
        maxWidth: 900,
      },
    },
  },
  contentWidth: {
    width: 700,
  },
});

export const Layout: React.FunctionComponent = ({children}) => {
  return (
    <div className={classes.root}>
      <NavigationBar />
      <section className={classes.page}>
        <div className={classes.content}>
          <div className={classes.contentWidth}>{children}</div>
        </div>
      </section>
    </div>
  );
};
