import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {useIceProxy, useSubscription} from '../ice-client-react';

export const useTables = (areaIds: string[]) => {
  const {subscribeToTables} = useIceProxy<Gazebo.Lobby.ServicePrx>({
    type: 'static',
    propertyName: 'Webapp.Lobby',
    proxyClass: Gazebo.Lobby.ServicePrx,
  });

  const subscribe = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Lobby.PokerTable>,
    ) => {
      return subscribeToTables(subscriber, areaIds);
    },
    [subscribeToTables, areaIds],
  );
  return useSubscription(subscribe);
};

export const useAllTables = () => {
  const {subscribeToAllTables} = useIceProxy<Gazebo.Lobby.ServicePrx>({
    type: 'static',
    propertyName: 'Webapp.Lobby',
    proxyClass: Gazebo.Lobby.ServicePrx,
  });

  const subscribe = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Lobby.PokerTable>,
    ) => {
      return subscribeToAllTables(subscriber);
    },
    [subscribeToAllTables],
  );
  return useSubscription(subscribe);
};
