import {
  Alignment,
  Button,
  Menu,
  MenuDivider,
  MenuItem,
  Navbar,
  Popover,
  Position,
} from '@blueprintjs/core';
import * as React from 'react';

import {useAuthContext} from './auth';

import {MenuItemRouteTo} from './menuItemRouteTo';

import {getClasses} from './utils/css';

import {lobbyIcon} from './icons';

const classes = getClasses({
  navbar: {
    position: 'fixed',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  navbarFirstItem: {
    flex: '0 0 2em',
    padding: '0 1em 0 0',
  },
});

export const NavigationBarMain: React.FunctionComponent = ({children}) => {
  const auth = useAuthContext();
  const logout = React.useCallback(() => {
    auth.logout();
  }, [auth]);

  return (
    <Navbar className={classes.navbar}>
      <Navbar.Group align={Alignment.LEFT} className={classes.navbarFirstItem}>
        <Popover minimal position={Position.BOTTOM}>
          <Button icon="menu" />
          {/* меню навигации по разделам сайта */}
          <Menu>
            <MenuItemRouteTo icon={lobbyIcon} text="Lobby" to="/" />
            <MenuItemRouteTo
              icon="dollar"
              text="Cash Flow Inspector"
              to="/cash-flow-inspector"
            />
            <MenuItemRouteTo
              icon="list-detail-view"
              text="Active games"
              to="/active-games"
            />
            <MenuItemRouteTo icon="globe-network" text="Admin" to="/admin" />
            <MenuItemRouteTo icon="calendar" text="Promos" to="/promos" />
            <MenuItemRouteTo icon="graph" text="Changelog" to="/changelog" />
            <MenuDivider />
            <MenuItem text="Log out" icon="log-out" onClick={logout} />
          </Menu>
        </Popover>
      </Navbar.Group>
      {children}
    </Navbar>
  );
};
