import {Gazebo} from '@slices/Gazebo';

export const getStatusText = (status?: Gazebo.Lobby.AbstractTournamentStatus) =>
  status == null
    ? undefined
    : status instanceof Gazebo.Lobby.TournamentRegistrationOpen
    ? 'Registration open'
    : status instanceof Gazebo.Lobby.TournamentRegistrationClosed
    ? 'Registration is closed'
    : status instanceof Gazebo.Lobby.TournamentInProgress
    ? 'In progress'
    : status instanceof Gazebo.Lobby.TournamentGamesFinished
    ? 'Games finished'
    : undefined;
