import {
  Alignment,
  Button,
  Card,
  Checkbox,
  Classes,
  Colors,
  Dialog,
  FormGroup,
  H4,
  H5,
  Icon,
  InputGroup,
  Intent,
  MenuItem,
  Position,
  Switch,
  Text,
  Tooltip,
} from '@blueprintjs/core';
import {ItemRenderer, Select} from '@blueprintjs/select';

import {Ice} from 'ice';
import moment from 'moment';
import * as React from 'react';
import {cssRule} from 'typestyle';

import {Gazebo} from '@slices/Gazebo';

import {createGameOptions} from 'src/utils/createGameOptions';

import {KillTable} from '../../components/killTable';
import {
  AVAILABLE_GAME_TYPES,
  DEFAULT_GAME_TYPE,
  GameType,
} from '../../constants';
import {getResponseData, getResponseError} from '../../ice-client-react';
import {useClubsPrx} from '../../store/clubs';
import {useLeaguesPrx} from '../../store/league';
import {useLobbyManagerPrx} from '../../store/lobbyManager';
import {useTablePrx} from '../../store/table';
import {useTableMaintenancePrx} from '../../store/tableMaintenance';
import {useTables} from '../../store/tables';
import {create} from '../../utils/create';
import {createGameType} from '../../utils/createGameType';
import {getClasses} from '../../utils/css';
import {Form} from '../../utils/form';
import {useIdempotenceKey} from '../../utils/idempotencyKey';
import {
  parseCents,
  parseInteger,
  parseString,
  skipValidation,
  useInputState,
} from '../../utils/inputState';
import {
  centsToMainUnits,
  formatRakeCap,
  formatRakeSize,
  parseRakeCap,
  parseRakeSize,
} from '../../utils/numbers';
import {Stopper} from '../../utils/Stopper';
import {createTableSizeValidator} from '../../utils/tableSizeValidator';
import {ResponseErrorToast} from '../../utils/toast';
import {useCancelContext} from '../../utils/useCancelContext';
import {useRequest} from '../../utils/useRequest';

export const tablesClasses = getClasses({
  root: {
    display: 'grid',
    gridGap: 25,
  },
  swimlaneContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 5,
  },
  swimlaneHeader: {
    padding: '5px 10px',
    letterSpacing: 2,
  },
  swimlaneSubHeader: {
    color: Colors.GRAY4,
    fontSize: '10px',
    padding: '3px 10px',
  },
  tableCard: {
    display: 'grid',
    gridGap: 5,
    maxWidth: 230,
    position: 'relative',
    $nest: {
      [`& .${Classes.ICON}`]: {
        marginRight: 10,
      },
    },
  },
  tableCardHeader: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: 5,
  },
  tableCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 10,
    marginRight: '0!important',
    cursor: 'pointer',
  },
  tableKillButton: {
    top: 0,
    right: 0,
    padding: 0,
    marginRight: '0!important',
    cursor: 'pointer',
  },
});

const GameSelect = Select.ofType<string>();
const defaultAutoRenewalEnabled = false;
const defaultBombPotEnabled = true;
const defaultBombPotActivatesDoubleBoard = true;
const defaultIsDoubleBoardEnabled = false;
const defaultShouldCheckParameters = true;

export type Tables = Gazebo.Lobby.PokerTable[];
export const TablesManagement: React.FunctionComponent = () => {
  const [onClubIdentityChanged, clubIdentity] = useInputState(
    '',
    parseString,
    skipValidation,
  );
  const [onLeagueIdentityChanged, leagueIdentity] = useInputState(
    '',
    parseString,
    skipValidation,
  );
  const [ctx] = useCancelContext();

  const {findClubByIdentity} = useClubsPrx();
  const {findLeagueByIdentity} = useLeaguesPrx();

  const [findClubResponse, doFindClub] = useRequest(
    () =>
      findClubByIdentity(
        isNaN(+clubIdentity.rawValue)
          ? create(Gazebo.Clubs.UUID, {clubId: clubIdentity.rawValue})
          : create(Gazebo.Clubs.DisplayId, {displayId: clubIdentity.rawValue}),
      ),
    [clubIdentity.rawValue, findClubByIdentity],
    ctx,
  );

  const [findLeagueResponse, doFindLeague] = useRequest(
    () =>
      findLeagueByIdentity(
        isNaN(+leagueIdentity.rawValue)
          ? create(Gazebo.Leagues.UUID, {leagueId: leagueIdentity.rawValue})
          : create(Gazebo.Leagues.DisplayId, {
              displayId: leagueIdentity.rawValue,
            }),
      ),
    [leagueIdentity.rawValue, findLeagueByIdentity],
    ctx,
  );

  React.useEffect(() => doFindClub(), [clubIdentity.rawValue, doFindClub]);
  React.useEffect(
    () => doFindLeague(),
    [leagueIdentity.rawValue, doFindLeague],
  );

  const clubData = getResponseData(findClubResponse);
  const leagueData = getResponseData(findLeagueResponse);

  const findLeagueError = getResponseError(findLeagueResponse);
  const findClubError = getResponseError(findClubResponse);

  if (findLeagueError) {
    logger.warn({findLeagueError}, 'Error, when finding league');
  }
  if (findClubError) {
    logger.warn({findClubError}, 'Error, when finding club');
  }

  const area = React.useMemo(() => {
    const area: string[] = [];
    if (leagueData && leagueIdentity.rawValue !== '') {
      area.push(leagueData.leagueId);
    }
    if (clubData && clubIdentity.rawValue !== '') {
      area.push(clubData.clubId);
    }
    if (!leagueData && leagueIdentity.rawValue !== '') {
      return [];
    }
    if (!clubData && clubIdentity.rawValue !== '') {
      return [];
    }
    if (area.length === 0) {
      return ['global'];
    }
    return area;
  }, [clubData, leagueIdentity.rawValue, clubIdentity.rawValue, leagueData]);

  const areaLobby = useTables(area);
  const error = getResponseError(areaLobby);
  const rawTables = getResponseData(areaLobby);

  const tables = React.useMemo(() => {
    const groups: Record<string, Tables> = {};
    for (const table of rawTables ?? []) {
      const limit = `${centsToMainUnits(
        table.smallBlind?.toNumber(),
      )}/${centsToMainUnits(table.bigBlind?.toNumber())}`;
      groups[limit] = groups[limit] ?? [];
      groups[limit].push(table);
    }
    return [...Object.entries(groups)].sort(
      ([limit1], [limit2]) => -limit1.localeCompare(limit2),
    );
  }, [rawTables]);

  if (error != null) {
    return <div>Error</div>;
  }
  return (
    <div className={tablesClasses.root}>
      Area:
      <InputGroup
        value={leagueIdentity.rawValue}
        id="text-input"
        onChange={onLeagueIdentityChanged}
        placeholder="League ID or display ID"
      />
      <InputGroup
        value={clubIdentity.rawValue}
        id="text-input"
        onChange={onClubIdentityChanged}
        placeholder="Club ID or display ID"
      />
      <KillTable />
      <CreateTable />
      {tables.map(([limit, tables]) => (
        <Swimlane key={limit} header={limit} subHeader="" tables={tables} />
      ))}
    </div>
  );
};

export const Swimlane: React.FunctionComponent<{
  header: string;
  tables: Tables;
  subHeader: string;
}> = ({header, tables, subHeader}) => {
  return (
    <div>
      <H4>
        <div className={tablesClasses.swimlaneHeader}>{header}</div>
        {subHeader?.length > 0 && (
          <span className={tablesClasses.swimlaneSubHeader}>{subHeader}</span>
        )}
      </H4>
      <div className={tablesClasses.swimlaneContent}>
        {tables.map((table) => (
          <TableCard key={table.tableId} table={table} />
        ))}
      </div>
    </div>
  );
};

export const renderGameType: ItemRenderer<string> = (
  gameType,
  {handleClick},
) => {
  return <MenuItem key={gameType} onClick={handleClick} text={gameType} />;
};

export const formatCents = (value?: Ice.Long) =>
  centsToMainUnits(value?.toNumber());

export const TableCard: React.FunctionComponent<{
  table: Gazebo.Lobby.PokerTable;
}> = ({table}) => {
  const {killTable} = useTableMaintenancePrx();
  const [ctx] = useCancelContext();
  const key = useIdempotenceKey([table.tableId]);

  const {execute} = useTablePrx(table.tableId);
  const [closeTableResponse, doCloseTable] = useRequest(
    () => {
      return execute(key, create(Gazebo.Tables.Poker.Command.CloseTable, {}));
    },
    [key, execute],
    ctx,
  );

  const rake =
    table.rake instanceof Gazebo.Lobby.TotalPotRake ? table.rake : undefined;

  const [isStopperOpened, setIsStopperOpened] = React.useState<boolean>(false);
  const [isKillStopperOpened, setIsStopperKillOpened] =
    React.useState<boolean>(false);
  const isTableFinished = table.status instanceof Gazebo.Lobby.TableFinished;

  const handleStopperOpen = React.useCallback(() => {
    setIsStopperOpened(true);
  }, [setIsStopperOpened]);
  const handleStopperClose = React.useCallback(() => {
    setIsStopperOpened(false);
  }, [setIsStopperOpened]);

  const handleKillStopperOpen = React.useCallback(() => {
    setIsStopperKillOpened(true);
  }, [setIsStopperKillOpened]);
  const handleKillStopperClose = React.useCallback(() => {
    setIsStopperKillOpened(false);
  }, [setIsStopperKillOpened]);

  const [killTableResponse, doKillTable] = useRequest(
    () => {
      return killTable(key, table.tableId);
    },
    [key, killTable, table.tableId],
    ctx,
  );
  return (
    <Card className={tablesClasses.tableCard}>
      <Icon
        className={tablesClasses.tableCloseButton}
        icon="cross"
        onClick={handleStopperOpen}
      />
      <Stopper
        title={'Close table'}
        text={`Are you sure you want to close table ${table.name}?`}
        doRequest={doCloseTable}
        handleStopperClose={handleStopperClose}
        isOpened={isStopperOpened && !isTableFinished}
      />
      <H5 className={tablesClasses.tableCardHeader}>
        {table.name}
        <Text className={Classes.TEXT_MUTED}>{`#${table.tableId}`}</Text>
      </H5>
      <TableStatus status={table.status} />
      <span>
        <Icon icon="settings" />{' '}
        {table.gameType +
          (table.gameOptions?.bombPot ? ' B' : '') +
          (table.gameOptions?.doubleBoard ? ' DB' : '')}
      </span>
      <span>
        <Icon icon="eye-off" />
        {`${formatCents(table.smallBlind)} / ${formatCents(table.bigBlind)}`}
      </span>
      <span>
        <Icon icon="user" /> {table.playerCount} / {table.size}
      </span>
      <span>
        <Icon icon="dollar" /> Rake: {formatRakeSize(rake?.size ?? 0)},{' '}
        {formatRakeCap(rake?.cap?.toNumber() ?? 0)}
      </span>
      <span>
        <Icon icon="time" />
        {table.finishedAtMs == null
          ? `closing ${moment(
              (table.createdAtMs?.toNumber() ?? 0) +
                (table.durationMs?.toNumber() ?? 0),
            ).fromNow()} / created ${moment(
              table.createdAtMs?.toNumber(),
            ).fromNow()}`
          : `closed ${moment(
              table.finishedAtMs.toNumber(),
            ).fromNow()} / created ${moment(
              table.createdAtMs?.toNumber(),
            ).fromNow()}`}
      </span>
      <Icon
        className={tablesClasses.tableKillButton}
        icon="trash"
        onClick={handleKillStopperOpen}
      />
      <Stopper
        title={'Close table'}
        text={`Are you sure you want to KILL table ${table.name}?`}
        doRequest={doKillTable}
        handleStopperClose={handleKillStopperClose}
        isOpened={isKillStopperOpened && !isTableFinished}
      />
      <ResponseErrorToast response={closeTableResponse} />
      <ResponseErrorToast response={killTableResponse} />
    </Card>
  );
};

export const TableStatus: React.FunctionComponent<{
  status: Gazebo.Lobby.PokerTable['status'];
}> = ({status}) => {
  if (status instanceof Gazebo.Lobby.TableNotStarted) {
    return (
      <span>
        <Icon intent={Intent.NONE} icon="info-sign" />
        Not started
      </span>
    );
  }
  if (status instanceof Gazebo.Lobby.TableStarted) {
    return (
      <span>
        <Icon intent={Intent.SUCCESS} icon="info-sign" />
        Started
      </span>
    );
  }
  if (status instanceof Gazebo.Lobby.TableFinished) {
    return (
      <span>
        <Icon intent={Intent.PRIMARY} icon="info-sign" />
        Finished
      </span>
    );
  }
  if (status instanceof Gazebo.Lobby.TableBroken) {
    return (
      <span>
        <Icon intent={Intent.DANGER} icon="info-sign" />
        Broken
      </span>
    );
  }
  return null;
};

const createTableClasses = getClasses({
  form: {
    padding: 15,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  formGroup: {
    display: 'grid',
    gridTemplateColumns: '100px 240px',
  },
  dialog: {
    width: 370,
  },
  limit: {
    display: 'grid',
    gridTemplateColumns: '1fr 15px 1fr 1fr',
    alignItems: 'center',
    justifyItems: 'center',
    maxWidth: 200,
  },
  submit: {
    width: 100,
  },
  checkParams: {
    width: 150,
  },
  fee: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    maxWidth: 250,
  },
  create: {
    borderRadius: 50,
    position: 'fixed',
    top: 30,
    left: 'calc(50vw + 310px)',
    zIndex: 100,
  },
});

cssRule('@media only screen and (max-width: 780px)', {
  [`.${createTableClasses.create}`]: {
    top: 'unset',
    left: 'unset',
    bottom: 10,
    right: 10,
  },
});

cssRule('@media only screen and (max-width: 500px)', {
  [`.${tablesClasses.swimlaneContent}`]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

const CreateTable: React.FunctionComponent = () => {
  const [isOpened, setIsOpened] = React.useState<boolean>(false);
  const {createPokerTable} = useLobbyManagerPrx();
  const [ctx] = useCancelContext();
  const [onNameChanged, name, setName] = useInputState(
    '',
    parseString,
    validateTableName,
  );

  name.error = validateTableName(name.value);

  const [isSbBoundToBb, setIsSbBoundToBb] = React.useState<boolean>(true);
  const [isAutoRenewalEnabled, setIsAutoRenewalEnabled] =
    React.useState<boolean>(defaultAutoRenewalEnabled);
  const [isBombPotEnabled, setIsBombPotEnabled] = React.useState<boolean>(
    defaultBombPotEnabled,
  );
  const [bombPotActivatesDoubleBoard, setBombPotActivatesDoubleBoard] =
    React.useState<boolean>(defaultBombPotActivatesDoubleBoard);
  const [isDoubleBoardEnabled, setIsDoubleBoardEnabled] =
    React.useState<boolean>(defaultIsDoubleBoardEnabled);
  const [shouldCheckParameters, setShouldCheckParameters] =
    React.useState<boolean>(defaultShouldCheckParameters);
  const [onSbChanged, sb, setSb] = useInputState(
    '0.01',
    parseCents,
    skipValidation,
  );
  const [onBbChanged, bb] = useInputState('0.02', parseCents, skipValidation);
  const [onDurationChanged, duration] = useInputState(
    '6',
    parseFloat,
    validateDuration,
  );

  React.useEffect(() => {
    if (isSbBoundToBb && !Number.isNaN(bb.value) && bb.value > 0.01) {
      const sb = Math.round(bb.value / 2);
      setSb({value: sb, error: '', rawValue: centsToMainUnits(sb)});
    }
  }, [bb, setSb, isSbBoundToBb]);

  let limitError: string | undefined = undefined;
  if (Number.isNaN(sb.value) || sb.value <= 0) {
    limitError = 'small blind must be a positive number';
  }
  if (Number.isNaN(bb.value) || bb.value < 1) {
    limitError = 'big blind must be a number greater or equal to 0.01';
  }
  if (sb.value > bb.value) {
    limitError = 'small blind must be less or equal to big blind';
  }

  const [gameType, setGameType] = React.useState<GameType>(DEFAULT_GAME_TYPE);
  const handleGameTypeChange = React.useCallback(
    (newGame: unknown) => {
      setGameType(newGame as GameType);
    },
    [setGameType],
  );

  const [onSizeChanged, size] = useInputState(
    '6',
    parseInteger,
    createTableSizeValidator(gameType).validate,
  );

  const [onBombPotPeriodChange, bombPotPeriod] = useInputState(
    '3',
    parseInteger,
    validateBombPotPeriod,
  );

  const [onBombPotAnteSizeChange, bombPotAnteSize] = useInputState(
    '10',
    parseInteger,
    validateBombPotAnteSize,
  );

  const [onFeeChanged, fee] = useInputState('0', parseRakeSize, skipValidation);

  const [onRakeCapChanged, rakeCap] = useInputState(
    '0.5',
    parseRakeCap,
    skipValidation,
  );

  const hasErrors =
    shouldCheckParameters &&
    !!(
      name.error ||
      limitError ||
      size.error ||
      duration.error ||
      (isBombPotEnabled && (bombPotPeriod.error || bombPotAnteSize.error))
    );

  const key = useIdempotenceKey([
    gameType,
    name,
    sb,
    bb,
    size,
    isBombPotEnabled,
    bombPotPeriod,
    bombPotAnteSize,
    bombPotActivatesDoubleBoard,
    isDoubleBoardEnabled,
  ]);
  const [createTableResponse, doCreateTable] = useRequest(
    () => {
      if (hasErrors) {
        throw new Error('Input error');
      }

      const gameOwnerId = create(Gazebo.LobbyManager.Owner.PlatformId, {});
      return createPokerTable(
        key,
        create(Gazebo.LobbyManager.TableParams, {
          name: name.value,
          size: size.value,
          gameOptions: createGameOptions({
            bombPot: isBombPotEnabled
              ? {
                  period: bombPotPeriod.value,
                  sizeInBB: bombPotAnteSize.value,
                  activatesDoubleBoard: bombPotActivatesDoubleBoard,
                }
              : undefined,
            doubleBoard: isDoubleBoardEnabled,
          }),
          rake:
            fee.value > 0
              ? create(Gazebo.LobbyManager.TotalPotRake, {
                  size: fee.value,
                  cap: new Ice.Long(rakeCap.value),
                })
              : undefined,
          gameType: createGameType(gameType),
          isAutoRenewalEnabled,
          smallBlind: sb.value == null ? undefined : new Ice.Long(sb.value),
          bigBlind: bb.value == null ? undefined : new Ice.Long(bb.value),
          durationMs: new Ice.Long(1000 * 60 * 60 * duration.value),
          gameOwnerId,
        }),
      );
    },
    [
      key,
      name,
      size,
      duration,
      sb,
      bb,
      hasErrors,
      gameType,
      fee,
      rakeCap,
      createPokerTable,
      isAutoRenewalEnabled,
      isBombPotEnabled,
      bombPotPeriod,
      bombPotAnteSize,
      isDoubleBoardEnabled,
      bombPotActivatesDoubleBoard,
    ],
    ctx,
  );

  React.useEffect(() => {
    if (createTableResponse?.type === 'data') {
      setName({rawValue: '', value: ''});
      setIsOpened(false);
    }
  }, [createTableResponse, setName, setIsOpened]);

  const onAir = createTableResponse?.type === 'started';

  const handleOpen = React.useCallback(() => {
    setIsOpened(true);
  }, [setIsOpened]);
  const handleClosed = React.useCallback(() => {
    if (!onAir) {
      setIsOpened(false);
    }
  }, [setIsOpened, onAir]);

  const handleAutorenewalChange = React.useCallback(() => {
    setIsAutoRenewalEnabled(!isAutoRenewalEnabled);
  }, [setIsAutoRenewalEnabled, isAutoRenewalEnabled]);
  const handleBombPotEnabledChange = React.useCallback(() => {
    setIsBombPotEnabled(!isBombPotEnabled);
  }, [setIsBombPotEnabled, isBombPotEnabled]);
  const handleBombPotActivatesDoubleBoardChange = React.useCallback(() => {
    setBombPotActivatesDoubleBoard(!bombPotActivatesDoubleBoard);
  }, [setBombPotActivatesDoubleBoard, bombPotActivatesDoubleBoard]);
  const handleDoubleBoardEnabledChange = React.useCallback(() => {
    setIsDoubleBoardEnabled(!isDoubleBoardEnabled);
  }, [setIsDoubleBoardEnabled, isDoubleBoardEnabled]);
  const handleParametersCheckingChange = React.useCallback(() => {
    setShouldCheckParameters(!shouldCheckParameters);
  }, [setShouldCheckParameters, shouldCheckParameters]);
  const handleSbBoundToBb = React.useCallback(() => {
    setIsSbBoundToBb(true);
  }, [setIsSbBoundToBb]);
  const handleSbUnboundFromBb = React.useCallback(() => {
    setIsSbBoundToBb(false);
  }, [setIsSbBoundToBb]);

  return (
    <>
      <Button
        className={createTableClasses.create}
        intent={Intent.PRIMARY}
        large={true}
        onClick={handleOpen}
        icon="plus"
      />
      <Dialog
        isOpen={isOpened}
        className={createTableClasses.dialog}
        onClose={handleClosed}
        title="Create global table"
      >
        <Form className={createTableClasses.form}>
          <Switch
            className={createTableClasses.checkParams}
            label={'Check params'}
            alignIndicator={Alignment.LEFT}
            checked={shouldCheckParameters}
            onChange={handleParametersCheckingChange}
            defaultChecked={defaultShouldCheckParameters}
            innerLabel={'no'}
            innerLabelChecked={'yes'}
          />
          <FormGroup
            className={createTableClasses.formGroup}
            inline={true}
            labelFor="text-input"
            label="Name"
            helperText={name.error}
          >
            <InputGroup
              autoFocus={true}
              value={name.rawValue}
              id="text-input"
              onChange={onNameChanged}
            />
          </FormGroup>
          <FormGroup
            className={createTableClasses.formGroup}
            inline={true}
            labelFor="text-input"
            label="Game"
          >
            <GameSelect
              filterable={false}
              items={AVAILABLE_GAME_TYPES}
              onItemSelect={handleGameTypeChange}
              itemRenderer={renderGameType}
            >
              <Button text={gameType} rightIcon="caret-down" />
            </GameSelect>
          </FormGroup>
          <FormGroup
            className={createTableClasses.formGroup}
            inline={true}
            labelFor="text-input"
            label="Limit"
            helperText={limitError}
          >
            <div className={createTableClasses.limit}>
              <InputGroup
                disabled={isSbBoundToBb}
                value={sb.rawValue}
                id="text-input"
                onChange={onSbChanged}
              />
              /
              <InputGroup
                value={bb.rawValue}
                id="text-input"
                onChange={onBbChanged}
              />
              <Button
                minimal={true}
                icon={isSbBoundToBb ? 'unlock' : 'lock'}
                onClick={
                  isSbBoundToBb ? handleSbUnboundFromBb : handleSbBoundToBb
                }
              />
            </div>
          </FormGroup>
          <FormGroup
            className={createTableClasses.formGroup}
            inline={true}
            labelFor="text-input"
            label="Fee (%)"
          >
            <InputGroup
              className={createTableClasses.fee}
              value={fee.rawValue}
              id="text-input"
              onChange={onFeeChanged}
            />
          </FormGroup>
          <FormGroup
            className={createTableClasses.formGroup}
            inline={true}
            labelFor="text-input"
            label={
              <Tooltip
                content={'Maximum rake to take in one hand. In Big Blinds.'}
                position={Position.TOP}
              >
                Fee Cap (BB)
              </Tooltip>
            }
          >
            <InputGroup
              className={createTableClasses.fee}
              value={rakeCap.rawValue}
              id="text-input"
              onChange={onRakeCapChanged}
            />
          </FormGroup>
          <FormGroup
            className={createTableClasses.formGroup}
            inline={false}
            labelFor="text-input"
            label="Auto renew"
          >
            <Checkbox
              checked={isAutoRenewalEnabled}
              onChange={handleAutorenewalChange}
              defaultChecked={defaultAutoRenewalEnabled}
            />
          </FormGroup>
          <FormGroup
            className={createTableClasses.formGroup}
            inline={false}
            labelFor="text-input"
            label="Bomb pot"
            helperText={bombPotPeriod.error ?? bombPotAnteSize.error}
          >
            <Checkbox
              label="Enabled: "
              alignIndicator={Alignment.RIGHT}
              checked={isBombPotEnabled}
              onChange={handleBombPotEnabledChange}
              defaultChecked={defaultBombPotEnabled}
            />
            {'Period: '}
            <InputGroup
              value={bombPotPeriod.rawValue}
              id="text-input"
              onChange={onBombPotPeriodChange}
              disabled={!isBombPotEnabled}
            />
            {'Ante size in BB: '}
            <InputGroup
              value={bombPotAnteSize.rawValue}
              id="text-input"
              onChange={onBombPotAnteSizeChange}
              disabled={!isBombPotEnabled}
            />
            <Checkbox
              label="Activates double board: "
              alignIndicator={Alignment.RIGHT}
              checked={bombPotActivatesDoubleBoard}
              onChange={handleBombPotActivatesDoubleBoardChange}
              defaultChecked={defaultBombPotActivatesDoubleBoard}
              disabled={!isBombPotEnabled}
            />
          </FormGroup>
          <FormGroup
            className={createTableClasses.formGroup}
            inline={false}
            labelFor="text-input"
            label="Double board"
          >
            <Checkbox
              checked={isDoubleBoardEnabled}
              onChange={handleDoubleBoardEnabledChange}
              defaultChecked={defaultIsDoubleBoardEnabled}
            />
          </FormGroup>
          <FormGroup
            className={createTableClasses.formGroup}
            inline={true}
            labelFor="text-input"
            label="Size"
            helperText={size.error}
          >
            <InputGroup
              value={size.rawValue}
              id="text-input"
              onChange={onSizeChanged}
            />
          </FormGroup>
          <FormGroup
            className={createTableClasses.formGroup}
            inline={true}
            labelFor="text-input"
            label="Duration"
            helperText={duration.error}
          >
            <InputGroup
              id="numeric-input"
              onChange={onDurationChanged}
              value={duration.rawValue}
              placeholder={'in hours'}
            />
          </FormGroup>
          <Button
            loading={onAir}
            disabled={hasErrors}
            className={createTableClasses.submit}
            type="submit"
            intent={Intent.PRIMARY}
            onClick={doCreateTable}
          >
            Create
          </Button>
          <ResponseErrorToast response={createTableResponse} />
        </Form>
      </Dialog>
    </>
  );
};

const validateDuration = (duration: number) => {
  if (Number.isNaN(duration) || !Number.isInteger(duration) || duration <= 0) {
    return 'duration must be a positive integer';
  }
  if (duration > 24) {
    return 'duration must be less than or equal to 24';
  }
  return;
};

const validateBombPotPeriod = (period: number) => {
  if (Number.isNaN(period) || !Number.isInteger(period) || period <= 0) {
    return 'period must be a positive integer';
  }
  if (period > 30) {
    return 'period must be less than or equal to 30';
  }
  return;
};

const validateBombPotAnteSize = (size: number) => {
  if (Number.isNaN(size) || !Number.isInteger(size) || size <= 0) {
    return 'ante size must be a positive integer';
  }
  if (size > 20) {
    return 'ante size must be less than or equal to 20';
  }
  return;
};

const validateTableName = (name: string) => {
  if (name.length === 0) {
    return 'name must contain at least one character';
  }
  if (name.length > 15) {
    return 'name length must be less than 15';
  }
  return;
};
