import {Button, Classes, FormGroup, InputGroup} from '@blueprintjs/core';
import {Ice} from 'ice';
import * as React from 'react';

import {useWalletsAdminPrx} from '../../store/walletsAdmin';
import {getClasses} from '../../utils/css';
import {Form} from '../../utils/form';
import {useIdempotenceKey} from '../../utils/idempotencyKey';
import {parseCents, parseInteger, useInputState} from '../../utils/inputState';
import {ResponseErrorToast} from '../../utils/toast';
import {useCancelContext} from '../../utils/useCancelContext';
import {useRequest} from '../../utils/useRequest';

const classes = getClasses({
  form: {
    $nest: {
      [`& .${Classes.FORM_GROUP}.${Classes.INLINE}`]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
      },
    },
  },
  refillGroup: {
    display: 'grid',
    gridTemplateColumns: '120px 90px 80px 80px',
    gridGap: 10,
  },
});

export const UserBalanceChangeForm: React.FunctionComponent = () => {
  // TODO: добавить изменение баланса игроков клубов \ лиг \ турниров.
  const {changeGlobalUserBalance, changeUserGemBalance} = useWalletsAdminPrx();

  const [ctx] = useCancelContext();

  const [onGlobalAmountChange, globalAmount, , setGlobalAmount] = useInputState(
    '0.00',
    parseCents,
    validateAmount,
  );

  const [onGemAmountChange, gemAmount, , setGemAmount] = useInputState(
    '0',
    parseInteger,
    validateAmount,
  );

  const [onGlobalDisplayIdChange, globalDisplayId, , setGlobalDisplayId] =
    useInputState('', parseInteger, validateAmount);

  const [onGemDisplayIdChange, gemDisplayId, , setGemDisplayId] = useInputState(
    '',
    parseInteger,
    validateAmount,
  );

  const globalBalanceKey = useIdempotenceKey([globalDisplayId, globalAmount]);
  const gemBalanceKey = useIdempotenceKey(['gems', globalDisplayId, gemAmount]);

  // TODO вынести этот и последующие useRequest в одну функцию, ибо сейчас они копипаста
  const [globalDepositState, doGlobalDeposit] = useRequest(
    async () => {
      if (
        globalAmount.error == null &&
        globalAmount.value > 0 &&
        globalDisplayId.error == null &&
        globalDisplayId.value > 0
      ) {
        await changeGlobalUserBalance(
          globalBalanceKey,
          globalDisplayId.value.toString(),
          new Ice.Long(globalAmount.value),
        );
        setGlobalAmount('0.00');
        setGlobalDisplayId('');
      }
    },
    [
      changeGlobalUserBalance,
      globalBalanceKey,
      setGlobalAmount,
      globalDisplayId,
      globalAmount,
      setGlobalDisplayId,
    ],
    ctx,
  );

  const [gemDepositState, doGemDeposit] = useRequest(
    async () => {
      if (
        gemAmount.error == null &&
        gemAmount.value > 0 &&
        gemDisplayId.error == null &&
        gemDisplayId.value > 0
      ) {
        await changeUserGemBalance(
          gemBalanceKey,
          gemDisplayId.value.toString(),
          new Ice.Long(gemAmount.value),
        );
        setGemAmount('0');
        setGemDisplayId('');
      }
    },
    [
      changeUserGemBalance,
      gemBalanceKey,
      setGemAmount,
      gemDisplayId,
      gemAmount,
      setGemDisplayId,
    ],
    ctx,
  );

  const [globalCashoutState, doGlobalCashout] = useRequest(
    async () => {
      if (
        globalAmount.error == null &&
        globalAmount.value > 0 &&
        globalDisplayId.error == null &&
        globalDisplayId.value > 0
      ) {
        await changeGlobalUserBalance(
          globalBalanceKey,
          globalDisplayId.value.toString(),
          new Ice.Long(-globalAmount.value),
        );
        setGlobalAmount('0.00');
        setGlobalDisplayId('');
      }
    },
    [
      changeGlobalUserBalance,
      globalBalanceKey,
      setGlobalAmount,
      globalDisplayId,
      globalAmount,
      setGlobalDisplayId,
    ],
    ctx,
  );

  const [gemCashoutState, doGemCashout] = useRequest(
    async () => {
      if (
        gemAmount.error == null &&
        gemAmount.value > 0 &&
        gemDisplayId.error == null &&
        gemDisplayId.value > 0
      ) {
        await changeUserGemBalance(
          gemBalanceKey,
          gemDisplayId.value.toString(),
          new Ice.Long(-gemAmount.value),
        );
        setGemAmount('0');
        setGemDisplayId('');
      }
    },
    [
      changeUserGemBalance,
      gemBalanceKey,
      setGemAmount,
      gemDisplayId,
      gemAmount,
      setGemDisplayId,
    ],
    ctx,
  );

  return (
    <Form className={classes.form}>
      <FormGroup
        inline={true}
        labelFor="text-input"
        helperText={globalAmount.error}
      >
        {'Global lobby balance'}
        <div className={classes.refillGroup}>
          <InputGroup
            value={`${globalDisplayId.rawValue}`}
            placeholder={'User display ID'}
            id="text-input"
            onChange={onGlobalDisplayIdChange}
          />
          <InputGroup
            value={`${globalAmount.rawValue}`}
            id="text-input"
            onChange={onGlobalAmountChange}
          />
          <Button
            type="submit"
            disabled={globalAmount.error != null}
            onClick={doGlobalDeposit}
          >
            Deposit
          </Button>
          <Button
            type="submit"
            disabled={globalAmount.error != null}
            onClick={doGlobalCashout}
          >
            Cashout
          </Button>
        </div>
      </FormGroup>
      <FormGroup
        inline={true}
        labelFor="text-input"
        helperText={gemAmount.error}
      >
        {'User gems'}
        <div className={classes.refillGroup}>
          <InputGroup
            value={`${gemDisplayId.rawValue}`}
            placeholder={'User display ID'}
            id="text-input"
            onChange={onGemDisplayIdChange}
          />
          <InputGroup
            value={`${gemAmount.rawValue}`}
            id="text-input"
            onChange={onGemAmountChange}
          />
          <Button
            type="submit"
            disabled={gemAmount.error != null}
            onClick={doGemDeposit}
          >
            Deposit
          </Button>
          <Button
            type="submit"
            disabled={gemAmount.error != null}
            onClick={doGemCashout}
          >
            Cashout
          </Button>
        </div>
      </FormGroup>
      <ResponseErrorToast response={globalDepositState} />
      <ResponseErrorToast response={globalCashoutState} />
      <ResponseErrorToast response={gemDepositState} />
      <ResponseErrorToast response={gemCashoutState} />
    </Form>
  );
};

const validateAmount = (value: number) => {
  if (Number.isNaN(value) || value <= 0 || !Number.isInteger(value)) {
    // Тут валидируется распарсенное значение, поэтому сообщение об ошибке не
    // полностью соответствует условию.
    return 'amount must be a positive number';
  }
  return;
};
