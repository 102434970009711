import {Divider, FormGroup, InputGroup, Switch} from '@blueprintjs/core';
import * as _ from 'lodash';
import * as React from 'react';

import {
  parseBoolean,
  parseInteger,
  skipValidation,
  useInputState,
} from 'src/utils/inputState';

type Click = {
  doClick(): void;
  label: string | symbol;
};

type ClickerState = {
  isEnabled: boolean;
  clicks: Set<Click>;
  intervalMs: number;
  probabilitySettings: Record<string | symbol, number>;
};

type Register = {type: 'register'; click: Click; probability: number};
type Unregister = {type: 'unregister'; click: Click};
type SetInterval = {type: 'setInterval'; intervalMs: number};
type SetProbability = {
  type: 'setProbability';
  probability: number;
  label: string | symbol;
};
type DropClicks = {type: 'dropClicks'};
type ToggleClicker = {type: 'toggleClicker'};
type Actions =
  | Register
  | Unregister
  | DropClicks
  | SetInterval
  | SetProbability
  | ToggleClicker;
type ClickerEffect = DropClicks;

const clickerReducer = (state: ClickerState, action: Actions): ClickerState => {
  if (action.type === 'toggleClicker') {
    return {...state, isEnabled: !state.isEnabled};
  }

  if (action.type === 'dropClicks') {
    return {...state, clicks: new Set()};
  }

  if (action.type === 'setInterval') {
    return {...state, intervalMs: action.intervalMs};
  }

  if (action.type === 'setProbability') {
    return {
      ...state,
      probabilitySettings: {
        ...state.probabilitySettings,
        [action.label]: action.probability,
      },
    };
  }

  // dirty intent
  if (action.type === 'register') {
    const probability =
      state.probabilitySettings[action.click.label as any] ??
      action.probability;
    state.clicks.add(action.click);
    return {
      ...state,
      probabilitySettings: {
        ...state.probabilitySettings,
        [action.click.label]: probability,
      },
    };
  }

  // dirty intent
  if (action.type === 'unregister') {
    state.clicks.delete(action.click);
    return state;
  }

  return state;
};

const useClickerReducer = () =>
  React.useReducer(clickerReducer, {
    isEnabled: false,
    clicks: new Set<Click>(),
    intervalMs: 200,
    probabilitySettings: {},
  });

const ClickerContext = React.createContext<
  ReturnType<typeof useClickerReducer>
>(null as any);

export const ClickerProvider: React.SFC = ({children}) => {
  return (
    <ClickerContext.Provider value={useClickerReducer()}>
      {children}
    </ClickerContext.Provider>
  );
};

export const useClicker = () => {
  const [{clicks, intervalMs, isEnabled, probabilitySettings}] =
    React.useContext(ClickerContext);
  React.useEffect(() => {
    if (!isEnabled) {
      return;
    }
    const interval = setInterval(() => {
      const chance = Math.random();
      const click = _.sample(
        [...clicks].filter((click) => {
          // https://github.com/Microsoft/TypeScript/issues/24587#issuecomment-411459021
          // https://github.com/microsoft/TypeScript/issues/1863
          const probability = probabilitySettings[click.label as any];
          return chance < probability;
        }),
      );
      if (click != null) {
        click.doClick();
      }
    }, intervalMs);
    return () => {
      clearInterval(interval);
    };
  }, [isEnabled, intervalMs, clicks, probabilitySettings]);
};

export const useClickerEffect = (effectType: ClickerEffect['type']) => {
  const [, dispatch] = React.useContext(ClickerContext);
  return React.useCallback(
    () => dispatch({type: effectType}),
    [dispatch, effectType],
  );
};

export const useClick = (
  doClick: () => any,
  label?: string,
  probability = 1,
) => {
  const defaultLabel = React.useMemo(() => Symbol(), []);
  const [, dispatch] = React.useContext(ClickerContext);
  React.useEffect(() => {
    const click: Click = {
      doClick,
      label: label ?? defaultLabel,
    };
    dispatch({type: 'register', click, probability});
    return () => {
      dispatch({type: 'unregister', click});
    };
  }, [doClick, label, probability, dispatch, defaultLabel]);
};

export const ClickerSettings = () => {
  const [{intervalMs, probabilitySettings, isEnabled}, dispatch] =
    React.useContext(ClickerContext);
  const [onChange, enabled] = useInputState(
    `${isEnabled}`,
    parseBoolean,
    skipValidation,
  );
  const {value} = enabled;
  React.useEffect(() => {
    if (value !== isEnabled) {
      dispatch({type: 'toggleClicker'});
    }
  }, [value, isEnabled, dispatch]);
  return (
    <div>
      <FormGroup label="Enabled" inline={true}>
        <Switch onChange={onChange} checked={enabled.value} />
      </FormGroup>
      <FormGroup label="Click interval" inline={true}>
        <IntervalInput init={intervalMs} />
      </FormGroup>
      <Divider />
      <FormGroup label="Probabilities">
        {[...Object.entries(probabilitySettings)]
          .sort(([key1], [key2]) => key1.localeCompare(key2))
          .map(([key, probability]) => {
            return (
              <FormGroup key={'group:' + key} label={key} inline={true}>
                <ProbablyInput key={key} label={key} init={probability} />
              </FormGroup>
            );
          })}
      </FormGroup>
    </div>
  );
};

const validateProbability = (probability: number) => {
  if (probability < 0) {
    return 'Probability must be positive float';
  }
  if (probability > 1) {
    return 'Probability must be less than or equal to 1';
  }
};

const ProbablyInput: React.SFC<{label: string; init: number}> = ({
  label,
  init,
}) => {
  const [onChange, probability] = useInputState(
    `${init}`,
    parseFloat,
    validateProbability,
  );
  const [, dispatch] = React.useContext(ClickerContext);
  const {value, error} = probability;
  React.useEffect(() => {
    if (error == null) {
      dispatch({type: 'setProbability', label, probability: value});
    }
  }, [value, error, dispatch, label]);
  return <InputGroup onChange={onChange} value={probability.rawValue} />;
};

const validateInterval = (interval: number) => {
  if (interval < 0) {
    return 'Interval must be positive integer';
  }
};

const IntervalInput: React.SFC<{init: number}> = ({init}) => {
  const [onChange, interval] = useInputState(
    `${init}`,
    parseInteger,
    validateInterval,
  );
  const [, dispatch] = React.useContext(ClickerContext);
  const {value, error} = interval;
  React.useEffect(() => {
    if (error == null) {
      dispatch({type: 'setInterval', intervalMs: value});
    }
  }, [value, error, dispatch]);
  return <InputGroup onChange={onChange} value={interval.rawValue} />;
};
