import {Card, Elevation, H1} from '@blueprintjs/core';
import {History} from 'history';
import {Ice} from 'ice';
import * as React from 'react';
import {useHistory} from 'react-router-dom';

import {Gazebo} from '@slices/Gazebo';

import {getResponseData, getResponseError} from 'src/ice-client-react';
import {useUserGames} from 'src/store/userGames';
import {DefaultErrorToast} from 'src/utils/toast';

export const Content: React.FunctionComponent = () => {
  const history = useHistory();
  const userGames = useUserGames();
  const userGamesRaw = getResponseData(userGames);
  const error = getResponseError(userGames);

  if (error != null) {
    return (
      <H1>
        <DefaultErrorToast
          message={`Error getting user games data: ${JSON.stringify(error)}`}
        />
      </H1>
    );
  }

  if (userGamesRaw == null || userGamesRaw.length === 0) {
    return <H1>You have no active games</H1>;
  }

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      {userGamesRaw.map(createGameToViewMapper(history))}
    </div>
  );
};

const createGameToViewMapper =
  (history: History<History.PoorMansUnknown>) =>
  (userGame: Gazebo.Lobby.AbstractUserGameInfo, index: number) => {
    const view = views.get(userGame.ice_id());
    if (view) {
      return view({userGame, history});
    }
    return (
      <Card key={index} interactive={false} elevation={Elevation.ZERO}>
        Unexpected game type
      </Card>
    );
  };

type GameViewComponent<T extends Gazebo.Lobby.AbstractUserGameInfo> =
  React.SFC<{userGame: T; history: History<History.PoorMansUnknown>}>;
const createViewVK = <T extends Gazebo.Lobby.AbstractUserGameInfo>(
  key: Ice.ValueConstructor<T>,
  value: GameViewComponent<T>,
) =>
  [key.ice_staticId(), value as any] as [
    string,
    GameViewComponent<Gazebo.Lobby.AbstractUserGameInfo>,
  ];

const views = new Map<
  string,
  GameViewComponent<Gazebo.Lobby.AbstractUserGameInfo>
>([
  createViewVK(Gazebo.Lobby.UserTableInfoV2, ({userGame, history}) => {
    return (
      <Card
        key={userGame.tableId}
        interactive={true}
        elevation={Elevation.TWO}
        onClick={() => {
          history.push(
            `/${userGame.referrerId ?? 'global'}/${userGame.tableId}`,
          );
        }}
      >
        <p>
          <span>Table id:</span>&nbsp;{userGame.tableId}
        </p>
        <p>
          <span>Referrer id:</span>&nbsp;{userGame.referrerId}
        </p>
      </Card>
    );
  }),
  createViewVK(Gazebo.Lobby.UserTournamentTableInfo, ({userGame, history}) => {
    return (
      <Card
        key={userGame.tableId}
        interactive={true}
        elevation={Elevation.TWO}
        onClick={() => {
          history.push(`/${userGame.tournamentId}/${userGame.tableId}`);
        }}
      >
        <p>
          <span>Table id:</span>&nbsp;{userGame.tableId}
        </p>
        <p>
          <span>Tournament id:</span>&nbsp;{userGame.tournamentId}
        </p>
      </Card>
    );
  }),
  createViewVK(Gazebo.Lobby.UserTournamentInfo, ({userGame, history}) => {
    return (
      <Card
        key={userGame.tournamentId}
        interactive={true}
        elevation={Elevation.TWO}
        onClick={() => {
          history.push(
            `/${userGame.referrerId ?? 'global'}/${userGame.tournamentId}`,
          );
        }}
      >
        <p>
          <span>Tournament id:</span>&nbsp;{userGame.tournamentId}
        </p>
        <p>
          <span>Referrer id:</span>&nbsp;{userGame.referrerId}
        </p>
      </Card>
    );
  }),
]);
