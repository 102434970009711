import {Alignment, Icon, Navbar} from '@blueprintjs/core';
import * as React from 'react';

import {getClasses} from '../../utils/css';

import {NavigationBarMain} from '../../navigationBarMain';

const classes = getClasses({
  navItem: {
    flex: '8 8 1em',
    padding: '0 0.5em !important',
  },
  navSpacer: {
    flex: '10 10 0',
  },
});

export const NavigationBar: React.FunctionComponent = () => (
  <NavigationBarMain>
    <Navbar.Group className={classes.navSpacer} />
    <Header />
    <Navbar.Group className={classes.navSpacer} />
  </NavigationBarMain>
);

const Header = () => {
  const heading = 'Global lobby admin';

  return (
    <Navbar.Group align={Alignment.CENTER} className={classes.navItem}>
      <Navbar.Heading>{heading}</Navbar.Heading>
    </Navbar.Group>
  );
};
