import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {KillTournament} from '../../components/killTournament';
import {
  getResponseData,
  getResponseError,
  useIceProxy,
  useSubscription,
} from '../../ice-client-react';
import {getStatusText} from '../tournamentUtils/tournamentStatusText';

import {Swimlane, tournamentClasses, Tournaments} from './tournaments';

export const AllTournamentsManagement: React.FunctionComponent = () => {
  const allAreaLobby = useAllTournaments();
  const error = getResponseError(allAreaLobby);
  const rawTournaments = getResponseData(allAreaLobby);

  const tournaments = React.useMemo(() => {
    const groups: Record<string, Tournaments> = {};
    for (const tournament of rawTournaments ?? []) {
      const status =
        (tournament instanceof Gazebo.Lobby.MultiTableTournament &&
          getStatusText(tournament.status)) ||
        'undefined';

      groups[status] = groups[status] ?? [];
      groups[status].push(tournament);
    }
    return [...Object.entries(groups)].sort(
      ([status1], [status2]) => -status1.localeCompare(status2),
    );
  }, [rawTournaments]);

  if (error != null) {
    return <div>Error</div>;
  }
  return (
    <div className={tournamentClasses.root}>
      <KillTournament />
      {tournaments.map(([status, tournaments]) => (
        <Swimlane key={status} status={status} tournaments={tournaments} />
      ))}
    </div>
  );
};

const useAllTournaments = () => {
  const {subscribeToAllTournaments} = useIceProxy<Gazebo.Lobby.ServicePrx>({
    type: 'static',
    propertyName: 'Webapp.Lobby',
    proxyClass: Gazebo.Lobby.ServicePrx,
  });

  const subscribe = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Lobby.AbstractTournament>,
    ) => {
      return subscribeToAllTournaments(subscriber);
    },
    [subscribeToAllTournaments],
  );
  return useSubscription(subscribe);
};
