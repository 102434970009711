import {Button, Card, Colors, Dialog, ProgressBar} from '@blueprintjs/core';
import {format} from 'date-fns';
import {last, sortBy} from 'lodash';
import React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {
  getResponseData,
  getResponseError,
  useSubscription,
} from '../../../ice-client-react';
import {useHandHistoryPrx} from '../../../store/handHistory';
import {getClasses} from '../../../utils/css';
import {centsToMainUnits} from '../../../utils/numbers';

const classes = getClasses({
  timer: {
    margin: 5,
  },
  hands: {
    margin: 5,
    maxHeight: '50vh',
    overflow: 'auto',
  },
  cardInCombination: {
    color: Colors.GREEN5,
  },
  player: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  playerInfo: {
    minWidth: 100,
  },
  pocketCards: {
    minWidth: 120,
  },
  handHistoryButton: {
    position: 'absolute',
    top: 230,
    zIndex: 10,
  },
});

export const HandHistory: React.FC<{tableId: string}> = ({tableId}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const handleClose = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  const {subscribeToHandHistory, subscribeToPlayerTurnTimer} =
    useHandHistoryPrx();

  const subscribeToHands = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.HandHistory.HandSummary>,
    ) => subscribeToHandHistory(subscriber, tableId),
    [subscribeToHandHistory, tableId],
  );
  const handsState = useSubscription(subscribeToHands);
  const handsError = getResponseError(handsState);
  const hands = sortBy(getResponseData(handsState), (hand) => hand.timestamp);

  const subscribeToTimer = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.HandHistory.TimerNotification>,
    ) => subscribeToPlayerTurnTimer(subscriber, tableId),
    [subscribeToPlayerTurnTimer, tableId],
  );
  const timerState = useSubscription(subscribeToTimer);
  const timerError = getResponseError(timerState);
  const timer = getResponseData(timerState)?.[0];

  const [timerProgress, setTimerProgress] = React.useState<number | undefined>(
    undefined,
  );
  React.useEffect(() => {
    const endAtMs = timer?.heroTurnEndsAtMs?.toNumber();
    const startAtMs = timer?.heroTurnStartsAtMs?.toNumber();
    if (endAtMs == null || startAtMs == null) {
      setTimerProgress(undefined);
      return;
    }
    setTimerProgress((endAtMs - Date.now()) / (endAtMs - startAtMs));
    const intervalTimer = setInterval(() => {
      if (endAtMs < Date.now() || !isOpen) {
        setTimerProgress(undefined);
        clearInterval(intervalTimer);
        return;
      }
      setTimerProgress((endAtMs - Date.now()) / (endAtMs - startAtMs));
    }, 50);
    return () => {
      setTimerProgress(undefined);
      clearInterval(intervalTimer);
    };
  }, [timer, setTimerProgress, isOpen]);

  return (
    <>
      <Button className={classes.handHistoryButton} onClick={handleOpen}>
        Hand summary
      </Button>
      <Dialog isOpen={isOpen} title="Hand Summary" onClose={handleClose}>
        <div className={classes.timer}>
          {timerError != null ? (
            JSON.stringify(timerError, null, 2)
          ) : timerProgress == null ? (
            'Not your turn'
          ) : (
            <ProgressBar value={timerProgress} />
          )}
        </div>
        <div className={classes.hands}>
          {handsError != null
            ? JSON.stringify(handsError, null, 2)
            : hands.length === 0
            ? 'No hands'
            : hands.map((hand) => <Hand key={hand.handId} hand={hand} />)}
        </div>
      </Dialog>
    </>
  );
};

const Hand: React.FC<{hand: Gazebo.HandHistory.HandSummary}> = ({hand}) => {
  return (
    <Card>
      <div>
        Id: {hand.handId} Pot:{' '}
        {hand.totalPot == null
          ? 'N/A'
          : centsToMainUnits(hand.totalPot.toNumber())}{' '}
        Start time:{' '}
        {hand.timestamp == null
          ? 'N/A'
          : format(hand.timestamp.toNumber(), 'PPpp')}
      </div>
      {hand.playersHandsSummary?.map((player) => {
        const winnings = player.winnings?.toNumber() ?? 0;
        return (
          <div key={player.displayName} className={classes.player}>
            <div className={classes.playerInfo}>
              <div>{player.displayName}</div>
              <div>
                {winnings > 0 ? '+' : ''}
                {centsToMainUnits(winnings)}
              </div>
              <div>
                {player.combinationsType?.map((combinationType, ind) => (
                  <div key={ind}>{combinationType.ice_id().split('::')}</div>
                ))}
              </div>
              {player.isFold ? <div>Fold</div> : null}
              <div>{player.isWinner ? 'Winner' : 'Not winner'}</div>
            </div>
            <div className={classes.pocketCards}>
              {player.pockets?.map((pocket, ind) => (
                <div key={ind}>
                  {pocket.map((card) => (
                    <PlayingCard key={card.card?.name} card={card} />
                  ))}
                </div>
              ))}
            </div>
            <div>
              {player.boards?.map((board, ind) => (
                <div key={ind}>
                  {board.map((card) => (
                    <PlayingCard key={card.card?.name} card={card} />
                  ))}
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </Card>
  );
};

const PlayingCard: React.FC<{card: Gazebo.HandHistory.Card}> = ({card}) => {
  return (
    <div
      className={
        card.isPartOfCombination && card.isOpen
          ? classes.cardInCombination
          : undefined
      }
    >
      {card.isOpen ? card.card?.name : 'Hidden'}
    </div>
  );
};
