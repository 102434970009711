import {
  CancelContext,
  retry,
  RetryOptions,
  spawn,
} from '@neolab/cancel-context';
import {Ice} from 'ice';
import * as React from 'react';

import {useAuthContext} from '../auth';
import {createSubject, Deferred, deferred, Subject} from '../ice-client/utils';
import {useCancelContext} from '../utils/useCancelContext';

/**
 * Наколеночная реализация клиента для json-rpc-gateway
 * с его помощью можно потыкать в консоли интерфейс json-rpc
 * и убедиться что гетвей нормально работает.
 *
 * Пример подписки:
 * c = createJRPCClient('wss://json.dev.gazebo.poks.poker')
 * c.updateAccessToken(JSON.parse(localStorage.$$currentAuthSession).accessToken)
 * c.subscribe('Lobby', 'Gazebo.Lobby.Service', 'subscribeToUserTables', '1', []).then(async subject => {
 *   while(true) {
 *     console.log(await subject.next())
 *   }
 * })
 */
export const JRPCClient = () => {
  const [ctx] = useCancelContext();
  const {accessToken} = useAuthContext();
  const jrpcClient = React.useRef<JRPCClient>();
  React.useEffect(() => {
    (window as any).createJRPCClient = (url = 'ws://localhost:34567') => {
      const client = createJRPCClient(ctx, url);
      jrpcClient.current = client;
      return client;
    };
  }, [ctx, jrpcClient]);
  React.useEffect(() => {
    if (accessToken && jrpcClient.current) {
      jrpcClient.current.updateAccessToken(accessToken);
    }
  }, [jrpcClient, accessToken]);
  return <div />;
};

const createJSONRpcMessage = (method: string, params: any[]) => {
  return {
    jsonrpc: '2.0',
    id: Ice.generateUUID(),
    method,
    params,
  };
};

const createInvokeMessage = (
  proxyString: string,
  interfaceName: string,
  operation: string,
  params: any[],
) => {
  return createJSONRpcMessage('invoke', [
    proxyString,
    interfaceName,
    operation,
    params,
  ]);
};

const createSubscribeMessage = (
  proxyString: string,
  interfaceName: string,
  operation: string,
  subscriptionId: string,
  params: any[],
) => {
  return createJSONRpcMessage('subscribe', [
    proxyString,
    interfaceName,
    operation,
    subscriptionId,
    params,
  ]);
};

const createUbsubscribeMessage = (subscriptionId: string) => {
  return createJSONRpcMessage('unsubscribe', [subscriptionId]);
};

const notifications = ['notify', 'connected', 'disconnected', 'error', 'end'];
const parseJSONRpcMessage = (payload: string) => {
  const data = JSON.parse(payload);
  if ('id' in data) {
    const {id} = data;
    if ('error' in data) {
      return {type: 'error', id, error: data.error} as const;
    } else if ('result' in data) {
      return {type: 'response', id, result: data.result} as const;
    }
    return {type: 'unexpected', id, payload} as const;
  } else {
    const {method} = data;
    if (notifications.includes(method)) {
      const {
        params: [id, ...params],
      } = data;
      return {
        type: 'notification',
        id,
        message: {type: method, params},
      } as const;
    }
    return {type: 'unexpectedNotification', payload} as const;
  }
};

const createJRPCClient = (ctx: CancelContext, url: string) => {
  const deferreds = new Map<string, {defer: Deferred<any>; message: any}>();
  const subscriptions = new Map<
    string,
    {subject: Subject<any>; message: any}
  >();

  const accessTokenSubject = createSubject<string>(ctx);
  const baseWsSubject = createSubject<WebSocket>(ctx);

  const wsSubject = baseWsSubject.map(async (ws) => {
    ws.onmessage = (message) => {
      const data = parseJSONRpcMessage(message.data);
      if (data.type === 'response') {
        deferreds.get(data.id)?.defer.resolve(data.result);
        deferreds.delete(data.id);
      } else if (data.type === 'error') {
        deferreds.get(data.id)?.defer.reject(data.result);
        deferreds.delete(data.id);
      } else if (data.type === 'notification') {
        subscriptions.get(data.id)?.subject.put(data.message);
        if (data.message.type === 'end') {
          subscriptions.get(data.id)?.subject.cancel();
          subscriptions.delete(data.id);
        }
      } else {
        const error = new Error(`Unexpected payload: ${data.payload}`);
        console.warn(error);
        deferreds.get(data.id)?.defer.reject(error);
        deferreds.delete(data.id);
      }
    };

    const {onclose, onerror} = ws;
    ws.onerror = (event) => {
      authSubject.cancel();
      clearTimeout(pingTimer);
      onerror?.call(ws, event);
    };
    ws.onclose = (event) => {
      authSubject.cancel();
      clearTimeout(pingTimer);
      onclose?.call(ws, event);
    };

    const ping = async () => {
      try {
        await unauthorizedRpcCall(
          Promise.resolve(ws),
          createJSONRpcMessage('jsonbird.ping', []),
        );
      } catch (err) {
        console.error(err);
      } finally {
        pingTimer = setTimeout(ping, 2000);
      }
    };
    let pingTimer = setTimeout(ping, 2000);

    const authSubject = accessTokenSubject.map(async (accessToken) => {
      await unauthorizedRpcCall(
        Promise.resolve(ws),
        createJSONRpcMessage('updateToken', [accessToken]),
      );
    });

    await authSubject.current();

    for (const {message} of deferreds.values()) {
      ws.send(JSON.stringify(message));
    }

    for (const {message} of subscriptions.values()) {
      ws.send(JSON.stringify(message));
    }

    return ws;
  });

  const unauthorizedRpcCall = async (
    ws: Promise<WebSocket>,
    message: any,
    options: RetryOptions = {maxAttempts: 5, baseMs: 50, onError: console.warn},
  ) =>
    retry(
      ctx,
      async () => {
        const defer = deferred(ctx);
        deferreds.set(message.id, {defer, message});
        (await ws).send(JSON.stringify(message));
        return defer.promise;
      },
      options,
    );

  const rpcCall = (
    message: any,
    options: RetryOptions = {maxAttempts: 5, baseMs: 50, onError: console.warn},
  ) => unauthorizedRpcCall(wsSubject.current() as any, message, options);

  const invoke = (
    proxyString: string,
    interfaceName: string,
    operation: string,
    params: any[],
  ) => {
    return rpcCall(
      createInvokeMessage(proxyString, interfaceName, operation, params),
    );
  };

  const subscribe = async (
    proxyString: string,
    interfaceName: string,
    operation: string,
    subscriptionId: string,
    params: any[],
  ) => {
    const subject = createSubject(ctx);
    const message = createSubscribeMessage(
      proxyString,
      interfaceName,
      operation,
      subscriptionId,
      params,
    );
    subscriptions.set(subscriptionId, {subject, message});
    await rpcCall(message);
    return {
      ...subject,
      cancel: () => rpcCall(createUbsubscribeMessage(subscriptionId)),
    };
  };

  const unsubscribe = async (subscriptionId: string) => {
    const subscription = subscriptions.get(subscriptionId);
    if (subscription === undefined) {
      throw new Error('Subscription does not exist');
    }
    await rpcCall(createUbsubscribeMessage(subscriptionId));
    return subscriptions.delete(subscriptionId);
  };

  const createConnection = (ctx: CancelContext) =>
    new Promise<WebSocket>((resolve, reject) => {
      const newWs = new WebSocket(url);
      newWs.onopen = () => resolve(newWs);
      const removeCb = ctx.onCancel(() => {
        newWs.close();
      });
      newWs.onerror = newWs.onclose = (err?: any) => {
        removeCb();
        reject(err);
      };
    });

  spawn(ctx, async (ctx) => {
    while (!ctx.isCanceled()) {
      const ws = await retry(ctx, createConnection, {maxDelayMs: 60000});
      baseWsSubject.put(ws);
      await new Promise((resolve) => {
        ws.onerror = ws.onclose = resolve;
      });
    }
  });
  return {
    invoke,
    subscribe,
    unsubscribe,
    updateAccessToken(token: string) {
      accessTokenSubject.put(token);
    },
  };
};

// eslint-disable-next-line no-redeclare
export type JRPCClient = ReturnType<typeof createJRPCClient>;
