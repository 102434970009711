import {Intent, Position, Toast, Toaster} from '@blueprintjs/core';
import {
  cancelable,
  CancelContext,
  catchCancelError,
  isCancelError,
} from '@neolab/cancel-context';
import {subscribeToCollection} from '@neolab/subscriptions';
import {Ice} from 'ice';
import * as React from 'react';

import {Subscriptions} from '@slices/Subscriptions';

import {
  ConnectionState,
  createIceClient,
  DynamicProxyConfig,
  IceClient,
  StaticProxyConfig,
} from '../ice-client';
import {iceProperties} from '../iceProperties';
import {logger} from '../logger';

import {useIceClientAuth} from './IceClientAuth';

export * from './IceClientAuth';

const IceClientContext = React.createContext<IceClient>(null as any);

export const IceClientProvider: React.SFC<{accessToken: string}> = ({
  children,
  accessToken,
}) => {
  const [authCtxState, authCtxDispatch] = useIceClientAuth();

  React.useEffect(() => {
    authCtxDispatch({type: 'accessTokenUpdated', value: accessToken});
  }, [authCtxDispatch, accessToken]);

  const [iceClient, setIceClient] = React.useState<IceClient>();

  React.useEffect(() => {
    const iceClient = createIceClient(iceProperties);
    setIceClient(iceClient);
    return () => {
      iceClient.destroy();
    };
  }, [setIceClient]);

  React.useEffect(() => {
    if (iceClient != null) {
      iceClient.setAuthContext(authCtxState);
    }
  }, [authCtxState, iceClient]);

  if (iceClient == null) {
    return null;
  }

  return (
    <IceClientContext.Provider value={iceClient}>
      {children}
      <ConnectionStateToast />
    </IceClientContext.Provider>
  );
};

export const useIceClient = () => React.useContext(IceClientContext);

const ConnectionStateToast = () => {
  const [state, setState] = React.useState<ConnectionState>();
  const iceClient = useIceClient();
  React.useEffect(() => {
    return iceClient.onConnectionStateChange(setState);
  }, [iceClient, setState]);

  const [timeout, setTimeoutState] = React.useState<number>();
  React.useEffect(() => {
    if (state?.type === 'retry') {
      let timeout = Math.round(state.delayMs / 1000);
      setTimeoutState(timeout);
      const timer = setInterval(() => {
        setTimeoutState(timeout--);
      }, 1000);
      return () => clearInterval(timer);
    }
    return () => {};
  }, [setTimeoutState, state]);

  if (state?.type === 'retry') {
    return (
      <Toaster position={Position.BOTTOM_RIGHT}>
        <Toast
          timeout={0}
          intent={Intent.DANGER}
          message={`Connection lost. Reconnecting in ${timeout}s`}
          action={{onClick: iceClient.reconnectNow}}
          icon="refresh"
        />
      </Toaster>
    );
  }
  return null;
};

export const useIceProxy = <P extends Ice.ObjectPrx>(
  config: StaticProxyConfig<P> | DynamicProxyConfig<P>,
) => useIceClient().createProxy(config);

export type Response<T> =
  | {type: 'data'; data: T}
  | {type: 'error'; error?: any; data?: T}
  | {type: 'started'; data?: T};

const useDataKeeper = <
  T extends {type: 'data' | string; data?: any} | undefined,
>(
  response: T,
) => {
  const [state, setState] = React.useState<T>(response);
  const data = getResponseData(state);
  React.useEffect(() => {
    if (response?.type !== 'data') {
      setState({...response, data});
    } else {
      setState(response);
    }
  }, [response, data, setState]);
  return state;
};

export const useResponse = <T,>(
  ctx: CancelContext,
  requester: () => T | Promise<T>,
  deps: React.DependencyList = [],
): [Response<T> | undefined, () => void] => {
  const [state, setState] = React.useState<Response<T>>({type: 'started'});
  const doRequest = React.useMemo(
    () => requester,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );
  const loadData = React.useCallback(() => {
    setState({type: 'started'});
    cancelable(ctx, Promise.resolve(doRequest()))
      .then((data) => ({type: 'data', data} as const))
      .catch((error) => {
        if (isCancelError(error)) {
          throw error;
        }
        return {type: 'error', error} as const;
      })
      .then(setState)
      .catch(catchCancelError);
  }, [doRequest, ctx]);
  React.useEffect(() => {
    loadData();
  }, [loadData]);
  return [useDataKeeper(state), loadData];
};

export type PromiseValue<T> = T extends Promise<infer R> ? R : T;
export type Fn<T> = T extends () => any
  ? () => void
  : T extends (...args: infer A) => any
  ? (...args: A) => void
  : never;

export const useRequest = <T extends (...args: any) => any>(
  ctx: CancelContext,
  callback: T,
  deps: React.DependencyList = [],
): [Response<PromiseValue<ReturnType<T>>> | undefined, Fn<T>] => {
  const [state, setState] =
    React.useState<Response<PromiseValue<ReturnType<T>>>>();
  const doCallback = React.useMemo(
    () => callback,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );

  const doRequest = React.useCallback(
    (...args: any[]) => {
      setState({type: 'started'});
      try {
        cancelable(ctx, Promise.resolve(doCallback(...args)))
          .then((data) => ({type: 'data', data} as const))
          .catch((error) => {
            if (isCancelError(error)) {
              throw error;
            }
            return {type: 'error', error} as const;
          })
          .then(setState)
          .catch(catchCancelError);
      } catch (error) {
        setState({type: 'error', error});
      }
    },
    [doCallback, ctx],
  );
  return [useDataKeeper(state), doRequest as any];
};

export const getResponseData = <T,>(
  // eslint-disable-next-line @typescript-eslint/ban-types
  response?: {data: T} | {},
): T | null => {
  if (response != null && 'data' in response) {
    return response.data;
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const getResponseError = (response?: {error?: any} | {}): any | null => {
  if (response != null && 'error' in response) {
    return response.error;
  }
  return null;
};

export type SubscriptionState<Item extends Ice.Value> =
  | Response<Item[]>
  | {type: 'finished'; data?: Item[]};

export type SubscriptionFactory<Item extends Ice.Value> = (
  subscriberPrx: Subscriptions.CollectionSubscriberPrx<Item>,
  ctx?: Ice.Context | undefined,
) => Promise<Subscriptions.SubscriptionPrx | null>;

export const useSubscription = <Item extends Ice.Value>(
  subscribe: SubscriptionFactory<Item> | undefined,
): SubscriptionState<Item> => {
  const [state, setState] = React.useState<SubscriptionState<Item>>({
    type: 'started',
  });

  const subscribeWorker = React.useCallback(
    (
      subscribe: SubscriptionFactory<Item>,
      adapter: Ice.ObjectAdapter,
      servantCategory: string,
    ) => {
      setState({type: 'started'});
      let state: SubscriptionState<Item> = {type: 'started'};
      return subscribeToCollection<Item>({
        subscribe,
        onStatusChange(status) {
          if (status.isLive === false) {
            state = {
              ...state,
              type: 'error',
              error: {
                type: 'backoff',
                error: status.error,
                attempt: status.attempt,
                delayMs: status.backoffDeadlineMs - Date.now(),
              },
            };
            setState(state);
          }
        },
        adapter,
        servantCategory,
        parentLogger: logger as any,
      }).subscribe({
        next: (data) => {
          state = {type: 'data', data: [...data.values()]};
          setState(state);
        },
        error: (error?: any) => {
          state = {...state, type: 'error', error};
          setState(state);
        },
        complete: () => {
          state = {...state, type: 'finished'};
          setState(state);
        },
      });
    },
    [setState],
  );

  const iceClient = useIceClient();

  React.useEffect(() => {
    if (subscribe == null) {
      return;
    }
    return iceClient.createSubscription(subscribe, subscribeWorker);
  }, [iceClient, subscribe, subscribeWorker]);

  return useDataKeeper<SubscriptionState<Item>>(state);
};
