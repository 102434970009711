import {
  Button,
  Card,
  Dialog,
  Elevation,
  FormGroup,
  H3,
  Icon,
  InputGroup,
  Intent,
  Tag,
} from '@blueprintjs/core';
import * as React from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {cssRule} from 'typestyle';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {useAuthContext} from 'src/auth';
import {create} from 'src/utils/create';
import {Form} from 'src/utils/form';
import {useIdempotenceKey} from 'src/utils/idempotencyKey';
import {parseString, skipValidation, useInputState} from 'src/utils/inputState';
import {DefaultErrorToast, ResponseErrorToast} from 'src/utils/toast';
import {useCancelContext} from 'src/utils/useCancelContext';

import {
  getResponseData,
  getResponseError,
  useIceProxy,
  useSubscription,
} from '../../ice-client-react';
import {useWalletsAdminPrx} from '../../store/walletsAdmin';
import {getClasses} from '../../utils/css';
import {centsToMainUnits} from '../../utils/numbers';
import {useRequest} from '../../utils/useRequest';

import {useClub, useClubs, useClubsPrx} from '../../store/clubs';
import {getNamespace} from '../../utils/getNamespace';
import {Link} from '../../utils/Link';

import {Table} from './Table';
import {Tables} from './Tables';
import {Tournament} from './Tournament';
import {Tournaments} from './Tournaments';

const classes = getClasses({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 25,
  },
  join: {
    borderRadius: 50,
    position: 'fixed',
    top: 30,
    left: 'calc(50vw + 310px)',
    zIndex: 100,
  },
  addClub: {
    borderRadius: 50,
    position: 'fixed',
    top: 30,
    left: 'calc(50vw + 260px)',
    zIndex: 100,
  },
  dialog: {
    width: 350,
  },
  form: {
    padding: 15,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  formGroup: {
    display: 'grid',
    gridTemplateColumns: '70px 250px',
  },
  submit: {
    width: 100,
  },
  clubTag: {
    marginRight: 5,
  },
});

cssRule('@media only screen and (max-width: 780px)', {
  [`.${classes.join}`]: {
    top: 'unset',
    left: 'unset',
    bottom: 10,
    right: 10,
  },
});

cssRule('@media only screen and (max-width: 500px)', {
  [`.${classes.root}`]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

const LobbyRoot = () => {
  const response = useClubs();

  const clubs = getResponseData(response) ?? [];
  const error = getResponseError(response);

  if (error != null) {
    return (
      <DefaultErrorToast
        message={`Error getting clubs data: ${JSON.stringify(error)}`}
      />
    );
  }

  return (
    <div className={classes.root}>
      <JoinToClub />
      <CreateClub />
      {clubs.map((club) => (
        <ClubCard key={club.clubId} club={club} />
      ))}
      <GlobalLobbyCard key="global" />
    </div>
  );
};

const GlobalLobbyCard: React.FunctionComponent = () => {
  const history = useHistory();
  const gotoGlobalLobby = React.useCallback(() => {
    history.push(`/${'global'}`);
  }, [history]);

  const {subscribeToTables} = useIceProxy<Gazebo.Lobby.ServicePrx>({
    type: 'static',
    propertyName: 'Webapp.Lobby',
    proxyClass: Gazebo.Lobby.ServicePrx,
  });

  const subscribe = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Lobby.AbstractTable>,
    ) => subscribeToTables(subscriber, ['global']),
    [subscribeToTables],
  );

  const {idTokenPayload} = useAuthContext();
  const userId = idTokenPayload?.sub ?? '';
  const {subscribeToUserBalances} = useWalletsAdminPrx();
  const subscribeToBalances = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.WalletsAdmin.Wallet>,
    ) => subscribeToUserBalances(subscriber, [userId]),
    [subscribeToUserBalances, userId],
  );
  const balancesSubscriptionState = useSubscription(subscribeToBalances);
  const globalLobbyBalances = getResponseData(balancesSubscriptionState);

  const globalLobbySubscription = useSubscription(subscribe);
  return (
    <Card
      key={'global'}
      interactive={true}
      elevation={Elevation.TWO}
      onClick={gotoGlobalLobby}
    >
      <H3>{'global'}</H3>
      <p>
        <span>
          {centsToMainUnits(globalLobbyBalances?.[0]?.balance.toNumber() ?? 0)}{' '}
          <Icon
            iconSize={12}
            icon="dollar"
            tagName="span"
            style={{marginLeft: 5}}
          />
        </span>
        <br />
        <span>
          {globalLobbySubscription.data?.length}{' '}
          <Icon
            iconSize={12}
            icon="grid"
            tagName="span"
            style={{marginLeft: 5}}
          />
        </span>
      </p>
    </Card>
  );
};

const ClubCard: React.FunctionComponent<{club: Gazebo.Clubs.Club}> = ({
  club,
}) => {
  const history = useHistory();
  const gotoClub = React.useCallback(() => {
    history.push(`/${club.clubId}`);
  }, [club, history]);

  return (
    <Card
      key={club.clubId}
      interactive={true}
      elevation={Elevation.TWO}
      onClick={gotoClub}
    >
      <H3>
        {club.name} #{club.displayId}
      </H3>
      <p>
        <span>
          {centsToMainUnits(club.balance?.toNumber() ?? 0)}{' '}
          <Icon
            iconSize={12}
            icon="dollar"
            tagName="span"
            style={{marginLeft: 5}}
          />
        </span>
        <br />
        <span>
          {club.tableCount}{' '}
          <Icon
            iconSize={12}
            icon="grid"
            tagName="span"
            style={{marginLeft: 5}}
          />
        </span>
      </p>
      {club.isMyClub ? (
        <Tag className={classes.clubTag} title="Your club">
          Your club
        </Tag>
      ) : null}
      {club.isSuspended ? (
        <Tag className={classes.clubTag} title="Suspended">
          Suspended
        </Tag>
      ) : null}
    </Card>
  );
};

const JoinToClub: React.FunctionComponent = () => {
  const {joinClub} = useClubsPrx();
  const [isOpened, setIsOpened] = React.useState<boolean>(false);

  const [onChangeClubId, clubId] = useInputState(
    '',
    parseString,
    skipValidation,
  );
  const [onChangeAgentId, agentId] = useInputState(
    '',
    parseString,
    skipValidation,
  );

  const [ctx] = useCancelContext();
  const key = useIdempotenceKey([clubId]);
  const [joinResponse, doJoinClub] = useRequest(
    async () => {
      if (clubId.error != null) {
        throw new Error(`Input error: ${clubId.error}`);
      }
      const result = await joinClub(
        key,
        clubId.value,
        agentId.value === '' ? undefined : agentId.value,
      );
      setIsOpened(false);
      return result;
    },
    [key, clubId, agentId, setIsOpened, joinClub],
    ctx,
  );

  const onAir = joinResponse?.type === 'started';

  const handleOpen = React.useCallback(() => {
    setIsOpened(true);
  }, [setIsOpened]);
  const handleClosed = React.useCallback(() => {
    if (!onAir) {
      setIsOpened(false);
    }
  }, [setIsOpened, onAir]);

  return (
    <>
      <Button
        className={classes.join}
        intent={Intent.PRIMARY}
        large={true}
        onClick={handleOpen}
        icon="plus"
      />
      <Dialog
        isOpen={isOpened}
        className={classes.dialog}
        onClose={handleClosed}
        title="Join club"
      >
        <Form className={classes.form}>
          <FormGroup
            className={classes.formGroup}
            inline={true}
            labelFor="text-input"
            label="Club ID"
            helperText={clubId.error}
          >
            <InputGroup
              autoFocus={true}
              value={clubId.rawValue}
              id="text-input"
              onChange={onChangeClubId}
            />
          </FormGroup>
          <FormGroup
            className={classes.formGroup}
            inline={true}
            labelFor="text-input"
            label="Agent ID"
          >
            <InputGroup
              value={agentId.rawValue}
              id="text-input"
              onChange={onChangeAgentId}
            />
          </FormGroup>
          <Button
            loading={onAir}
            disabled={clubId.error != null}
            className={classes.submit}
            type="submit"
            intent={Intent.PRIMARY}
            onClick={doJoinClub}
          >
            Join
          </Button>
        </Form>
      </Dialog>
      <ResponseErrorToast response={joinResponse} />
    </>
  );
};

const CreateClub: React.FunctionComponent = () => {
  const {createClub} = useClubsPrx();
  const [isOpened, setIsOpened] = React.useState<boolean>(false);

  const [onClubNameChange, clubName] = useInputState(
    '',
    parseString,
    skipValidation,
  );

  const [ctx] = useCancelContext();
  const key = useIdempotenceKey([clubName]);
  const [createResponse, doCreateClub] = useRequest(
    async () => {
      if (clubName.error != null) {
        throw new Error(`Input error: ${clubName.error}`);
      }
      const result = await createClub(
        key,
        create(Gazebo.Clubs.ClubParams, {name: clubName.value}),
      );
      setIsOpened(false);
      return result;
    },
    [key, clubName, setIsOpened, createClub],
    ctx,
  );

  const onAir = createResponse?.type === 'started';

  const handleOpen = React.useCallback(() => {
    setIsOpened(true);
  }, [setIsOpened]);
  const handleClosed = React.useCallback(() => {
    if (!onAir) {
      setIsOpened(false);
    }
  }, [setIsOpened, onAir]);

  return (
    <>
      <Button
        className={classes.addClub}
        intent={Intent.PRIMARY}
        large={true}
        onClick={handleOpen}
        icon="add"
      />
      <Dialog
        isOpen={isOpened}
        className={classes.dialog}
        onClose={handleClosed}
        title="Create club"
      >
        <Form className={classes.form}>
          <FormGroup
            className={classes.formGroup}
            inline={true}
            labelFor="text-input"
            label="Name"
            helperText={clubName.error}
          >
            <InputGroup
              autoFocus={true}
              value={clubName.rawValue}
              id="text-input"
              onChange={onClubNameChange}
            />
          </FormGroup>
          <Button
            loading={onAir}
            disabled={clubName.error != null}
            className={classes.submit}
            type="submit"
            intent={Intent.PRIMARY}
            onClick={doCreateClub}
          >
            Create
          </Button>
        </Form>
      </Dialog>
      <ResponseErrorToast response={createResponse} />
    </>
  );
};

export const Content: React.FunctionComponent = () => {
  const match =
    useRouteMatch<{areaId: string; gameId: string}>('/:areaId/:gameId?');
  const areaId = match?.params.areaId;
  const club = useClub(match?.params.areaId);
  const gameId = match?.params.gameId;
  const clubId = club?.data?.clubId;
  const isGlobalLobby = areaId === 'global';
  const namespace = getNamespace();
  const loggiaBaseUrl = window.location.origin.includes('localhost')
    ? 'http://localhost:8010'
    : 'https://loggia.kube.poks.poker';
  return match != null && gameId != null ? (
    gameId.startsWith('table') ? (
      <Table clubId={clubId} tableId={gameId} />
    ) : (
      <Tournament clubId={clubId} tournamentId={gameId} />
    )
  ) : (
    <>
      <Link
        href={`${loggiaBaseUrl}/?app=Tables&level=error${
          namespace ? `&ns=${namespace}` : ''
        }`}
        text={'Loggia Tables'}
      />
      {match == null ? (
        <LobbyRoot />
      ) : (
        <>
          <Link
            href={
              isGlobalLobby
                ? `${window.location.origin}/admin`
                : `${
                    window.location.origin.includes('test-client')
                      ? window.location.origin.replace('test-client.', '')
                      : 'http://localhost:24291'
                  }/${clubId}/`
            }
            text={isGlobalLobby ? 'Global Lobby Admin' : 'CH Webapp'}
          />
          <Tournaments areaId={areaId} />
          <Tables areaId={areaId} />
        </>
      )}
    </>
  );
};
