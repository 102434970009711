import {Classes as blueprintClasses, Colors} from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import 'normalize.css';

import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import {useHistory} from 'react-router-dom';
import {BrowserRouter} from 'react-router-dom';

import {AuthProvider, useAuthContext} from './auth';
import {IceClientAuth, IceClientProvider} from './ice-client-react';
import {iceProperties} from './iceProperties';
import {logger} from './logger';
import {Routes} from './routes';
import * as serviceWorker from './serviceWorker';

import {UserSession} from './auth/userSession';
import {getClass} from './utils/css';
import {getClasses} from './utils/css';

export const scrollbarThickness = 10;
export const classes = getClasses({
  root: {
    maxHeight: '100vh',
    maxWidth: '100vw',
    overflow: 'hidden',
    $nest: {
      [`.${blueprintClasses.CONTROL_INDICATOR}, ` +
      `.${blueprintClasses.SELECT} select, ` +
      `.${blueprintClasses.BUTTON}, ` +
      `& .${blueprintClasses.BUTTON}`]: {
        boxShadow: 'none !important',
      },
      '*': {
        borderColor: Colors.DARK_GRAY5,
      },
    },
  },
  customScrollbar: {
    $nest: {
      '& ::-webkit-scrollbar': {
        width: scrollbarThickness,
        height: scrollbarThickness,
      },
      '& ::-webkit-scrollbar-track': {
        background: 'rgba(16, 22, 26, 0.5)',
      },
      '& ::-webkit-scrollbar-thumb': {
        borderRadius: scrollbarThickness,
        background: Colors.DARK_GRAY5,
      },
      '& ::-webkit-scrollbar-corner': {
        background: 'rgba(0, 0, 0, 0.5)',
      },
    },
  },
});

document.documentElement.className = classes.customScrollbar;

document.body.className = classNames(
  getClass({
    backgroundColor: Colors.DARK_GRAY3,
    maxHeight: '100vh',
    maxWidth: '100vw',
    overflow: 'hidden',
  }),
  blueprintClasses.DARK,
);

const logLevel = iceProperties.getProperty('Webapp.LogLevel');
console.log('[LOG] Level', logLevel);

logger.level = logLevel;

const Authenticator: React.FunctionComponent = ({children}) => {
  const {accessToken} = useAuthContext();
  if (accessToken == null) {
    return null;
  }
  return (
    <IceClientAuth>
      <IceClientProvider accessToken={accessToken}>
        <UserSession>{children}</UserSession>
      </IceClientProvider>
    </IceClientAuth>
  );
};

const App = () => {
  const history = useHistory();
  return (
    <AuthProvider
      basePath={iceProperties.getProperty('Webapp.BasePath')}
      afterLogoutRedirectURI={window.location.origin}
      clientID={iceProperties.getProperty('Webapp.Auth0.ClientID')}
      domain={iceProperties.getProperty('Webapp.Auth0.Domain')}
      audience={iceProperties.getProperty('Webapp.Auth0.Audience')}
      history={history}
    >
      <Authenticator>
        <Routes />
      </Authenticator>
    </AuthProvider>
  );
};

const root = document.getElementById('root');

if (root != null) {
  root.className = classes.root;
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={iceProperties.getProperty('Webapp.BasePath')}>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  root,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
