import * as React from 'react';
import {useRouteMatch} from 'react-router-dom';

import {ClubsProvider} from '../../store/clubs';
import {TablesProvider} from '../../store/clubTables';
import {TournamentsProvider} from '../../store/tournaments';

import {Content} from './Content';
import {Layout} from './Layout';

export const Lobby: React.FunctionComponent = () => {
  const match = useRouteMatch<{areaId: string}>('/:areaId');
  return (
    <ClubsProvider>
      <TournamentsProvider areaId={match?.params.areaId}>
        <TablesProvider areaId={match?.params.areaId}>
          <Layout>
            <Content />
          </Layout>
        </TablesProvider>
      </TournamentsProvider>
    </ClubsProvider>
  );
};
