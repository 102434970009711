import {Button, Dialog, InputGroup, Intent} from '@blueprintjs/core';
import * as React from 'react';

import {useUserInfoPrx} from '../../store/userInfo';
import {getClasses} from '../../utils/css';
import {useIdempotenceKey} from '../../utils/idempotencyKey';

import {
  parseString,
  skipValidation,
  useInputState,
} from '../../utils/inputState';
import {ResponseErrorToast} from '../../utils/toast';
import {useCancelContext} from '../../utils/useCancelContext';
import {useRequest} from '../../utils/useRequest';

const classes = getClasses({
  dialog: {
    width: 300,
  },
  input: {
    padding: 15,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  button: {
    width: 70,
    height: 40,
  },
  submit: {
    marginTop: 15,
    width: 60,
  },
});

export const ChangeAvatarDialog: React.FunctionComponent = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const handleClose = React.useCallback(() => setIsOpen(false), [setIsOpen]);
  const {changeAvatar} = useUserInfoPrx();

  const [onAvatarChanged, avatar] = useInputState(
    '',
    parseString,
    skipValidation,
  );

  const [ctx] = useCancelContext();
  const idempotencyKey = useIdempotenceKey([avatar.value]);
  const [changeAvatarResponse, doChangeAvatar] = useRequest(
    () => {
      setIsOpen(false);
      return changeAvatar(idempotencyKey, avatar.value);
    },
    [avatar, changeAvatar, setIsOpen, idempotencyKey],
    ctx,
  );
  return (
    <>
      <Button className={classes.button} onClick={handleOpen}>
        Change avatar
      </Button>
      <Dialog
        isOpen={isOpen}
        title="Change avatar"
        onClose={handleClose}
        className={classes.dialog}
      >
        <div className={classes.input}>
          <InputGroup
            fill={true}
            onChange={onAvatarChanged}
            value={avatar.rawValue}
            autoFocus={true}
          />
          <Button
            className={classes.submit}
            type="submit"
            intent={Intent.PRIMARY}
            onClick={doChangeAvatar}
          >
            Confirm
          </Button>
          <ResponseErrorToast response={changeAvatarResponse} />
        </div>
      </Dialog>
    </>
  );
};
