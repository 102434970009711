import {Gazebo} from '@slices/Gazebo';

import {useIceProxy} from '../ice-client-react';

export const useTournamentMaintenancePrx = () =>
  useIceProxy({
    type: 'static',
    propertyName: 'Webapp.TournamentMaintenance',
    proxyClass: Gazebo.TournamentMaintenance.ServicePrx,
  });
