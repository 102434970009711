import * as React from 'react';

import {getClasses} from 'src/utils/css';

const classes = getClasses({
  svg: {
    fill: 'hsla(200,20%,80%,0.5)',
  },
});

export const lobbyIcon = (
  <svg
    className={classes.svg}
    data-icon="my_icon"
    width="16"
    height="16"
    viewBox="0 0 296 296"
  >
    <desc>clubs icon</desc>
    <path
      d="M148.237,0C66.368,0,0.001,66.367,0.001,148.236s66.367,148.236,148.236,148.236c81.867,0,148.234-66.367,148.234-148.236
		S230.105,0,148.237,0z M184.958,199.929c-10.663,0-20.38-4.012-27.757-10.593c0.548,13.68,2.357,32.038,7.868,41.9h-33.666
		c5.511-9.861,7.32-28.219,7.867-41.897c-7.377,6.579-17.093,10.59-27.756,10.59c-23.055,0-41.744-18.69-41.744-41.746
		c0-21.685,16.533-39.502,37.681-41.545c-0.659-2.946-1.019-6.006-1.019-9.15c0-23.056,18.689-41.745,41.746-41.745
		c23.055,0,41.744,18.689,41.744,41.745c0,3.142-0.358,6.198-1.017,9.142c21.202,1.99,37.796,19.83,37.796,41.554
		C226.702,181.238,208.013,199.929,184.958,199.929z"
    />
  </svg>
);
