import * as React from 'react';
import {useHistory, useLocation} from 'react-router-dom';

/**
 * Предустановленный переход по внутренней ссылке силами React-Router
 */
export const useAppRouteTo = (to: string) => {
  const location = useLocation();
  const history = useHistory();

  return React.useCallback(() => {
    if (to !== location.pathname) {
      history.push(to);
    }
  }, [location, history, to]);
};
