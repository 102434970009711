import {Button, InputGroup, Pre, Text} from '@blueprintjs/core';
import {Ice} from 'ice';
import {differenceBy} from 'lodash';
import React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {useAuthContext} from 'src/auth';
import {PromisifyPrx} from 'src/ice-client/utils';

import {
  getResponseData,
  getResponseError,
  useSubscription,
} from '../../../ice-client-react';
import {useUserInfoPrx} from '../../../store/userInfo';
import {create} from '../../../utils/create';
import {getClasses} from '../../../utils/css';
import {
  parseString,
  skipValidation,
  useInputState,
} from '../../../utils/inputState';
import {ResponseErrorToast} from '../../../utils/toast';
import {useCancelContext} from '../../../utils/useCancelContext';
import {useRequest} from '../../../utils/useRequest';

const classes = getClasses({
  incomingMessagesGroup: {
    position: 'absolute',
    bottom: 50,
    zIndex: 10,
  },
  sendMessageGroup: {
    top: 850,
    left: 1500,
    display: 'grid',
    gridTemplateColumns: '1fr 80px 80px',
    gridGap: 10,
    position: 'absolute',
  },
});

export const Chat: React.FC<{
  chatPrx: PromisifyPrx<Gazebo.Chats.ServicePrx>;
  tableId: string;
}> = ({chatPrx, tableId}) => {
  const {subscribeToChats, sendMessage} = chatPrx;
  const {idTokenPayload} = useAuthContext();
  const userId = idTokenPayload?.sub ?? '';

  const [onMessageInputChanged, messageInput, , setMessageInput] =
    useInputState('', parseString, skipValidation);

  const [ctx] = useCancelContext();

  const [sendMessageReponse, doSendMessage] = useRequest(
    async () => {
      const content = create(Gazebo.Chats.Text, {text: messageInput.rawValue});
      const chatId = create(Gazebo.Chats.TableChatId, {tableId});
      const sender = create(Gazebo.Chats.UserSender, {userId});
      await sendMessage(
        Ice.generateUUID(),
        create(Gazebo.Chats.OutgoingMessage, {content, chatId, sender}),
      );
      setMessageInput('');
    },
    [sendMessage, messageInput.rawValue, setMessageInput, userId, tableId],
    ctx,
  );

  const subscribeToChat = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Chats.AbstractIncomingMessage>,
    ) =>
      subscribeToChats(subscriber, [
        create(Gazebo.Chats.TableChatId, {tableId}),
      ]),
    [subscribeToChats, tableId],
  );

  const chatState = useSubscription(subscribeToChat);
  const chatError = getResponseError(chatState);
  const messagesResponse = React.useMemo(
    () =>
      (getResponseData(chatState) ?? []) as Array<
        Gazebo.Chats.AbstractIncomingMessage | undefined
      >,
    [chatState],
  );

  const messagesUpdates = React.useMemo(
    () =>
      messagesResponse.filter(
        (msg): msg is Gazebo.Chats.AbstractIncomingMessage => msg != undefined,
      ),
    [messagesResponse],
  );

  type Message = {
    id: string;
    sender: string;
    text: string;
  };

  const [messages, setMessages] = React.useState<Message[]>([]);

  React.useEffect(() => {
    const msgs: Message[] = [];
    messagesUpdates.map((msg) => {
      if (
        msg instanceof Gazebo.Chats.IncomingMessage &&
        msg.content instanceof Gazebo.Chats.Text &&
        msg.sender instanceof Gazebo.Chats.UserSender &&
        msg.content instanceof Gazebo.Chats.Text
      ) {
        msgs.push({
          id: msg.messageId,
          text: msg.content.text,
          sender: msg.sender.userId,
        });
      }
    });
    setMessages((prevState) => [
      ...prevState,
      ...differenceBy(msgs, prevState, 'id'),
    ]);
  }, [setMessages, messagesUpdates]);

  const sendersUserIds = React.useMemo(
    () => messages.map(({sender}) => sender),
    [messages],
  );

  const {subscribeToUsers} = useUserInfoPrx();
  const subscribeToUserInfos = React.useCallback(
    (subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.UserInfo.User>) =>
      subscribeToUsers(subscriber, sendersUserIds),
    [subscribeToUsers, sendersUserIds],
  );

  const usersSubscriptionState = useSubscription(subscribeToUserInfos);
  const displayNames = React.useMemo(
    () =>
      new Map(
        getResponseData(usersSubscriptionState)?.map(
          (user) => [user.userId, user.displayName] as const,
        ),
      ),
    [usersSubscriptionState],
  );

  const text = React.useMemo(
    () =>
      messages.map((message) => {
        const senderName = displayNames.get(message.sender) ?? message.sender;
        return (
          <TextMessage
            sender={senderName}
            key={message.id}
            text={message.text}
          />
        );
      }),
    [messages, displayNames],
  );

  return (
    <>
      <div className={classes.sendMessageGroup}>
        <div className={classes.incomingMessagesGroup}>{text}</div>
        <InputGroup
          value={messageInput.rawValue}
          id="text-input"
          onChange={onMessageInputChanged}
          placeholder="Text ur message"
        />
        <Button type="submit" onClick={doSendMessage}>
          Send
        </Button>
        <ResponseErrorToast response={sendMessageReponse} />
        <ResponseErrorToast response={chatError} />
      </div>
    </>
  );
};

const TextMessage: React.FunctionComponent<{text: string; sender: string}> = ({
  text,
  sender,
}) => {
  return (
    <Text>
      {sender}: {text}
    </Text>
  );
};
