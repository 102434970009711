export function getNamespace() {
  if (window.location.origin.includes('.prod.')) {
    return 'production';
  }
  if (window.location.origin.includes('.stage.')) {
    return 'stage';
  }
  if (window.location.origin.includes('.dev.')) {
    return 'dev';
  }
  return undefined;
}
