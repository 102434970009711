import * as React from 'react';

import {getClasses} from './css';

const classes = getClasses({
  root: {
    marginBottom: 10,
    marginTop: 5,
  },
});

export const Link: React.FC<{
  href: string;
  text: string;
}> = ({href, text}) => {
  return (
    <div className={classes.root}>
      <a href={href} rel={'noreferrer'} target={'_blank'}>
        {text}
      </a>
    </div>
  );
};
