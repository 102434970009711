import {
  Card,
  Classes,
  H4,
  H5,
  Icon,
  InputGroup,
  Intent,
  Text,
} from '@blueprintjs/core';
import moment from 'moment';
import * as React from 'react';
import {cssRule} from 'typestyle';

import {Gazebo} from '@slices/Gazebo';

import {KillTournament} from '../../components/killTournament';
import {getResponseData, getResponseError} from '../../ice-client-react';
import {useClubsPrx} from '../../store/clubs';
import {useLeaguesPrx} from '../../store/league';
import {useTournamentPrx} from '../../store/tournament';
import {useTournamentMaintenancePrx} from '../../store/tournamentMaintenance';
import {
  useTournamentsClubLobby,
  useTournamentsLeagueLobby,
} from '../../store/tournaments';
import {create} from '../../utils/create';
import {getClasses} from '../../utils/css';
import {useIdempotenceKey} from '../../utils/idempotencyKey';
import {
  parseString,
  skipValidation,
  useInputState,
} from '../../utils/inputState';
import {centsToMainUnits} from '../../utils/numbers';
import {Stopper} from '../../utils/Stopper';
import {ResponseErrorToast} from '../../utils/toast';
import {useCancelContext} from '../../utils/useCancelContext';
import {useRequest} from '../../utils/useRequest';
import {getStatusText} from '../tournamentUtils/tournamentStatusText';

import {CreateTournament} from './createTournament';

export const tournamentClasses = getClasses({
  root: {
    display: 'grid',
    gridGap: 25,
  },
  swimlaneContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 5,
  },
  swimlaneHeader: {
    padding: '5px 10px',
    // letterSpacing: 5,
  },
  tournamentCard: {
    display: 'grid',
    gridGap: 5,
    maxWidth: 230,
    position: 'relative',
    $nest: {
      [`& .${Classes.ICON}`]: {
        marginRight: 10,
      },
    },
  },
  tournamentCardHeader: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: 5,
  },
  tournamentCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 10,
    marginRight: '0!important',
    cursor: 'pointer',
  },
  tournamentKillButton: {
    top: 0,
    right: 0,
    padding: 0,
    marginRight: '0!important',
    cursor: 'pointer',
  },
});

cssRule('@media only screen and (max-width: 500px)', {
  [`.${tournamentClasses.swimlaneContent}`]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

export type Tournaments = Gazebo.Lobby.AbstractTournament[];
export const TournamentsManagement: React.FunctionComponent = () => {
  const [onClubIdentityChanged, clubIdentity] = useInputState(
    '',
    parseString,
    skipValidation,
  );
  const [onLeagueIdentityChanged, leagueIdentity] = useInputState(
    '',
    parseString,
    skipValidation,
  );
  const [ctx] = useCancelContext();

  const {findClubByIdentity} = useClubsPrx();
  const {findLeagueByIdentity} = useLeaguesPrx();

  const [findClubResponse, doFindClub] = useRequest(
    () =>
      findClubByIdentity(
        isNaN(+clubIdentity.rawValue)
          ? create(Gazebo.Clubs.UUID, {clubId: clubIdentity.rawValue})
          : create(Gazebo.Clubs.DisplayId, {displayId: clubIdentity.rawValue}),
      ),
    [clubIdentity.rawValue, findClubByIdentity],
    ctx,
  );

  const [findLeagueResponse, doFindLeague] = useRequest(
    () =>
      findLeagueByIdentity(
        isNaN(+leagueIdentity.rawValue)
          ? create(Gazebo.Leagues.UUID, {leagueId: leagueIdentity.rawValue})
          : create(Gazebo.Leagues.DisplayId, {
              displayId: leagueIdentity.rawValue,
            }),
      ),
    [leagueIdentity.rawValue, findLeagueByIdentity],
    ctx,
  );

  React.useEffect(() => doFindClub(), [clubIdentity.rawValue, doFindClub]);
  React.useEffect(
    () => doFindLeague(),
    [leagueIdentity.rawValue, doFindLeague],
  );

  const findLeagueError = getResponseError(findLeagueResponse);
  const findClubError = getResponseError(findClubResponse);

  if (findLeagueError) {
    logger.warn({findLeagueError}, 'Error, when finding league');
  }
  if (findClubError) {
    logger.warn({findClubError}, 'Error, when finding club');
  }

  const clubLobby = useTournamentsClubLobby();
  const leagueLobby = useTournamentsLeagueLobby();
  const clubError = getResponseError(clubLobby);
  const leagueError = getResponseError(leagueLobby);
  const rawTournaments = (getResponseData(clubLobby) ?? []).concat(
    getResponseData(leagueLobby) ?? [],
  );

  const tournaments = React.useMemo(() => {
    const groups: Record<string, Tournaments> = {};
    for (const tournament of rawTournaments ?? []) {
      const status =
        (tournament instanceof Gazebo.Lobby.MultiTableTournament &&
          getStatusText(tournament.status)) ||
        'undefined';

      groups[status] = groups[status] ?? [];
      groups[status].push(tournament);
    }
    return [...Object.entries(groups)].sort(
      ([status1], [status2]) => -status1.localeCompare(status2),
    );
  }, [rawTournaments]);

  if (clubError != null || leagueError != null) {
    return <div>Error</div>;
  }
  return (
    <div className={tournamentClasses.root}>
      Area:
      <InputGroup
        value={leagueIdentity.rawValue}
        id="text-input"
        onChange={onLeagueIdentityChanged}
        placeholder="League ID or display ID"
      />
      <InputGroup
        value={clubIdentity.rawValue}
        id="text-input"
        onChange={onClubIdentityChanged}
        placeholder="Club ID or display ID"
      />
      <KillTournament />
      <CreateTournament />
      {tournaments.map(([status, tournaments]) => (
        <Swimlane key={status} status={status} tournaments={tournaments} />
      ))}
    </div>
  );
};

export const Swimlane: React.FunctionComponent<{
  status: string;
  tournaments: Tournaments;
}> = ({status, tournaments}) => {
  return (
    <div>
      <H4 className={tournamentClasses.swimlaneHeader}>{status}</H4>
      <div className={tournamentClasses.swimlaneContent}>
        {tournaments.map((tournament) => (
          <TournamentCard
            key={tournament.tournamentId}
            tournament={tournament}
          />
        ))}
      </div>
    </div>
  );
};

export const TournamentCard: React.FunctionComponent<{
  tournament: Gazebo.Lobby.AbstractTournament;
}> = ({tournament}) => {
  const {killTournament} = useTournamentMaintenancePrx();
  const [ctx] = useCancelContext();
  const key = useIdempotenceKey([tournament.tournamentId]);

  const {applyCommand} = useTournamentPrx(tournament.tournamentId);
  const [closeTournamentResponse, doCloseTournament] = useRequest(
    () => {
      return applyCommand(
        key,
        create(Gazebo.Tournaments.Command.CloseTournament, {}),
      );
    },
    [key, applyCommand],
    ctx,
  );

  const [isStopperOpened, setIsStopperOpened] = React.useState<boolean>(false);
  const [isKillStopperOpened, setIsStopperKillOpened] =
    React.useState<boolean>(false);
  const handleStopperOpen = React.useCallback(() => {
    setIsStopperOpened(true);
  }, [setIsStopperOpened]);
  const handleStopperClose = React.useCallback(() => {
    setIsStopperOpened(false);
  }, [setIsStopperOpened]);

  const handleKillStopperOpen = React.useCallback(() => {
    setIsStopperKillOpened(true);
  }, [setIsStopperKillOpened]);
  const handleKillStopperClose = React.useCallback(() => {
    setIsStopperKillOpened(false);
  }, [setIsStopperKillOpened]);

  const [killTournamentResponse, doKillTournament] = useRequest(
    () => {
      return killTournament(key, tournament.tournamentId);
    },
    [key, killTournament, tournament.tournamentId],
    ctx,
  );

  if (!(tournament instanceof Gazebo.Lobby.MultiTableTournament)) {
    return null;
  }

  return (
    <Card className={tournamentClasses.tournamentCard}>
      <Icon
        className={tournamentClasses.tournamentCloseButton}
        icon="cross"
        onClick={handleStopperOpen}
      />
      <Stopper
        title={'Close tournament'}
        text={`Are you sure you want to close tournament ${tournament.name}?`}
        doRequest={doCloseTournament}
        handleStopperClose={handleStopperClose}
        isOpened={isStopperOpened}
      />
      <H5 className={tournamentClasses.tournamentCardHeader}>
        {tournament.name}
        <Text
          className={Classes.TEXT_MUTED}
        >{`#${tournament.tournamentId}`}</Text>
      </H5>
      <TournamentStatus status={tournament.status} />
      <span>
        <Icon icon="settings" /> {tournament.gameType}
      </span>
      <span>
        <Icon icon="dollar" /> Buy-in:{' '}
        {centsToMainUnits(tournament.buyIn.toNumber())}
      </span>
      <span>
        <Icon icon="time" />
        {tournament.startAtMs != null
          ? `start at ${moment(
              tournament.startAtMs?.toNumber() ?? 0,
            ).fromNow()}`
          : '-'}
      </span>
      <Icon
        className={tournamentClasses.tournamentKillButton}
        icon="trash"
        onClick={handleKillStopperOpen}
      />
      <Stopper
        title={'Close tournament'}
        text={`Are you sure you want to KILL tournament ${tournament.name}?`}
        doRequest={doKillTournament}
        handleStopperClose={handleKillStopperClose}
        isOpened={isKillStopperOpened}
      />
      <ResponseErrorToast response={closeTournamentResponse} />
      <ResponseErrorToast response={killTournamentResponse} />
    </Card>
  );
};

export const TournamentStatus: React.FunctionComponent<{
  status: Gazebo.Lobby.MultiTableTournament['status'];
}> = ({status}) => {
  const statusText = getStatusText(status);
  return statusText ? (
    <span>
      <Icon intent={Intent.NONE} icon="info-sign" />
      {statusText}
    </span>
  ) : null;
};
