import {Gazebo} from '@slices/Gazebo';

import {useIceProxy} from '../ice-client-react';

export const useUserRelationsPrx = () =>
  useIceProxy({
    type: 'static',
    propertyName: 'Webapp.UserRelations',
    proxyClass: Gazebo.UserRelations.ServicePrx,
  });
