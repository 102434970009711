import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {
  SubscriptionState,
  useIceProxy,
  useSubscription,
} from '../ice-client-react';
import {create} from '../utils/create';

export const useTablePrx = (tableId: string) =>
  useIceProxy({
    type: 'dynamic',
    proxyString: `Table/${tableId}`,
    proxyClass: Gazebo.Tables.Poker.TablePrx,
  });

const TableStateContext = React.createContext<
  SubscriptionState<Gazebo.Tables.Poker.TableState>
>({
  type: 'started',
});

export const TableStateProvider: React.SFC<{
  tableId: string;
  clubId?: string;
}> = ({tableId, children, clubId}) => {
  const {subscribeToState} = useTablePrx(tableId);
  const subscribe = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Tables.Poker.TableState>,
    ) =>
      subscribeToState(
        subscriber,
        create(Gazebo.Tables.Poker.ReferrerId, {clubId}),
      ),
    [subscribeToState, clubId],
  );
  const subscription = useSubscription(subscribe);

  return (
    <TableStateContext.Provider value={subscription}>
      {children}
    </TableStateContext.Provider>
  );
};

export const useTableState = () => {
  return React.useContext(TableStateContext);
};
