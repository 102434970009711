import * as React from 'react';
import {Route, Switch} from 'react-router-dom';

import {ActiveGames} from './ActiveGames';
import {Admin} from './Admin';
import {Changelog} from './Changelog';
import {Lobby} from './Clubs';
import {JRPCClient} from './JRPCClient';
import {Promos} from './Promos';

export const Routes: React.FunctionComponent = () => (
  <>
    {/**
     * Наколеночная реализация клиента для json-rpc-gateway
     * с его помощью можно потыкать в консоли интерфейс json-rpc
     * и убедиться что гетвей нормально работает.
     *
     * на работу webapp никак не влияет.
     */}
    <JRPCClient />
    <Switch>
      {/* основные разделы сайта */}
      <Route path="/admin">
        <Admin />
      </Route>
      <Route path="/active-games">
        <ActiveGames />
      </Route>
      <Route path="/promos">
        <Promos />
      </Route>
      <Route path="/changelog">
        <Changelog />
      </Route>
      <Route path="/">
        <Lobby />
      </Route>
    </Switch>
  </>
);
