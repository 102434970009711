import {Button, Dialog, HTMLTable} from '@blueprintjs/core';
import {sortBy} from 'lodash';
import moment from 'moment';
import React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {
  getResponseData,
  getResponseError,
  useSubscription,
} from '../../../ice-client-react';
import {useTableResultsPrx} from '../../../store/tableResults';
import {getClasses} from '../../../utils/css';
import {centsToMainUnits} from '../../../utils/numbers';

const classes = getClasses({
  dialog: {
    width: 1000,
  },
  tableResultsButton: {
    position: 'absolute',
    top: 190,
    zIndex: 10,
  },
});

export const Results: React.SFC<{tableId: string}> = ({tableId}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const handleClose = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  const {subscribeToCurrentSessions} = useTableResultsPrx();
  const subscribeToCurrent = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.TableResults.CurrentSession>,
    ) => subscribeToCurrentSessions(subscriber, tableId),
    [subscribeToCurrentSessions, tableId],
  );
  const currentSessionsState = useSubscription(subscribeToCurrent);
  const currentSessionsError = getResponseError(currentSessionsState);
  const currentSessions = getResponseData(currentSessionsState);

  const {subscribeToCompletedSessions} = useTableResultsPrx();
  const subscribeToCompleted = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.TableResults.CompletedSession>,
    ) =>
      // Подписываемся только на 100 первых сессий.
      // Сейчас должно быть достаточно для тестов.
      subscribeToCompletedSessions(subscriber, tableId),
    [subscribeToCompletedSessions, tableId],
  );
  const completedSessionsState = useSubscription(subscribeToCompleted);
  const completedSessionsError = getResponseError(completedSessionsState);
  const completedSessions = getResponseData(completedSessionsState);

  return (
    <>
      <Button className={classes.tableResultsButton} onClick={handleOpen}>
        Table Results
      </Button>
      <Dialog
        isOpen={isOpen}
        title="Table Results"
        onClose={handleClose}
        className={classes.dialog}
      >
        {currentSessionsError != null ? (
          <div>{currentSessionsError}</div>
        ) : (
          <HTMLTable striped={true} condensed={true}>
            <thead>
              <tr>
                <th>Session Id</th>
                <th>User Id</th>
                <th>User Name</th>
                <th>Buy-In</th>
                <th>Profit</th>
                <th>Hands</th>
                <th>User Session Number</th>
              </tr>
            </thead>
            <tbody>
              {sortBy(currentSessions ?? [], ({order}) => order).map(
                (session) => (
                  <tr key={session.sessionId}>
                    <td>{session.sessionId}</td>
                    <td>{session.userId}</td>
                    <td>{session.userDisplayName}</td>
                    <td>{centsToMainUnits(session.buyIn.toNumber())}</td>
                    <td>{centsToMainUnits(session.profit.toNumber())}</td>
                    <td>{session.hands}</td>
                    <td>{session.userSessionNumber}</td>
                  </tr>
                ),
              )}
            </tbody>
          </HTMLTable>
        )}
        {completedSessionsError != null ? (
          <div>{completedSessionsError}</div>
        ) : (
          <HTMLTable striped={true} condensed={true}>
            <thead>
              <tr>
                <th>Session Id</th>
                <th>User Id</th>
                <th>User Name</th>
                <th>Buy-In</th>
                <th>Profit</th>
                <th>Hands</th>
                <th>User Session Number</th>
                <th>Finished At</th>
              </tr>
            </thead>
            <tbody>
              {sortBy(completedSessions ?? [], ({order}) => order).map(
                (session) => (
                  <tr key={session.sessionId}>
                    <td>{session.sessionId}</td>
                    <td>{session.userId}</td>
                    <td>{session.userDisplayName}</td>
                    <td>{centsToMainUnits(session.buyIn.toNumber())}</td>
                    <td>{centsToMainUnits(session.profit.toNumber())}</td>
                    <td>{session.hands}</td>
                    <td>{session.userSessionNumber}</td>
                    <td>
                      {moment(session.finishedAtMs.toNumber()).format(
                        'DD.MM.YYYY HH:mm',
                      )}
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </HTMLTable>
        )}
      </Dialog>
    </>
  );
};
