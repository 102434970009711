import {Colors} from '@blueprintjs/core';
import * as React from 'react';

import {getClass} from '../../utils/css';

export const getCirpleProgressClasses = ({
  circleSize,
  circleBackground,
  circleColor,
  insetSize,
  insetColor,
}: {
  circleSize: number;
  insetSize: number;
  circleBackground: string;
  circleColor: string;
  insetColor: string;
}) => {
  const progressCircleFillAndSlice = {
    width: circleSize,
    height: circleSize,
    position: 'absolute',
    borderRadius: '50%',
  } as const;

  const progressCircleFill = getClass(progressCircleFillAndSlice);

  const progressCircleSlice = getClass({
    ...progressCircleFillAndSlice,
    clip: `rect(0px, ${circleSize}px, ${circleSize}px, ${circleSize / 2}px)`,
    $nest: {
      [`& .${progressCircleFill}`]: {
        clip: `rect(0px, ${circleSize / 2}px, ${circleSize}px, 0px)`,
        backgroundColor: circleColor,
      },
    },
  });

  const progressCircleOverlay = getClass({
    width: insetSize,
    height: insetSize,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    backgroundColor: insetColor,
    borderRadius: '50%',
  });

  const progressCircle = getClass({
    width: circleSize,
    height: circleSize,

    backgroundColor: circleBackground,
    borderRadius: '50%',
  });

  return {
    progressCircle,
    progressCircleSlice,
    progressCircleFill,
    progressCircleOverlay,
  };
};

type Classes = ReturnType<typeof getCirpleProgressClasses>;

const defaultClasses = getCirpleProgressClasses({
  circleSize: 70,
  insetSize: 58,
  circleBackground: Colors.DARK_GRAY4,
  circleColor: '#1291D4',
  insetColor: Colors.DARK_GRAY4,
});

export const CircleProgress: React.SFC<{
  classes?: Classes;
  start: number;
  end: number;
  current: number;
}> = ({start, end, current, classes = defaultClasses, children}) => {
  const progress = ((end - current) / (end - start)) * 100;
  const transitionLength = end - start;
  const [{fill, bar}, setStyles] = React.useState({
    fill: {
      transform: `rotate(${1.8 * progress}deg)`,
      transition: `transform ${transitionLength}ms linear`,
    },
    bar: {
      transform: `rotate(${1.8 * progress * 2}deg)`,
      transition: `transform ${transitionLength}ms linear`,
    },
  });
  React.useEffect(() => {
    const handle = window.requestAnimationFrame(() => {
      setStyles({
        fill: {
          transform: 'rotate(0deg)',
          transition: `transform ${transitionLength}ms linear`,
        },
        bar: {
          transform: 'rotate(0deg)',
          transition: `transform ${transitionLength}ms linear`,
        },
      });
    });
    return () => cancelAnimationFrame(handle);
  }, [setStyles, transitionLength, start, end]);
  return (
    <div className={classes.progressCircle}>
      <div>
        <div style={fill} className={classes.progressCircleSlice}>
          <div style={fill} className={classes.progressCircleFill} />
        </div>
        <div className={classes.progressCircleSlice}>
          <div style={fill} className={classes.progressCircleFill} />
          <div style={bar} className={classes.progressCircleFill} />
        </div>
      </div>
      <div className={classes.progressCircleOverlay}>{children}</div>
    </div>
  );
};
