import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';

import {createBaseAuthContext} from '../ice-client';

const authContextReducer = (
  state: Record<string, string> = {},
  action:
    | {type: 'accessTokenUpdated'; value: string}
    | {type: 'userSessionUpdated'; value: string},
) => {
  if (action.type === 'accessTokenUpdated') {
    return {
      ...state,
      ...createBaseAuthContext(action.value),
    };
  }

  if (action.type === 'userSessionUpdated') {
    return {
      ...state,
      [Gazebo.Auth.UserSessionContextKey]: action.value,
    };
  }

  return state;
};
export type IceClientAuthContext = [
  Record<string, string>,
  React.Dispatch<
    | {
        type: 'accessTokenUpdated';
        value: string;
      }
    | {
        type: 'userSessionUpdated';
        value: string;
      }
  >,
];

// eslint-disable-next-line no-redeclare
export const IceClientAuthContext = React.createContext<IceClientAuthContext>(
  null as any,
);

export const IceClientAuth: React.SFC = ({children}) => {
  const value = React.useReducer(authContextReducer, {});
  return (
    <IceClientAuthContext.Provider value={value}>
      {children}
    </IceClientAuthContext.Provider>
  );
};

export const useIceClientAuth = () => React.useContext(IceClientAuthContext);
