import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {useIceProxy, useSubscription} from '../ice-client-react';

export const useLeaguesPrx = () =>
  useIceProxy({
    type: 'static',
    propertyName: 'Webapp.Leagues',
    proxyClass: Gazebo.Leagues.ServicePrx,
  });

export const useLeagueSubscription = (leagueId: string | undefined) => {
  const {subscribeToLeague} = useLeaguesPrx();

  const subscribe = React.useMemo(
    () =>
      leagueId != null
        ? (
            subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Leagues.League>,
          ) => subscribeToLeague(subscriber, leagueId)
        : undefined,
    [subscribeToLeague, leagueId],
  );
  return useSubscription(subscribe).data?.[0];
};

export const useLeague = (leagueId: string | undefined) => {
  const league = useLeagueSubscription(leagueId);
  return React.useMemo(() => {
    if (leagueId === undefined || leagueId.length < 4) {
      return undefined;
    }
    return league;
  }, [leagueId, league]);
};
