import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {
  SubscriptionState,
  useIceProxy,
  useSubscription,
} from '../ice-client-react';

const UserGamesContext = React.createContext<
  SubscriptionState<Gazebo.Lobby.AbstractUserGameInfo>
>({type: 'started'});

export const UserGamesProvider: React.SFC = ({children}) => {
  const {subscribeToUserGames} = useIceProxy<Gazebo.Lobby.ServicePrx>({
    type: 'static',
    propertyName: 'Webapp.Lobby',
    proxyClass: Gazebo.Lobby.ServicePrx,
  });

  const subscribe = React.useMemo(
    () =>
      (
        subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Lobby.AbstractUserGameInfo>,
      ) => {
        return subscribeToUserGames(subscriber);
      },
    [subscribeToUserGames],
  );
  const subscription = useSubscription(subscribe);

  return (
    <UserGamesContext.Provider value={subscription}>
      {children}
    </UserGamesContext.Provider>
  );
};

export const useUserGames = () => {
  return React.useContext(UserGamesContext);
};
