import {Ice} from 'ice';
import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {
  SubscriptionState,
  useIceProxy,
  useSubscription as useSubscriptionOrigin,
} from '../ice-client-react';
import {create} from '../utils/create';

export const useTournamentPrx = (tournamentId: string) =>
  useIceProxy({
    type: 'dynamic',
    proxyString: `${Gazebo.Tournaments.proxyCategory}/${tournamentId}`,
    proxyClass: Gazebo.Tournaments.TournamentPrx,
  });

type AllTournamentStates = {
  general: SubscriptionState<Gazebo.Tournaments.AbstractGeneralTournamentState>;
  tablesState: SubscriptionState<Gazebo.Tournaments.AbstractTournamentTableState>;
  prizeDistribution: SubscriptionState<Gazebo.Tournaments.AbstractTournamentPrizeDistributionState>;
  participants: SubscriptionState<Gazebo.Tournaments.AbstractTournamentParticipantState>;
  commandRequirements: SubscriptionState<Gazebo.Tournaments.CommandRequirement.AbstractCommandRequirement>;
  heroParticipation: SubscriptionState<Gazebo.Tournaments.HeroParticipationState>;
  blindLevel: SubscriptionState<Gazebo.Tournaments.LevelState>;
  lifecycle: SubscriptionState<Gazebo.Tournaments.AbstractTournamentLifecycleState>;
};

const TournamentStateContext = React.createContext<AllTournamentStates>({
  general: {type: 'started'},
  tablesState: {type: 'started'},
  prizeDistribution: {type: 'started'},
  participants: {type: 'started'},
  commandRequirements: {type: 'started'},
  heroParticipation: {type: 'started'},
  blindLevel: {type: 'started'},
  lifecycle: {type: 'started'},
});

const useSubscription = <Item extends Ice.Value>(
  createSubscription?: (
    subscriberPrx: Subscriptions.CollectionSubscriberPrx<Item>,
    referrerId?: Gazebo.Tournaments.ReferrerId,
    ctx?: Ice.Context | undefined,
  ) => Promise<Subscriptions.SubscriptionPrx | null>,
  referrerId?: Gazebo.Tournaments.ReferrerId,
): SubscriptionState<Item> => {
  const subscribe = React.useMemo(
    () =>
      createSubscription != null
        ? (subscriber: Subscriptions.CollectionSubscriberPrx<Item>) =>
            createSubscription(subscriber, referrerId)
        : undefined,
    [createSubscription, referrerId],
  );
  return useSubscriptionOrigin(subscribe);
};

export const TournamentStateProvider: React.SFC<{
  tournamentId: string;
  clubId?: string;
}> = ({tournamentId, children, clubId}) => {
  const {
    subscribeToCommandRequirements,
    subscribeToGeneralState,
    subscribeToParticipantsState,
    subscribeToPrizeDistributionState,
    subscribeToHeroParticipationState,
    subscribeToLevelState,
    subcribeToLifecycleState,
    subscribeToTablesState,
  } = useTournamentPrx(tournamentId);

  const referrerId = React.useMemo(
    () =>
      clubId != null
        ? create(Gazebo.Tournaments.ReferrerId, {clubId})
        : undefined,
    [clubId],
  );

  const generalStateSubscription = useSubscription(
    subscribeToGeneralState,
    referrerId,
  );
  const participantsStateSubscription = useSubscription(
    subscribeToParticipantsState,
    referrerId,
  );
  const prizeDistributionStateSubscription = useSubscription(
    subscribeToPrizeDistributionState,
    referrerId,
  );
  const commandRequirementsStateSubscription = useSubscription(
    subscribeToCommandRequirements,
    referrerId,
  );
  const heroParticipationStateSubscription = useSubscription(
    subscribeToHeroParticipationState,
    referrerId,
  );
  const levelStateSubscription = useSubscription(
    subscribeToLevelState,
    referrerId,
  );
  const lifecycleSubscription = useSubscription(
    subcribeToLifecycleState,
    referrerId,
  );
  const tablesStateSubscription = useSubscription(
    subscribeToTablesState,
    referrerId,
  );

  return (
    <TournamentStateContext.Provider
      value={{
        general: generalStateSubscription,
        tablesState: tablesStateSubscription,
        prizeDistribution: prizeDistributionStateSubscription,
        participants: participantsStateSubscription,
        commandRequirements: commandRequirementsStateSubscription,
        heroParticipation: heroParticipationStateSubscription,
        blindLevel: levelStateSubscription,
        lifecycle: lifecycleSubscription,
      }}
    >
      {children}
    </TournamentStateContext.Provider>
  );
};

export const useTournamentState = () => {
  return React.useContext(TournamentStateContext);
};
