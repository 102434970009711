import {Gazebo} from '@slices/Gazebo';

import {useIceProxy} from '../ice-client-react';

export const useHandHistoryPrx = () =>
  useIceProxy({
    type: 'static',
    propertyName: 'Webapp.HandHistory',
    proxyClass: Gazebo.HandHistory.ServicePrx,
  });
