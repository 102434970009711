import {Gazebo} from '@slices/Gazebo';

import {useIceProxy} from '../ice-client-react';

export const useUserSessionsPrx = () =>
  useIceProxy({
    type: 'static',
    propertyName: 'Webapp.UserSessions',
    proxyClass: Gazebo.UserSessions.ServicePrx,
  });
