import {Gazebo} from '@slices/Gazebo';

import {useIceProxy} from '../ice-client-react';

export const useTableRatingsPrx = () =>
  useIceProxy({
    type: 'static',
    propertyName: 'Webapp.TableRatings',
    proxyClass: Gazebo.TableRatings.ServicePrx,
  });
