import {CancelContext, delay, retry} from '@neolab/cancel-context';
import {Ice} from 'ice';
import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';

import {IceClientAuthContext, useIceClientAuth} from 'src/ice-client-react';
import {PromisifyPrx} from 'src/ice-client/utils';
import {create} from 'src/utils/create';
import {useCancelContext} from 'src/utils/useCancelContext';

import {iceProperties} from '../iceProperties';
import {logger} from '../logger';
import {useUserSessionsPrx} from '../store/userSessions';

import {AuthResult, useAuthContext} from '.';

export const UserSession: React.SFC = ({children}) => {
  const authResult = useAuthContext();
  const authResultRef = React.useRef(authResult);
  React.useEffect(() => {
    authResultRef.current = authResult;
  }, [authResult, authResultRef]);

  const [ctx] = useCancelContext();
  const userSessionsPrx = useUserSessionsPrx();
  const [, authCtxDispatch] = useIceClientAuth();

  React.useEffect(() => {
    touchUserSession(
      ctx,
      authCtxDispatch,
      userSessionsPrx,
      authResultRef,
    ).catch((err) => {
      logger.error({err}, 'Error while user session create or update');
      throw err;
    });
  }, [ctx, authCtxDispatch, userSessionsPrx, authResultRef]);

  return <>{children}</>;
};

const touchUserSession = async (
  ctx: CancelContext,
  authCtxDispatch: IceClientAuthContext[1],
  userSessionsPrx: PromisifyPrx<Gazebo.UserSessions.ServicePrx>,
  authResult: React.MutableRefObject<AuthResult>,
) => {
  const key = Ice.generateUUID();
  const info = create(Gazebo.UserSessions.UserInfo, {
    appVersion: iceProperties.getProperty('Webapp.Version'),
    deviceType: 'web-app',
    deviceId: 'test-client',
    displayName: authResult.current.userMetadata.displayName,
    regDateMs: new Ice.Long(
      new Date(authResult.current.userMetadata.regDate).getTime(),
    ),
    locale: 'unknown',
    ip: await getIp(),
    deviceManufacturer: 'pokerplatform.io',
    deviceModel: 'test-client',
    deviceName: 'test-client',
    osName: 'web',
    osVersion: 'unknown',
    appName: 'test-client',
    appNamespace: 'test-client',
    appBuild: 'master',
    latitude: 0,
    longitude: 0,
  });
  let currentSession: Gazebo.UserSessions.UserSession = await retry(
    ctx,
    () => userSessionsPrx.startUserSession(key, info),
    {
      onError: ({error: err, delayMs, attempt}) => {
        logger.warn(
          {err, delayMs, attempt},
          'Error while starting user session',
        );
      },
    },
  );
  while (!ctx.isCanceled()) {
    const renewsIntervalMs =
      currentSession?.renewsIntervalMs?.toNumber() ?? 60 * 1000;
    await delay(ctx, renewsIntervalMs);

    const key = Ice.generateUUID();
    const session: Gazebo.UserSessions.UserSession = currentSession;
    currentSession = await retry(
      ctx,
      () => userSessionsPrx.renew(key, session),
      {
        maxAttempts: 10,
        onError: ({error: err, delayMs, attempt}) =>
          logger.warn(
            {err, delayMs, attempt},
            'Error while renewing user session',
          ),
      },
    );

    authCtxDispatch({
      type: 'userSessionUpdated',
      value: currentSession.sessionId!,
    });
  }
};

const getIp = async () => {
  const response = await fetch('https://api.ipify.org/?format=json');
  const {ip} = await response.json();
  return ip;
};
