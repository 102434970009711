import classnames, {Argument} from 'classnames';
import {prefix as prefixer} from 'inline-style-prefixer';
import {mapValues} from 'lodash';
import {keyframes, style as getStyle, types} from 'typestyle';

export function getClass(...objects: types.NestedCSSProperties[]): string {
  return getStyle(...objects.map((style) => prefixer(style)));
}

export function getClasses<
  T extends {
    [key: string]: types.NestedCSSProperties | types.NestedCSSProperties[];
  },
  R extends {[key in keyof T]: string},
>(styles: T): R {
  return mapValues(
    styles,
    // TODO Remove casting "as types.NestedCSSProperties". See same issue https://github.com/Microsoft/TypeScript/issues/21368
    (style) =>
      style instanceof Array
        ? getClass(...style)
        : getClass(style as types.NestedCSSProperties),
  ) as R;
}

export function getAnimationName(frames: types.KeyFrames): string {
  const prefixedFrames = {...frames};
  for (const key in prefixedFrames) {
    if (Object.hasOwnProperty.call(prefixedFrames, key)) {
      prefixedFrames[key] = prefixer(prefixedFrames[key]);
    }
  }
  return keyframes(prefixedFrames);
}

export function getAnimationsNames<
  T extends {[key: string]: types.KeyFrames},
  R extends {[key in keyof T]: string},
>(animations: T): R {
  return mapValues(animations, (frames) => getAnimationName(frames)) as R;
}

/**
 * Небольшая модификация известной функции classnames.
 *
 * Отличие в том, что в качестве параметров могут быть нераспакованные
 * массивы, то есть можно без опаски писать:
 *
 *    klassnames('aa', 'bb', ['cc', 'dd'])
 * - что даст 'aa,bb,cc,dd'.
 */
export const klassnames = (...args: Array<Argument | Argument[]>): string =>
  classnames(
    args.reduce((acc: Argument[], arg) => {
      return arg
        ? Array.isArray(arg)
          ? [...acc, ...arg]
          : [...acc, arg]
        : acc;
    }, []),
  );
