import {
  Button,
  Card,
  Classes,
  Elevation,
  H5,
  Icon,
  Intent,
  Text,
} from '@blueprintjs/core';
import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';

import {usePromoPrx} from '../../store/promo';
import {getClasses} from '../../utils/css';
import {useCancelContext} from '../../utils/useCancelContext';
import {useRequest} from '../../utils/useRequest';

import {NavigationBar} from './NavigationBar';

const classes = getClasses({
  root: {
    maxWidth: '100vw',
    maxHeight: '100vh',
    display: 'grid',
    justifyContent: 'center',
  },
  content: {
    marginTop: 100,
    display: 'flex',
    maxWidth: '100vw',
    maxHeight: '100vh',
    overflow: 'auto',
    flexDirection: 'row',
    justifyContent: 'center',
    $nest: {
      '&>*': {
        maxWidth: 1000,
      },
    },
  },
  bottom: {
    marginTop: 100,
    display: 'flex',
    maxWidth: '100vw',
    maxHeight: '100vh',
    overflow: 'auto',
    justifyContent: 'center',
    flexDirection: 'column',
    $nest: {
      '&>*': {
        maxWidth: 1000,
      },
    },
  },
  nextReward: {
    maxWidth: '100vw',
    padding: 20,
    fontSize: 18,
    textAlign: 'center',
  },
  rewardCard: {
    display: 'grid',
    marginRight: 10,
    marginLeft: 10,
    gridGap: 5,
    maxWidth: 130,
    $nest: {
      [`& .${Classes.ICON}`]: {
        marginRight: 10,
      },
    },
  },
  rewardCardHeader: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: 5,
  },
  claimButton: {
    borderRadius: 10,
    width: 100,
    height: 50,
    alignSelf: 'center',
  },
});

export const Promos: React.FunctionComponent = () => {
  const {getDailyLoginRewardsInfo, claimDailyLoginReward} = usePromoPrx();

  const [ctx] = useCancelContext();
  const [rewardsInfoResponse, doRequest] = useRequest(
    async () => {
      const result = await getDailyLoginRewardsInfo();
      return result;
    },
    [getDailyLoginRewardsInfo],
    ctx,
  );
  React.useEffect(() => {
    doRequest();
  }, [doRequest]);
  const rewardsInfo = React.useMemo(() => {
    return rewardsInfoResponse?.data;
  }, [rewardsInfoResponse]);
  const [, doClaimDailyReward] = useRequest(
    async () => {
      return await claimDailyLoginReward();
    },
    [claimDailyLoginReward],
    ctx,
  );
  if (!rewardsInfo) {
    return <div>Loading data...</div>;
  }
  return (
    <div className={classes.root}>
      <NavigationBar />
      <div className={classes.content}>
        {rewardsInfo
          ? rewardsInfo.dailyRewards.map((rewards, ind) => (
              <RewardCard
                dayRewards={rewards}
                dayNumber={ind + 1}
                isReceived={
                  ind < rewardsInfo!.todayIndex ||
                  (ind === rewardsInfo!.todayIndex && rewardsInfo!.claimedToday)
                }
                key={ind}
              />
            ))
          : null}
      </div>
      <div className={classes.bottom}>
        <Button
          type="submit"
          className={classes.claimButton}
          intent={rewardsInfo.claimedToday ? Intent.NONE : Intent.SUCCESS}
          onClick={doClaimDailyReward}
        >
          Claim
        </Button>
        <Text className={classes.nextReward}>
          {rewardsInfoResponse?.data?.nextDayStartInMs
            ? `Next reward: ${new Date(
                rewardsInfoResponse.data.nextDayStartInMs.toNumber(),
              ).toDateString()}`
            : null}
        </Text>
      </div>
    </div>
  );
};

const RewardCard: React.FunctionComponent<{
  dayRewards: Gazebo.Promo.AbstractRewardSeq;
  dayNumber: number;
  isReceived: boolean;
}> = ({dayRewards, dayNumber, isReceived}) => {
  const gemsAmount = dayRewards.reduce(
    (acc, reward) =>
      reward instanceof Gazebo.Promo.GemsReward ? acc + reward.amount : acc,
    0,
  );
  return (
    <Card className={classes.rewardCard} elevation={Elevation.TWO}>
      <H5 className={classes.rewardCardHeader}>{`Day ${dayNumber}`}</H5>
      <span>
        <Icon
          size={25}
          icon="symbol-diamond"
          style={{marginLeft: 5}}
          intent={isReceived ? Intent.NONE : Intent.PRIMARY}
        />
        {gemsAmount}
      </span>
      <RewardStatus received={isReceived} />
    </Card>
  );
};

const RewardStatus: React.FunctionComponent<{
  received: boolean;
}> = ({received}) => {
  return received ? (
    <span>
      <Icon intent={Intent.SUCCESS} icon="endorsed" />
      Received
    </span>
  ) : (
    <span>
      <Icon intent={Intent.NONE} icon="circle" />
      Pending
    </span>
  );
};
