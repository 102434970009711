import {Gazebo} from '@slices/Gazebo';

import {useIceProxy} from '../ice-client-react';

export const usePromoPrx = () =>
  useIceProxy({
    type: 'static',
    propertyName: 'Webapp.Promo',
    proxyClass: Gazebo.Promo.ServicePrx,
  });
