import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {
  SubscriptionState,
  useIceProxy,
  useSubscription,
} from '../ice-client-react';

import {useClub} from './clubs';

const ClubTablesContext = React.createContext<
  SubscriptionState<Gazebo.Lobby.PokerTable>
>({type: 'started'});

export const TablesProvider: React.SFC<{
  areaId: string | undefined;
}> = ({children, areaId}) => {
  const {subscribeToTables} = useIceProxy<Gazebo.Lobby.ServicePrx>({
    type: 'static',
    propertyName: 'Webapp.Lobby',
    proxyClass: Gazebo.Lobby.ServicePrx,
  });

  const club = useClub(areaId === 'global' ? undefined : areaId)?.data;
  const subscribe = React.useMemo(
    () =>
      club == null
        ? undefined
        : (
            subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Lobby.AbstractTable>,
          ) => {
            const areaIds = club.leagueId
              ? [club.leagueId, club.clubId]
              : [club.clubId];
            return subscribeToTables(subscriber, areaIds);
          },
    [club, subscribeToTables],
  );
  const subscription = useSubscription(subscribe);

  return (
    <ClubTablesContext.Provider value={subscription}>
      {children}
    </ClubTablesContext.Provider>
  );
};

export const useClubTables = () => {
  return React.useContext(ClubTablesContext);
};
