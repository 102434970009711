import {Position, Toaster} from '@blueprintjs/core';
import {useCallback, useEffect, useState} from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {
  getResponseData,
  useIceProxy,
  useSubscription,
} from '../ice-client-react';

export const useStickersPrx = () =>
  useIceProxy({
    type: 'static',
    propertyName: 'Webapp.Stickers',
    proxyClass: Gazebo.Stickers.ServicePrx,
  });

const toaster = Toaster.create({
  position: Position.TOP,
});

// В такой реализации при повторном заходе на стол покажутся события, которые
// уже могли показываться ранее. Скорее всего это можно исправить,
// используя React.Context.
export const useStickers = (tableId: string) => {
  const [shownStickers, setShownStickers] = useState(new Set<string>());
  const {subscribeToStickerEvents} = useStickersPrx();
  const subscribeToStickers = useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Stickers.Event>,
    ) => subscribeToStickerEvents(subscriber, tableId),
    [subscribeToStickerEvents, tableId],
  );
  const stickersState = useSubscription(subscribeToStickers);
  useEffect(() => {
    for (const event of getResponseData(stickersState) ?? []) {
      if (shownStickers.has(event.id)) {
        continue;
      }
      toaster.show({
        message: `${event.senderUserId} sent sticker ${event.sticker.stickerUri} to ${event.targetUserId}`,
      });
      setShownStickers(new Set([...shownStickers, event.id]));
    }
  }, [setShownStickers, stickersState, shownStickers]);
};
