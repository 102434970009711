import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';

import {useIceProxy} from '../ice-client-react';
import {iceProperties} from '../iceProperties';

export const useChatsPrx = () => {
  const proxy = useIceProxy({
    type: 'dynamic',
    proxyString: `${Gazebo.Chats.proxyCategory}`,
    proxyClass: Gazebo.Chats.ServicePrx,
  });
  return React.useMemo(
    () =>
      iceProperties.getProperty('Webapp.Chats') === '' ? undefined : proxy,
    [proxy],
  );
};
