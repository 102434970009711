import {
  Button,
  Classes,
  Colors,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core';
import classnames from 'classnames';
import {Ice} from 'ice';
import moment from 'moment';
import * as React from 'react';
import {useMemo} from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {Form} from 'src/utils/form';
import {parseCents, useInputState} from 'src/utils/inputState';

import {
  getResponseData,
  getResponseError,
  useSubscription,
} from '../../../ice-client-react';
import {useChatsPrx} from '../../../store/chats';
import {useStickers} from '../../../store/stickers';
import {
  TableStateProvider,
  useTablePrx,
  useTableState,
} from '../../../store/table';
import {useUserRelationsPrx} from '../../../store/userRelations';
import {create} from '../../../utils/create';
import {getClasses} from '../../../utils/css';
import {useIdempotenceKey} from '../../../utils/idempotencyKey';
import {Link} from '../../../utils/Link';
import {centsToMainUnits} from '../../../utils/numbers';
import {DefaultErrorToast, ResponseErrorToast} from '../../../utils/toast';
import {useCancelContext} from '../../../utils/useCancelContext';
import {useRequest} from '../../../utils/useRequest';
import {cardsStyles} from '../../tableUtils/cards';
import {
  ClickerProvider,
  ClickerSettings,
  useClick,
  useClicker,
} from '../../tableUtils/clicker';
import {RateTableDialog} from '../../tableUtils/tableRating';

import {Chat} from './Chat';
import {HandHistory} from './HandHistory';
import {Results} from './Results';
import {Seats} from './Seats';
import {useIceLong} from './utils';

const BOARDS_VERTICAL_STEP = 70;

const classes = getClasses({
  root: {
    display: 'grid',
    gridTemplateRows: '50px 1fr 1fr',
    maxHeight: '100%',
    gridGap: 25,
    overflow: 'hidden',
  },
  tableHeader: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: 25,
  },
  tableInfo: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  game: {
    position: 'relative',
    width: 365,
    display: 'flex',
    justifyContent: 'center',
    height: 500,
  },
  gameTable: {
    borderRadius: 125,
    borderStyle: 'solid',
    borderWidth: 15,
    height: '105%',
    transform: 'rotate3d(1, 0, 0, 20deg) translateY(-4.5%)',
    width: 350,
    background: `radial-gradient(${Colors.GREEN3}, ${Colors.GREEN1})`,
  },
  gameWrapper: {
    display: 'flex',
    height: 700,
    width: 700,
    justifyContent: 'center',
    alignItems: 'center',
    perspective: 1600,
    perspectiveOrigin: '50% 50%',
  },
  makeDepositDialog: {
    width: 350,
  },
  makeDepositForm: {
    padding: 15,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  makeDepositFormGroup: {
    display: 'grid',
    gridTemplateColumns: '70px 250px',
  },
  makeDepositSubmit: {
    width: 100,
  },
  money: {
    display: 'grid',
    gridTemplateColumns: 'auto 20px',
    alignItems: 'center',
    width: 'auto',
    height: 'auto',
    gridGap: 5,
  },
  moneyStackAmount: {textAlign: 'right'},
  moneyStack: {
    position: 'relative',
    height: 45,
    $nest: {
      '& > *': {
        width: 20,
        height: 20,
        border: '1px solid black!important',
        backgroundColor: Colors.GOLD4,
        borderRadius: 10,
        position: 'absolute',
      },
      '& > *:first-child': {
        boxShadow: '-3px -3px 10px rgba(0,0,0,1)',
      },
      '& > *:nth-child(1)': {
        top: 6,
      },
      '& > *:nth-child(2)': {
        top: 4,
      },
      '& > *:nth-child(3)': {
        top: 2,
      },
      '& > *:nth-child(4)': {
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
        fontSize: 17,
        fontWeight: 900,
      },
    },
  },
  board0: {
    position: 'absolute',
    top: 165,
    left: 35,
    display: 'flex',
    width: 250,
    height: 50,
    flexDirection: 'row',
    justifyContent: 'center',
    $nest: {
      '&  > *': {
        width: 40,
        height: 60,
        position: 'relative',
        background: 'white',
        borderRadius: 5,
        fontSize: 25,
        margin: '0 5px 0 5px',
        boxShadow: '-1px -1px 2px rgba(0,0,0,0.5)',
      },
    },
  },
  board1: {
    position: 'absolute',
    top: 165 + BOARDS_VERTICAL_STEP,
    left: 35,
    display: 'flex',
    width: 250,
    height: 50,
    flexDirection: 'row',
    justifyContent: 'center',
    $nest: {
      '&  > *': {
        width: 40,
        height: 60,
        position: 'relative',
        background: 'white',
        borderRadius: 5,
        fontSize: 25,
        margin: '0 5px 0 5px',
        boxShadow: '-1px -1px 2px rgba(0,0,0,0.5)',
      },
    },
  },
  potsWithBets: {
    fontSize: 14,
    position: 'absolute',
    width: 150,
    top: 65,
    left: 85,
  },
  pots: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  potsWithBetsAmount: {
    textAlign: 'center',
  },
  openedCard: {
    $nest: {
      '&::before': {
        position: 'absolute',
        top: 0,
        left: '8%',
        fontSize: '1em',
      },
      '&::after': {
        position: 'absolute',
        bottom: 0,
        right: '8%',
        fontSize: '1.5em',
      },
      ...cardsStyles,
    },
  },
  dealerButtonWrapper: {
    position: 'absolute',
    width: 20,
    height: 20,
    transition: '1s',
    $nest: {
      '& > *': {
        position: 'absolute',
        border: '1px solid black!important',
        background: Colors.LIGHT_GRAY3,
        borderRadius: '50%',
        overflow: 'hidden',
        height: 20,
        width: 20,
      },
      '& > *:first-child': {
        boxShadow: '-2px -2px 5px rgba(0,0,0,1)',
      },
      '& > *:nth-child(1)': {
        top: 2,
      },
      '& > *:nth-child(2)': {
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
        fontSize: 17,
        fontWeight: 900,
      },
    },
  },
  bet: {
    position: 'absolute',
  },
  commands: {
    display: 'flex',
    overflow: 'auto',
    justifyContent: 'flex-start',
    height: 'max-content',
    flexWrap: 'wrap',
    $nest: {
      '& > *': {
        margin: 5,
      },
    },
  },
  clickerSettingsButton: {
    position: 'absolute',
    top: 110,
    zIndex: 10,
  },
  clickerSettingsWrapper: {
    paddingLeft: 16,
  },
  eventSourcingLink: {
    position: 'absolute',
    top: 270,
    zIndex: 10,
  },
});

(window as any).GazeboCard = Gazebo.Tables.Poker.Card;

export const Table: React.SFC<{
  tableId: string;
  clubId?: string;
}> = ({tableId, clubId}) => {
  useStickers(tableId);
  const chatPrx = useChatsPrx();
  return (
    <ClickerProvider>
      <ClickerSettingsDialog />
      <RateTableDialog tableId={tableId} />
      <>
        {chatPrx == null ? null : <Chat chatPrx={chatPrx} tableId={tableId} />}
      </>
      <Results tableId={tableId} />
      <HandHistory tableId={tableId} />
      <div className={classes.eventSourcingLink}>
        <Link
          href={`${
            window.location.origin.includes('test-client')
              ? window.location.origin.replace('test-client', 'event-sourcing')
              : 'http://localhost:31415'
          }/?category=table&stream=${tableId}`}
          text={'Event Sourcing'}
        />
      </div>
      <TableStateProvider tableId={tableId} clubId={clubId}>
        <TableState tableId={tableId} clubId={clubId} />
      </TableStateProvider>
    </ClickerProvider>
  );
};

const boardClasses = [classes.board0, classes.board1];

export type Execute = ReturnType<typeof useTablePrx>['execute'];

const TableState: React.SFC<{
  tableId: string;
  clubId: string | undefined;
}> = ({tableId, clubId}) => {
  useClicker();
  const tableResponse = useTableState();
  const [table] = (getResponseData(tableResponse) ?? []) as Array<
    Gazebo.Tables.Poker.TableState | undefined
  >;

  const tableError = getResponseError(tableResponse);
  const {execute} = useTablePrx(tableId);

  const {
    heroSeatIndex,
    seats: {length: tableSize},
    commandVariants,
    availableReactions,
  } = table ?? {seats: {}};

  const userIds = React.useMemo(
    () =>
      table?.seats
        .filter(
          (seat): seat is Gazebo.Tables.Poker.OccupiedSeat =>
            seat instanceof Gazebo.Tables.Poker.OccupiedSeat,
        )
        .map((seat) => seat.playerId),
    [table?.seats],
  );
  const {subscribeToUserRelations} = useUserRelationsPrx();
  const subsccribeToRelations = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.UserRelations.AbstractRelation>,
    ) => subscribeToUserRelations(subscriber, userIds ?? []),
    [subscribeToUserRelations, userIds],
  );

  const relationsState = useSubscription(subsccribeToRelations);

  const relationsResponse = React.useMemo(
    () =>
      (getResponseData(relationsState) ?? []) as Array<
        Gazebo.UserRelations.AbstractRelation | undefined
      >,
    [relationsState],
  );

  const canReserveSeat = React.useMemo(
    () =>
      (commandVariants ?? []).some(
        (variant) =>
          variant instanceof Gazebo.Tables.Poker.CommandVariant.Reserve,
      ),
    [commandVariants],
  );

  const winner = table?.seats.find(
    (seat): seat is Gazebo.Tables.Poker.OccupiedSeat =>
      seat instanceof Gazebo.Tables.Poker.OccupiedSeat &&
      seat?.winner != undefined,
  );

  const isBombPotHand = React.useMemo(() => {
    return table?.hand?.events.some(
      (event) => event instanceof Gazebo.Tables.Poker.BombPotHand,
    );
  }, [table?.hand?.events]);

  const [bombPotMessage, setBombPotMessage] = React.useState<
    {type: 'error'; error: string} | undefined
  >(undefined);

  React.useEffect(() => {
    if (isBombPotHand) {
      setBombPotMessage({type: 'error', error: 'Bomb pot hand started!'});
    } else {
      setBombPotMessage(undefined);
    }
  }, [isBombPotHand]);

  const seatsOrder = React.useMemo(() => {
    if (tableSize == null) {
      return [];
    }
    const range = new Array(tableSize).fill(1).map((_, index) => index);
    if (heroSeatIndex == null) {
      return range;
    }
    return [...range.slice(heroSeatIndex), ...range.slice(0, heroSeatIndex)];
  }, [heroSeatIndex, tableSize]);

  const seatsLayout = React.useMemo(
    () =>
      tableSize != null ? getSeatsLayout(heroSeatIndex != null, tableSize) : [],
    [heroSeatIndex, tableSize],
  );
  if (tableError != null) {
    return <div>Error</div>;
  }
  if (table == null) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.tableHeader}>
        <TableHeader table={table} />
        <TableInfo table={table} />
      </div>
      <div className={classes.gameWrapper}>
        <div className={classes.game}>
          <div className={classes.gameTable}>
            <div className={classes.potsWithBets}>
              <div className={classes.pots}>
                {table.hand?.pots?.map((pot) => {
                  return (
                    <div key={pot.toNumber()} className={classes.money}>
                      <div className={classes.moneyStackAmount}>
                        {centsToMainUnits(pot.toNumber())}
                      </div>
                      <div className={classes.moneyStack}>
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={classes.potsWithBetsAmount}>
                Pots with bets:{' '}
                {centsToMainUnits(table.hand?.potsWithBets.toNumber())}
              </div>
              <div>
                Winner combination: {winner?.winner?.combinationName ?? ''}
              </div>
            </div>
            <BoardCards seats={table.seats} boards={table.hand?.boards} />
            <DealerButton
              seatIndex={table.hand?.dealerButtonSeatIndex}
              seatsLayout={seatsLayout}
              seatsOrder={seatsOrder}
            />
            <Bets
              seatsOrder={seatsOrder}
              seatsLayout={seatsLayout}
              seats={table.seats}
            />
          </div>
          <Seats
            heroSeatIndex={heroSeatIndex}
            seatsOrder={seatsOrder}
            seatsLayout={seatsLayout}
            hand={table.hand}
            seats={table.seats}
            execute={execute}
            canReserveSeat={canReserveSeat}
            clubId={clubId}
            availableReactions={availableReactions}
            relations={relationsResponse}
          />
        </div>
      </div>
      <CommandVariants table={table} execute={execute} />
      <ResponseErrorToast response={bombPotMessage} />
    </div>
  );
};

const ClickerSettingsDialog = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const handleClose = React.useCallback(() => setIsOpen(false), [setIsOpen]);
  return (
    <>
      <Button className={classes.clickerSettingsButton} onClick={handleOpen}>
        Clicker settings
      </Button>
      <Dialog isOpen={isOpen} title="Clicker settings" onClose={handleClose}>
        <div className={classes.clickerSettingsWrapper}>
          <ClickerSettings />
        </div>
      </Dialog>
    </>
  );
};

const BoardCards: React.SFC<{
  boards?: Gazebo.Tables.Poker.BoardSeq;
  seats: Gazebo.Tables.Poker.SeatSeq;
}> = ({boards, seats}) => {
  const combinations = React.useMemo(() => {
    return seats.map((seat): [number, Gazebo.Tables.Poker.CardSeq | null] => {
      if (seat instanceof Gazebo.Tables.Poker.OccupiedSeat) {
        return [seat.seatIndex, seat.winner?.combination ?? null];
      }
      return [seat.seatIndex, null];
    });
  }, [seats]);
  return (
    <>
      {boards?.map((board, i) => {
        return (
          <div className={boardClasses[i]} key={'board' + i}>
            {board?.map((card) => {
              // почему-то data-is-in-combination-0 рендерится не всегда, если пихать это инлайн спредом
              const isInCombinationFlags = combinations.reduce(
                (props: any, [seatIndex, combination]) => {
                  return {
                    ...props,
                    [`data-is-in-combination-${seatIndex}`]: combination?.some(
                      (item) => item.equals(card),
                    )
                      ? seatIndex
                      : undefined,
                  };
                },
                {} as any,
              );
              return (
                <div
                  key={card.name}
                  className={classnames(card.name, classes.openedCard)}
                  {...isInCombinationFlags}
                />
              );
            })}
          </div>
        );
      }) ?? null}
    </>
  );
};

const TableHeader: React.SFC<{table: Gazebo.Tables.Poker.TableState}> = ({
  table,
}) => {
  const [time, setTime] = React.useState<string>();
  const {
    updatedAtMs,
    game: {isStarted, startedAtMs},
    messages = [],
  } = table;
  React.useEffect(() => {
    const updatedAtInterval = setInterval(() => {
      const startedAt = isStarted
        ? `started at ${moment(startedAtMs?.toNumber()).fromNow()}`
        : 'no started';
      const updatedAt = `updated at ${moment(
        updatedAtMs.toNumber(),
      ).fromNow()}`;
      setTime([startedAt, updatedAt].join(', '));
    }, 1000);
    return () => {
      clearInterval(updatedAtInterval);
    };
  }, [table, setTime, isStarted, updatedAtMs, startedAtMs]);

  const rawHandId = table.hand?.id;
  const handId = React.useMemo(
    () => (rawHandId != null ? `Hand #${rawHandId}` : ''),
    [rawHandId],
  );

  const messagesText = messages.map(({text}) => text).join('\n');

  return (
    <div>
      <div>
        <span>{table.name}</span> <span>#{table.id}</span>
      </div>
      <div className={Classes.TEXT_MUTED}>{time}</div>
      {!!messagesText && <div>{messagesText}</div>}
      {!!handId && <div>{handId}</div>}
    </div>
  );
};

const TableInfo: React.SFC<{table: Gazebo.Tables.Poker.TableState}> = ({
  table,
}) => {
  return (
    <div className={classes.tableInfo}>
      <div>{`bb: ${centsToMainUnits(table.bigBlindAmount?.toNumber())}`}</div>
      <div>{`ante: ${centsToMainUnits(table.anteAmount?.toNumber())}`}</div>
      <div>{`sb: ${centsToMainUnits(table.smallBlindAmount?.toNumber())}`}</div>
      <div>{`straddle: ${centsToMainUnits(
        table.straddleAmount?.toNumber(),
      )}`}</div>
      <div />
      <div>{`BP period: ${
        table.game.gameOptions?.bombPot?.period ?? 0
      }, ante size: ${table.game.gameOptions?.bombPot?.bombAnteSizeInBB}`}</div>
    </div>
  );
};

const Bets: React.SFC<{
  seats: Gazebo.Tables.Poker.SeatSeq;
  seatsLayout: PlaceStyle[];
  seatsOrder: number[];
}> = ({seats, seatsLayout, seatsOrder}) => {
  return (
    <>
      {seatsOrder.map((index, viewPosition) => {
        const seat = seats[index];
        if (seat instanceof Gazebo.Tables.Poker.OccupiedSeat) {
          return (
            <Bet
              key={index}
              seatLayout={seatsLayout[viewPosition]}
              seat={seat}
            />
          );
        }
        return null;
      })}
    </>
  );
};

const DealerButton: React.SFC<{
  seatsLayout: PlaceStyle[];
  seatIndex?: number;
  seatsOrder: number[];
}> = ({seatsLayout, seatsOrder, seatIndex}) => {
  const style = React.useMemo(() => {
    if (seatIndex != null) {
      const index = seatsOrder.findIndex((index) => index === seatIndex);
      if (index !== -1) {
        const {side, style} = seatsLayout[index];
        switch (side) {
          case 'right':
            return {...style, transform: 'translateY(-150%) translateX(-20px)'};
          case 'left':
            return {...style, transform: 'translateY(150%)'};
          case 'bottom':
            return {...style, transform: 'translateX(150%) translateY(-20px)'};
          case 'top':
            return {...style, transform: 'translateX(-150%)'};
        }
      }
    }
  }, [seatIndex, seatsLayout, seatsOrder]);
  if (style == null) {
    return null;
  }
  return (
    <div style={style} className={classes.dealerButtonWrapper}>
      <div />
      <div>D</div>
    </div>
  );
};

const Bet: React.SFC<{
  seatLayout: PlaceStyle;
  seat: Gazebo.Tables.Poker.OccupiedSeat;
}> = ({seatLayout, seat}) => {
  const amount = useIceLong(seat.betAmount, Number.NaN);
  const amountStr = centsToMainUnits(amount);
  const isSb = seat.isPostedSb;
  const isBb = seat.isPostedBb;
  const style = React.useMemo(() => {
    switch (seatLayout.side) {
      case 'top':
        return {
          ...seatLayout.style,
          transform: 'translateX(-50%) translateX(25px) translateY(10px)',
        };
      case 'bottom':
        return {
          ...seatLayout.style,
          transform: 'translateY(-100%)',
        };
      case 'right':
        return {
          ...seatLayout.style,
          transform: 'translateX(-100%) translateY(15px)',
        };
      case 'left':
        return {...seatLayout.style, transform: 'translateY(-10px)'};
    }
  }, [seatLayout]);

  if (Number.isNaN(amount)) {
    return null;
  }

  return (
    <div style={style} className={classes.bet}>
      <div className={classes.money}>
        <div className={classes.moneyStackAmount}>{amountStr}</div>
        <div className={classes.moneyStack}>
          <div />
          <div />
          <div />
          <div>{isSb ? 'S' : isBb ? 'B' : null}</div>
        </div>
      </div>
    </div>
  );
};

const CommandVariants: React.SFC<{
  table: Gazebo.Tables.Poker.TableState;
  execute: Execute;
}> = ({table, execute}) => {
  const commandVariants = table.commandVariants;
  // Всё целиком засунуто в useMemo, потому что так было проще сделать на момент
  // написания.
  // Это делается, чтобы в SimpleCommand не передавался каждый раз новый объект
  // command, что приводило бы к бесконечным ререндерам.
  const children = useMemo(
    () =>
      commandVariants.map((variant) => {
        if (variant instanceof Gazebo.Tables.Poker.CommandVariant.MakeDeposit) {
          return (
            <MakeDeposit
              key={variant.ice_id()}
              table={table}
              commandVariant={variant}
              execute={execute}
            />
          );
        }
        if (variant instanceof Gazebo.Tables.Poker.CommandVariant.SitIn) {
          return (
            <SimpleCommand
              key={variant.ice_id()}
              command={create(Gazebo.Tables.Poker.Command.SitIn, {})}
              text="Sit in"
              execute={execute}
            />
          );
        }
        if (variant instanceof Gazebo.Tables.Poker.CommandVariant.SitOut) {
          return (
            <SimpleCommand
              key={variant.ice_id()}
              command={create(Gazebo.Tables.Poker.Command.SitOut, {})}
              text="Sit out"
              execute={execute}
            />
          );
        }
        if (variant instanceof Gazebo.Tables.Poker.CommandVariant.PostBlind) {
          const text = `Post ${variant.stageName} ${centsToMainUnits(
            variant.amount.toNumber(),
          )}`;
          return (
            <SimpleCommand
              key={variant.ice_id()}
              command={create(Gazebo.Tables.Poker.Command.PostBlind, {})}
              text={text}
              execute={execute}
            />
          );
        }
        if (variant instanceof Gazebo.Tables.Poker.CommandVariant.Fold) {
          return (
            <SimpleCommand
              key={variant.ice_id()}
              command={create(Gazebo.Tables.Poker.Command.Fold, {})}
              text="Fold"
              execute={execute}
            />
          );
        }
        if (variant instanceof Gazebo.Tables.Poker.CommandVariant.Check) {
          return (
            <SimpleCommand
              key={variant.ice_id()}
              command={create(Gazebo.Tables.Poker.Command.Check, {})}
              text="Check"
              execute={execute}
            />
          );
        }
        if (variant instanceof Gazebo.Tables.Poker.CommandVariant.Call) {
          return (
            <SimpleCommand
              key={variant.ice_id()}
              command={create(Gazebo.Tables.Poker.Command.Call, {})}
              text={`Call ${centsToMainUnits(variant.amountTo.toNumber())}`}
              execute={execute}
            />
          );
        }
        if (variant instanceof Gazebo.Tables.Poker.CommandVariant.Leave) {
          return (
            <SimpleCommand
              key={variant.ice_id()}
              command={create(Gazebo.Tables.Poker.Command.Leave, {})}
              text="Leave"
              execute={execute}
            />
          );
        }
        if (variant instanceof Gazebo.Tables.Poker.CommandVariant.Bet) {
          const text = `Bet ${centsToMainUnits(
            variant.minAmountTo.toNumber(),
          )}`;
          return (
            <SimpleCommand
              key={variant.ice_id()}
              command={create(Gazebo.Tables.Poker.Command.Bet, {
                isAmountTo: true,
                value: variant.minAmountTo,
              })}
              text={text}
              execute={execute}
            />
          );
        }
        if (
          variant instanceof
          Gazebo.Tables.Poker.CommandVariant.FastRaiseByMultiple
        ) {
          const text =
            variant.multiplicandType instanceof
            Gazebo.Tables.Poker.CommandVariant.PotRaiseMultiplicand
              ? variant.multiplierCaption
                ? variant.multiplierCaption + ' Pot'
                : 'Pot'
              : variant.multiplierCaption + 'X';
          return (
            <SimpleCommand
              key={variant.ice_id() + variant.multiplierCaption}
              command={create(Gazebo.Tables.Poker.Command.FastRaise, {
                isAmountTo: true,
                value: variant.amountTo,
              })}
              text={text}
              execute={execute}
            />
          );
        }
        if (variant instanceof Gazebo.Tables.Poker.CommandVariant.FastAllIn) {
          return (
            <SimpleCommand
              key={variant.ice_id() + 'Allin'}
              command={create(Gazebo.Tables.Poker.Command.Raise, {
                isAmountTo: true,
                value: variant.amountTo,
              })}
              text={`Fast AllIn ${centsToMainUnits(
                variant.amountTo.toNumber(),
              )}`}
              id="Fast AllIn"
              execute={execute}
            />
          );
        }
        if (variant instanceof Gazebo.Tables.Poker.CommandVariant.Raise) {
          const text = `Raise ${centsToMainUnits(
            variant.minAmountTo.toNumber(),
          )}`;
          return (
            <SimpleCommand
              key={variant.ice_id()}
              command={create(Gazebo.Tables.Poker.Command.Raise, {
                isAmountTo: true,
                value: variant.minAmountTo,
              })}
              text={text}
              execute={execute}
            />
          );
        }
        if (variant instanceof Gazebo.Tables.Poker.CommandVariant.AllIn) {
          const text = `AllIn ${centsToMainUnits(variant.amountTo.toNumber())}`;
          return (
            <SimpleCommand
              key={variant.ice_id()}
              command={create(Gazebo.Tables.Poker.Command.AllIn, {})}
              text={text}
              execute={execute}
            />
          );
        }
        if (variant instanceof Gazebo.Tables.Poker.CommandVariant.ShowCards) {
          return (
            <SimpleCommand
              key={variant.ice_id()}
              command={create(Gazebo.Tables.Poker.Command.ShowCards, {})}
              text="Show cards"
              execute={execute}
            />
          );
        }
        if (variant instanceof Gazebo.Tables.Poker.CommandVariant.MuckCards) {
          return (
            <SimpleCommand
              key={variant.ice_id()}
              command={create(Gazebo.Tables.Poker.Command.MuckCards, {})}
              text="Muck cards"
              execute={execute}
            />
          );
        }
        if (variant instanceof Gazebo.Tables.Poker.CommandVariant.CashOut) {
          return <CashOut key={variant.ice_id()} execute={execute} />;
        }
        if (
          variant instanceof Gazebo.Tables.Poker.CommandVariant.DeclineBlind
        ) {
          return (
            <SimpleCommand
              key={variant.ice_id()}
              command={create(Gazebo.Tables.Poker.Command.DeclineBlind, {})}
              text="Decline blind"
              execute={execute}
            />
          );
        }
        if (
          variant instanceof Gazebo.Tables.Poker.CommandVariant.SetCheckFold
        ) {
          return (
            <SimpleCommand
              key={`${variant.ice_id()}${variant.enabled}`}
              command={create(Gazebo.Tables.Poker.Command.SetCheckFold, {
                enabled: variant.enabled,
              })}
              text={variant.enabled ? 'Set Check/Fold' : 'Unset Check/Fold'}
              execute={execute}
            />
          );
        }
        if (
          variant instanceof Gazebo.Tables.Poker.CommandVariant.MarkCardToShow
        ) {
          return (
            <SimpleCommand
              key={`${variant.ice_id()}${variant.card}${variant.isMarked}`}
              command={create(Gazebo.Tables.Poker.Command.MarkCardToShow, {
                card: variant.card,
                isMarked: variant.isMarked,
              })}
              text={`Mark ${variant.card} for ${
                variant.isMarked ? 'show' : 'hide'
              } `}
              execute={execute}
            />
          );
        }
        if (
          variant instanceof
          Gazebo.Tables.Poker.CommandVariant.MarkAllCardsToShow
        ) {
          return (
            <SimpleCommand
              key={`${variant.ice_id()}${variant.isMarked}`}
              command={create(Gazebo.Tables.Poker.Command.MarkAllCardsToShow, {
                isMarked: variant.isMarked,
              })}
              text={variant.isMarked ? 'Show all' : "Don't show all"}
              execute={execute}
            />
          );
        }
        return null;
      }),
    [table, commandVariants, execute],
  );
  return <div className={classes.commands}>{children}</div>;
};

const SimpleCommand: React.FC<{
  command: Gazebo.Tables.Poker.Command.AbstractCommand;
  text: string;
  execute: Execute;
  id?: string;
}> = ({command, text, execute, id}) => {
  const [ctx] = useCancelContext();
  const key = useIdempotenceKey([]);
  const [response, doCommand] = useRequest(
    () => execute(key, command),
    [execute, key, command],
    ctx,
  );
  useClick(
    doCommand,
    id ?? command.ice_id().split('::').pop(),
    getCommandProbability(command),
  );
  return (
    <div>
      <Button onClick={doCommand}>{text}</Button>
      <ResponseErrorToast response={response} />
    </div>
  );
};

const getCommandProbability = (
  command: Gazebo.Tables.Poker.Command.AbstractCommand,
) => {
  if (
    command instanceof Gazebo.Tables.Poker.Command.Leave ||
    command instanceof Gazebo.Tables.Poker.Command.CashOut ||
    command instanceof Gazebo.Tables.Poker.Command.SitOut
  ) {
    return 0.0115;
  } else if (
    command instanceof Gazebo.Tables.Poker.Command.Fold ||
    command instanceof Gazebo.Tables.Poker.Command.MuckCards ||
    command instanceof Gazebo.Tables.Poker.Command.DeclineBlind
  ) {
    return 0.122;
  } else if (
    command instanceof Gazebo.Tables.Poker.Command.Check ||
    command instanceof Gazebo.Tables.Poker.Command.Call
  ) {
    return 0.25;
  } else if (
    command instanceof Gazebo.Tables.Poker.Command.AllIn ||
    command instanceof Gazebo.Tables.Poker.Command.Bet ||
    command instanceof Gazebo.Tables.Poker.Command.Raise ||
    command instanceof Gazebo.Tables.Poker.Command.FastRaise
  ) {
    return 1;
  } else if (
    command instanceof Gazebo.Tables.Poker.Command.Reserve ||
    command instanceof Gazebo.Tables.Poker.Command.MakeDeposit ||
    command instanceof Gazebo.Tables.Poker.Command.PostBlind ||
    command instanceof Gazebo.Tables.Poker.Command.ShowCards
  ) {
    return 1;
  } else if (command instanceof Gazebo.Tables.Poker.Command.SitIn) {
    return 0.1;
  }

  return 1;
};

const CashOut: React.SFC<{execute: Execute}> = ({execute}) => {
  const transactionId = useIdempotenceKey([]);
  return (
    <SimpleCommand
      command={create(Gazebo.Tables.Poker.Command.CashOut, {transactionId})}
      text="Cash out"
      execute={execute}
    />
  );
};

const MakeDeposit: React.SFC<{
  table: Gazebo.Tables.Poker.TableState;
  commandVariant: Gazebo.Tables.Poker.CommandVariant.MakeDeposit;
  execute: Execute;
}> = ({table, commandVariant, execute}) => {
  const [isOpened, setIsOpened] = React.useState<boolean>(false);

  const isRequired = !commandVariant.type.equals(
    Gazebo.Tables.Poker.CommandVariant.DepositType.Deposit,
  );

  React.useEffect(() => {
    if (isRequired) {
      setIsOpened(true);
    }
  }, [isRequired, setIsOpened]);

  const currentStack = useIceLong(commandVariant.currentStack);
  const minAmountTo = useIceLong(commandVariant.minAmountTo);
  const maxAmountTo = useIceLong(commandVariant.maxAmountTo);
  const validateAmount = React.useCallback(
    (value: number) => {
      if (minAmountTo > value || value > maxAmountTo) {
        return `Amount must be between ${centsToMainUnits(
          minAmountTo,
        )} and ${centsToMainUnits(maxAmountTo)}`;
      }
    },
    [minAmountTo, maxAmountTo],
  );

  const [onChangeAmount, amount] = useInputState(
    centsToMainUnits(currentStack),
    parseCents,
    validateAmount,
  );

  const [ctx] = useCancelContext();
  const key = useIdempotenceKey([amount.value]);
  const [depositResponse, doDeposit] = useRequest(
    () => {
      if (amount.error != null) {
        throw new Error('Input error');
      }
      return execute(
        key,
        create(Gazebo.Tables.Poker.Command.MakeDeposit, {
          amount: new Ice.Long(amount.value - currentStack),
          transactionId: key, // т.к. ключ привязан к сумме закупки
        }),
      );
    },
    [key, amount, execute, currentStack],
    ctx,
  );

  const onAir = depositResponse?.type === 'started';

  useClick(
    React.useCallback(() => {
      if (isOpened) {
        return execute(
          key,
          create(Gazebo.Tables.Poker.Command.MakeDeposit, {
            amount: new Ice.Long(
              minAmountTo + Math.floor((maxAmountTo - minAmountTo) / 2),
            ),
            transactionId: key, // т.к. ключ привязан к сумме закупки
          }),
        );
      }
    }, [isOpened, minAmountTo, maxAmountTo, key, execute]),
    Gazebo.Tables.Poker.Command.MakeDeposit.ice_staticId().split('::').pop(),
  );

  const handleOpen = React.useCallback(() => {
    setIsOpened(true);
  }, [setIsOpened]);
  const handleClosed = React.useCallback(() => {
    if (isRequired) {
      execute(key, create(Gazebo.Tables.Poker.Command.RejectDeposit, {}));
    }
    if (!onAir) {
      setIsOpened(false);
    }
  }, [setIsOpened, onAir, isRequired, execute, key]);
  if (table.notEnoughChipsMessage) {
    return (
      <div>
        <DefaultErrorToast message={table.notEnoughChipsMessage} />
      </div>
    );
  }
  return (
    <div>
      <Button onClick={handleOpen}>Make deposit</Button>
      <Dialog
        isOpen={isOpened}
        className={classes.makeDepositDialog}
        onClose={handleClosed}
        title={commandVariant.type.name}
      >
        <Form className={classes.makeDepositForm}>
          <FormGroup
            className={classes.makeDepositFormGroup}
            inline={true}
            labelFor="text-input"
            label="Amount"
            helperText={amount.error}
          >
            <InputGroup
              autoFocus={true}
              value={amount.rawValue}
              id="text-input"
              onChange={onChangeAmount}
            />
          </FormGroup>
          <Button
            loading={onAir}
            disabled={amount.error != null}
            className={classes.makeDepositSubmit}
            type="submit"
            intent={Intent.PRIMARY}
            onClick={doDeposit}
          >
            Deposit
          </Button>
        </Form>
      </Dialog>
      <ResponseErrorToast response={depositResponse} />
    </div>
  );
};

const getSeatsLayout = (hasHero: boolean, tableSize: number): PlaceStyle[] => {
  if (hasHero) {
    switch (tableSize) {
      case 2:
        return [
          createPlaceStyle(1, 1, 'bottom'),
          createPlaceStyle(1, 1, 'top'),
        ];
      case 3:
        return [
          createPlaceStyle(1, 1, 'bottom'),
          createPlaceStyle(1, 1, 'left'),
          createPlaceStyle(1, 1, 'right'),
        ];
      case 4:
        return [
          createPlaceStyle(1, 1, 'bottom'),
          createPlaceStyle(1, 1, 'left'),
          createPlaceStyle(1, 1, 'top'),
          createPlaceStyle(1, 1, 'right'),
        ];
      case 5:
        return [
          createPlaceStyle(1, 1, 'bottom'),
          ...createPlacesStyle(2, 'left'),
          ...createPlacesStyle(2, 'right'),
        ];
      case 6:
        return [
          createPlaceStyle(1, 1, 'bottom'),
          ...createPlacesStyle(2, 'left'),
          createPlaceStyle(1, 1, 'top'),
          ...createPlacesStyle(2, 'right'),
        ];
      case 7:
        return [
          createPlaceStyle(1, 1, 'bottom'),
          ...createPlacesStyle(2, 'left'),
          ...createPlacesStyle(2, 'top'),
          ...createPlacesStyle(2, 'right'),
        ];
      case 8:
        return [
          createPlaceStyle(1, 1, 'bottom'),
          ...createPlacesStyle(3, 'left'),
          createPlaceStyle(1, 1, 'top'),
          ...createPlacesStyle(3, 'right'),
        ];
      case 9:
        return [
          createPlaceStyle(1, 1, 'bottom'),
          ...createPlacesStyle(3, 'left'),
          ...createPlacesStyle(2, 'top'),
          ...createPlacesStyle(3, 'right'),
        ];
    }
  } else {
    switch (tableSize) {
      case 2:
        return [
          createPlaceStyle(1, 1, 'bottom'),
          createPlaceStyle(1, 1, 'top'),
        ];
      case 3:
        return [
          createPlaceStyle(1, 1, 'left'),
          createPlaceStyle(1, 1, 'top'),
          createPlaceStyle(1, 1, 'right'),
        ];
      case 4:
        return [
          createPlaceStyle(1, 1, 'bottom'),
          createPlaceStyle(1, 1, 'left'),
          createPlaceStyle(1, 1, 'top'),
          createPlaceStyle(1, 1, 'right'),
        ];
      case 5:
        return [
          ...createPlacesStyle(2, 'left'),
          createPlaceStyle(1, 1, 'top'),
          ...createPlacesStyle(2, 'right'),
        ];
      case 6:
        return [
          createPlaceStyle(1, 1, 'bottom'),
          ...createPlacesStyle(2, 'left'),
          createPlaceStyle(1, 1, 'top'),
          ...createPlacesStyle(2, 'right'),
        ];
      case 7:
        return [
          createPlaceStyle(1, 1, 'bottom'),
          ...createPlacesStyle(2, 'left'),
          ...createPlacesStyle(2, 'top'),
          ...createPlacesStyle(2, 'right'),
        ];
      case 8:
        return [
          ...createPlacesStyle(2, 'bottom'),
          ...createPlacesStyle(2, 'left'),
          ...createPlacesStyle(2, 'top'),
          ...createPlacesStyle(2, 'right'),
        ];
      case 9:
        return [
          ...createPlacesStyle(1, 'bottom'),
          ...createPlacesStyle(3, 'left'),
          ...createPlacesStyle(2, 'top'),
          ...createPlacesStyle(3, 'right'),
        ];
    }
  }
  throw new Error();
};

export type Side = 'left' | 'right' | 'top' | 'bottom';
export type PlaceStyle = {side: Side; style: React.CSSProperties};

const createPlaceStyle = (
  placesCount: number,
  place: number,
  side: Side,
): PlaceStyle => {
  let transform;
  let style: React.CSSProperties;
  switch (side) {
    case 'bottom':
      if (placesCount === 2) {
        transform = 'translateX(-50%) translateY(-3%)';
      } else {
        transform = 'translateX(-50%) translateY(5%)';
      }
      style = {
        top: '100%',
        left: `${(place * 100) / (placesCount + 1)}%`,
        transform,
      };
      break;
    case 'left':
      transform = 'translateY(-50%) translateX(-100%)';
      if (placesCount === 2 && place === 1) {
        transform = 'translateY(-50%) translateX(-90%)';
      } else if (placesCount === 3) {
        if (place === 1) {
          transform = 'translateY(-50%) translateX(-86%)';
        } else if (place === 2) {
          transform = 'translateY(-50%) translateX(-94%)';
        }
      }
      style = {
        left: 0,
        top: `${(place * 100) / (placesCount + 1)}%`,
        transform,
      };
      break;
    case 'top':
      if (placesCount === 2) {
        transform = 'translateX(-50%) translateY(-94%)';
      } else {
        transform = 'translateX(-50%) translateY(-100%)';
      }
      style = {
        top: 0,
        left: `${(place * 100) / (placesCount + 1)}%`,
        transform,
      };
      break;
    case 'right':
      transform = 'translateY(-50%)';
      if (placesCount === 2 && place === 1) {
        transform = 'translateY(-50%) translateX(-10%)';
      } else if (placesCount === 3) {
        if (place === 1) {
          transform = 'translateY(-50%) translateX(-14%)';
        } else if (place === 2) {
          transform = 'translateY(-50%) translateX(-6%)';
        }
      }
      style = {
        left: '100%',
        top: `${(place * 100) / (placesCount + 1)}%`,
        transform,
      };
      break;
  }
  return {
    side,
    style,
  };
};

const createPlacesStyle = (
  placesCount: number,
  side: 'left' | 'right' | 'top' | 'bottom',
): PlaceStyle[] => {
  const styles = new Array(placesCount)
    .fill(1)
    .map((_, index) => createPlaceStyle(placesCount, index + 1, side));
  if (side === 'left') {
    return styles.reverse();
  }
  return styles;
};
