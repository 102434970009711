import {Classes, Code} from '@blueprintjs/core';
import * as React from 'react';

import {TournamentStateProvider} from '../../../store/tournament';
import {getClasses} from '../../../utils/css';
import {Link} from '../../../utils/Link';

import {CommandRequirements} from './CommandRequirements';
import {TournamentStates} from './TournamentStates';

const classes = getClasses({
  root: {
    paddingTop: '20px',
    display: 'grid',
    maxHeight: '100%',
    gridGap: 25,
    overflow: 'hidden',
  },
  tournamentCard: {
    display: 'grid',
    gridGap: 5,
    maxWidth: 230,
    $nest: {
      [`& .${Classes.ICON}`]: {
        marginRight: 10,
      },
    },
  },
  cardHeader: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: 5,
  },
  eventSourcingLink: {},
});

export const Tournament: React.SFC<{
  tournamentId: string;
  clubId?: string;
}> = ({tournamentId, clubId}) => {
  return (
    <div>
      <div className={classes.eventSourcingLink}>
        <Link
          href={`${
            window.location.origin.includes('test-client')
              ? window.location.origin.replace('test-client', 'event-sourcing')
              : 'http://localhost:31415'
          }/?category=tournament&stream=${tournamentId}`}
          text={'Event Sourcing'}
        />
      </div>
      <TournamentStateProvider tournamentId={tournamentId} clubId={clubId}>
        <div className={classes.root}>
          <TournamentStates />
          <CommandRequirements tournamentId={tournamentId} clubId={clubId} />
        </div>
      </TournamentStateProvider>
    </div>
  );
};
