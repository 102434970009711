import {Intent, Toast, Toaster} from '@blueprintjs/core';
import * as React from 'react';

import {getResponseError, Response} from '../ice-client-react';

export const ResponseErrorToast: React.SFC<{response?: Response<any>}> = ({
  response,
}) => {
  const error = getResponseError(response);
  const [dismiss, setState] = React.useState<boolean>(false);
  const onDismiss = React.useCallback(() => {
    setState(true);
  }, [setState]);
  React.useEffect(() => () => setState(false), [response, setState]);
  if (error == null || dismiss) {
    return null;
  }
  return (
    <Toaster>
      <Toast
        timeout={20000}
        intent={Intent.WARNING}
        message={error.toString().replace('::Gazebo::', '')}
        icon="warning-sign"
        onDismiss={onDismiss}
      />
    </Toaster>
  );
};

export const DefaultErrorToast: React.SFC<{message: string}> = ({message}) => {
  const [dismiss, setState] = React.useState<boolean>(false);
  const onDismiss = React.useCallback(() => {
    setState(true);
  }, [setState]);
  if (dismiss) {
    return null;
  }
  return (
    <Toaster>
      <Toast
        timeout={20000}
        intent={Intent.WARNING}
        message={message}
        icon="warning-sign"
        onDismiss={onDismiss}
      />
    </Toaster>
  );
};
