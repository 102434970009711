import {Ice} from 'ice';
import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';

export const useIceLong = (number?: Ice.Long, defaultValue = 0) =>
  React.useMemo(
    () => number?.toNumber() ?? defaultValue,
    [number, defaultValue],
  );

export const getReactionText = (
  reaction: Gazebo.Tables.Poker.AbstractReaction,
) => {
  if (reaction instanceof Gazebo.Tables.Poker.AreYouCrazy) {
    return 'AreYouCrazy';
  }
  if (reaction instanceof Gazebo.Tables.Poker.Difficult) {
    return 'Difficult';
  }
  if (reaction instanceof Gazebo.Tables.Poker.WhatAreYouDoing) {
    return 'WhatAreYouDoing';
  }
  if (reaction instanceof Gazebo.Tables.Poker.Rage) {
    return 'Rage';
  }
  if (reaction instanceof Gazebo.Tables.Poker.Facepalm) {
    return 'Facepalm';
  }
  if (reaction instanceof Gazebo.Tables.Poker.Applause) {
    return 'Applause';
  }
  if (reaction instanceof Gazebo.Tables.Poker.ComeOnTry) {
    return 'ComeOnTry';
  }
  if (reaction instanceof Gazebo.Tables.Poker.Pray) {
    return 'Pray';
  }
  if (reaction instanceof Gazebo.Tables.Poker.PleaseCall) {
    return 'PleaseCall';
  }
  if (reaction instanceof Gazebo.Tables.Poker.CryingSadly) {
    return 'CryingSadly';
  }
  if (reaction instanceof Gazebo.Tables.Poker.Irritated) {
    return 'Irritated';
  }
  if (reaction instanceof Gazebo.Tables.Poker.MoneyMoney) {
    return 'MoneyMoney';
  }
  if (reaction instanceof Gazebo.Tables.Poker.YesIAmGood) {
    return 'YesIAmGood';
  }
  if (reaction instanceof Gazebo.Tables.Poker.PhewLuckyMe) {
    return 'PhewLuckyMe';
  }
  if (reaction instanceof Gazebo.Tables.Poker.Icily) {
    return 'Icily';
  }
  if (reaction instanceof Gazebo.Tables.Poker.Suspicious) {
    return 'Suspicious';
  }
  if (reaction instanceof Gazebo.Tables.Poker.ThumbsUp) {
    return 'ThumbsUp';
  }
  if (reaction instanceof Gazebo.Tables.Poker.Joy) {
    return 'Joy';
  }
  if (reaction instanceof Gazebo.Tables.Poker.ThankYouGod) {
    return 'ThankYouGod';
  }
  return 'Unknown';
};
