import {
  CancelError,
  catchCancelError,
  createCancelContext,
  delay,
  execute,
} from '@neolab/cancel-context';
import {Auth0Callback, Auth0DecodedHash, WebAuth} from 'auth0-js';
import {History} from 'history';
import * as React from 'react';

export type AuthParams = {
  /**
   * Auth0 domain
   * Example YOUR_TENANT.auth0.com
   */
  domain: string;
  /**
   * Auth0 clientID
   */
  clientID: string;
  /**
   * React app path prefix
   */
  basePath: string;
  /**
   * User will redirect to the url after logged out
   */
  afterLogoutRedirectURI: string;
  audience: string;
  history: History;
};

export type AuthResult = Auth0DecodedHash & {
  userMetadata: {
    displayName: string;
    regDate: string;
  };
  expiresAt: number;
  logout(): void;
};

const AuthContext = React.createContext<AuthResult>(null as any);

export const useAuthContext = () => {
  return React.useContext(AuthContext);
};

export const AuthProvider: React.SFC<AuthParams> = ({
  children,
  domain,
  clientID,
  basePath,
  afterLogoutRedirectURI,
  audience,
  history,
}) => {
  const [authResult, setAuthResult] = React.useState<AuthResult>();
  React.useEffect(() => {
    const [ctx, cancel] = createCancelContext();

    const webAuth = new WebAuth({
      domain,
      clientID,
      responseType: 'token id_token',
      scope: 'profile openid',
      audience,
    });

    const promisify = <P, R, E>(
      self: any,
      method: (params: P, cb: Auth0Callback<R, E>) => void,
      params: P,
    ): Promise<R> => {
      return execute(ctx, (resolve, reject) => {
        method.call(self, params, (err: E | null, result: R) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        });
      });
    };

    let currentSession = localStorage.read();

    const isAuthorize = (session: AuthResult | null): session is AuthResult =>
      session != null && Date.now() < session.expiresAt;

    const logout = () => {
      cancel();
      localStorage.clear();
      webAuth.logout({returnTo: afterLogoutRedirectURI});
    };

    const authorize = async (authResult: Auth0DecodedHash | null) => {
      if (authResult?.accessToken == null || authResult?.expiresIn == null) {
        throw new Error('Failed to parse auth result');
      }
      const expiresAt = authResult.expiresIn * 1000 + Date.now();
      const rawUserMetadata: any = authResult.idTokenPayload;
      const userMetadata: any = {};
      for (const key of Object.keys(rawUserMetadata)) {
        if (key.startsWith('https://pokerplatform.io/')) {
          userMetadata[key.replace('https://pokerplatform.io/', '')] =
            rawUserMetadata[key];
        }
      }
      currentSession = {...authResult, expiresAt, userMetadata, logout};
      localStorage.write(currentSession);
      setAuthResult(currentSession);
    };

    const renewToken = async () => {
      try {
        await authorize(
          await promisify(webAuth, webAuth.checkSession, authParams(basePath)),
        );
      } catch (err) {
        if (err instanceof CancelError) {
          return;
        }
        // TODO: process error ?
        logout();
      }
    };

    (async () => {
      if (isAuthorize(currentSession)) {
        setAuthResult({...currentSession, logout});
        await renewToken();
      } else if (window.location.pathname === `${basePath}auth`) {
        try {
          await authorize(
            await promisify(webAuth, webAuth.parseHash, {
              hash: window.location.hash,
            }),
          );
          const referrerPath = new URL(
            window.location.toString(),
          ).searchParams.get('referrer-path');

          if (
            referrerPath == null ||
            referrerPath === window.location.pathname
          ) {
            window.location.href = window.location.origin;
          }

          if (referrerPath) {
            history.replace(referrerPath.slice(1));
          }
        } catch (err) {
          // TODO: process error
          // redirect to login page;
          return webAuth.authorize(authParams(basePath));
        }
      } else {
        return webAuth.authorize(authParams(basePath));
      }

      while (currentSession) {
        const timeout = Math.max(
          0,
          Math.round((currentSession.expiresAt - Date.now()) / 2),
        );
        await delay(ctx, timeout);
        await renewToken();
      }
    })().catch(catchCancelError);
  }, [
    setAuthResult,
    domain,
    clientID,
    basePath,
    afterLogoutRedirectURI,
    audience,
    history,
  ]);

  if (authResult == null) {
    return <>Unauthorized</>;
  }
  return (
    <AuthContext.Provider value={authResult}>{children}</AuthContext.Provider>
  );
};

const localStorage = (() => {
  const key = '$$currentAuthSession';
  const {localStorage} = window;
  return {
    read: (): AuthResult | null =>
      JSON.parse(localStorage.getItem(key) || 'null'),
    write: (data: AuthResult) =>
      localStorage.setItem(key, JSON.stringify(data)),
    clear: () => localStorage.removeItem(key),
  };
})();

const authParams = (basePath: string) => {
  const referrerPath = window.location.pathname.replace(basePath, '/');
  const redirectUrlParams =
    referrerPath.length > 1
      ? `referrer-path=${encodeURIComponent(referrerPath)}`
      : '';
  return {
    redirectUri: `${window.location.origin}${basePath}auth?${redirectUrlParams}&referrerHost=${window.location.host}`,
  };
};
