import {
  Card,
  Classes,
  Elevation,
  H2,
  H5,
  Icon,
  Intent,
  NonIdealState,
  Tag,
  Text,
} from '@blueprintjs/core';
import moment from 'moment';
import * as React from 'react';
import {useHistory} from 'react-router-dom';

import {Gazebo} from '@slices/Gazebo';

import {useTables} from 'src/store/tables';

import {getResponseData, getResponseError} from '../../ice-client-react';
import {useClub} from '../../store/clubs';
import {useClubTables} from '../../store/clubTables';
import {getClasses} from '../../utils/css';
import {formatRakeCap, formatRakeSize} from '../../utils/numbers';
import {centsToMainUnits} from '../../utils/numbers';
import {sortTables} from '../tableUtils/sortTables';

const tablesClasses = getClasses({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 5,
  },
  tableCard: {
    display: 'grid',
    gridGap: 5,
    maxWidth: 230,
    $nest: {
      [`& .${Classes.ICON}`]: {
        marginRight: 10,
      },
    },
  },
  tableCardHeader: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: 5,
  },
});

const GLOBAL_TABLES_AREA = ['global'];
export const Tables: React.FunctionComponent<{areaId?: string}> = ({
  areaId,
}) => {
  const response = useClub(areaId);
  const club = getResponseData(response);
  const error = getResponseError(response);
  const clubLobby = useClubTables();
  const globalLobby = useTables(GLOBAL_TABLES_AREA);
  const lobby = areaId === 'global' ? globalLobby : clubLobby;
  const rawTables = getResponseData(lobby);

  if (areaId !== undefined && error != null) {
    return <div>Error</div>;
  }
  if (areaId?.startsWith('club') && club == null) {
    return null;
  }
  const tables = (rawTables ?? [])
    .sort((a, b) => sortTables(a.order ?? '', b.order ?? ''))
    .map((table) => (
      <TableCard key={table.tableId} table={table} areaId={areaId} />
    ));
  return (
    <>
      <H2>Tables</H2>
      <div className={tablesClasses.root}>
        {tables.length !== 0 ? tables : <NonIdealState title="No tables" />}
      </div>
    </>
  );
};

const TableCard: React.FunctionComponent<{
  table: Gazebo.Lobby.PokerTable;
  areaId?: string;
}> = ({table, areaId}) => {
  const history = useHistory();
  const {tableId} = table;
  const path = areaId ?? 'global';
  const rake =
    table.rake instanceof Gazebo.Lobby.TotalPotRake ? table.rake : undefined;
  const goToTable = React.useCallback(() => {
    history.push(`/${path}/${tableId}`);
  }, [history, tableId, path]);
  return (
    <Card
      className={tablesClasses.tableCard}
      onClick={goToTable}
      interactive={true}
      elevation={Elevation.TWO}
    >
      <H5 className={tablesClasses.tableCardHeader}>
        {table.name}
        <Text className={Classes.TEXT_MUTED}>{`#${table.tableId}`}</Text>
      </H5>
      <Tag
        minimal={true}
        intent={
          table.quality == null
            ? Intent.NONE
            : table.size === table.playerCount
            ? Intent.NONE
            : table.quality < 5 / 12
            ? Intent.DANGER
            : table.quality < 9 / 12
            ? Intent.WARNING
            : Intent.SUCCESS
        }
      >
        Quality: {table.quality?.toFixed(2) ?? 'N/A'}
      </Tag>
      <TableStatus status={table.status} />
      <span>
        <Icon icon="settings" />{' '}
        {table.gameType +
          (table.gameOptions?.bombPot ? ' B' : '') +
          (table.gameOptions?.doubleBoard ? ' DB' : '')}
      </span>
      <span>
        <Icon icon="user" /> {table.playerCount} / {table.size}
      </span>
      <span>
        <Icon icon="percentage" /> Rake: {formatRakeSize(rake?.size ?? 0)},{' '}
        {formatRakeCap(rake?.cap?.toNumber() ?? 0)}
      </span>
      <span>
        <Icon icon="briefcase" /> Average pot:{' '}
        {table.averagePot
          ? centsToMainUnits(table.averagePot?.toNumber())
          : '-'}
      </span>
      <span>
        <Icon icon="dollar" /> Chips in play:{' '}
        {table.chipsInPlay
          ? centsToMainUnits(table.chipsInPlay.toNumber())
          : '-'}
      </span>
      <span>
        <Icon icon="play" /> Min buy in:{' '}
        {table.minBuyIn ? centsToMainUnits(table.minBuyIn.toNumber()) : '-'}
      </span>
      <span>
        <Icon icon="flame" /> Game activity: {table.gameActivityEmoji ?? '-'}
      </span>
      <span>
        <Icon icon="time" />{' '}
        {`closing ${moment(
          (table.createdAtMs?.toNumber() ?? 0) +
            (table.durationMs?.toNumber() ?? 0),
        ).fromNow()} / created ${moment(
          table.createdAtMs?.toNumber(),
        ).fromNow()}`}
      </span>
    </Card>
  );
};

const TableStatus: React.FunctionComponent<{
  status: Gazebo.Lobby.PokerTable['status'];
}> = ({status}) => {
  if (status instanceof Gazebo.Lobby.TableNotStarted) {
    return (
      <span>
        <Icon intent={Intent.NONE} icon="info-sign" />
        Not started
      </span>
    );
  }
  if (status instanceof Gazebo.Lobby.TableStarted) {
    return (
      <span>
        <Icon intent={Intent.SUCCESS} icon="info-sign" />
        Started
      </span>
    );
  }
  if (status instanceof Gazebo.Lobby.TableFinished) {
    return (
      <span>
        <Icon intent={Intent.PRIMARY} icon="info-sign" />
        Finished
      </span>
    );
  }
  if (status instanceof Gazebo.Lobby.TableBroken) {
    return (
      <span>
        <Icon intent={Intent.DANGER} icon="info-sign" />
        Broken
      </span>
    );
  }
  return null;
};
