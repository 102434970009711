import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';

import {Subscriptions} from '@slices/Subscriptions';

import {create} from '../utils/create';

import {
  getResponseData,
  SubscriptionState,
  useIceProxy,
  useSubscription,
} from '../ice-client-react';

const ClubsContext = React.createContext<SubscriptionState<Gazebo.Clubs.Club>>({
  type: 'started',
});

export const useClubsPrx = () =>
  useIceProxy({
    type: 'static',
    propertyName: 'Webapp.Clubs',
    proxyClass: Gazebo.Clubs.ServicePrx,
  });

export const ClubsProvider: React.SFC = ({children}) => {
  const {subscribeToClubs} = useClubsPrx();

  const subscribe = React.useMemo(
    () =>
      (subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Clubs.Club>) =>
        subscribeToClubs(subscriber, undefined),
    [subscribeToClubs],
  );

  const subscription = useSubscription(subscribe);

  return (
    <ClubsContext.Provider value={subscription}>
      {children}
    </ClubsContext.Provider>
  );
};

export const useClubs = () => {
  return React.useContext(ClubsContext);
};

export const useClub = (clubOrDisplayId?: string) => {
  const clubs = useClubs();
  return React.useMemo(() => {
    if (clubOrDisplayId == null) {
      return undefined;
    }
    const data = getResponseData(clubs) ?? [];
    return {
      ...clubs,
      data: [...data].find(
        (club) =>
          club.clubId === clubOrDisplayId || club.displayId === clubOrDisplayId,
      ),
    };
  }, [clubs, clubOrDisplayId]);
};

export const useClubPlayerGameStatsSubscription = (
  clubId: string | undefined,
  playerId: string,
  gameStatsType: 'cash' | 'tournament',
) => {
  const {subscribeToClubPlayerGameStats} = useClubsPrx();
  const subscribe = React.useMemo(
    () =>
      clubId == null
        ? undefined
        : (
            subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Clubs.ClubPlayerGameStats>,
          ) =>
            subscribeToClubPlayerGameStats(
              subscriber,
              clubId,
              [playerId],
              create(
                gameStatsType === 'cash'
                  ? Gazebo.Clubs.CashGameStatsType
                  : Gazebo.Clubs.TournamentStatsType,
                {},
              ),
            ),
    [clubId, playerId, gameStatsType, subscribeToClubPlayerGameStats],
  );
  const subscription = useSubscription(subscribe);
  return subscription.data?.[0];
};
