import * as React from 'react';
import {useEffect, useState} from 'react';
import ReactMarkdown from 'react-markdown';

import changelogUri from 'src/CHANGELOG.md';

import {iceProperties} from '../../iceProperties';
import {getClasses} from '../../utils/css';

import {NavigationBar} from './NavigationBar';

const classes = getClasses({
  root: {},
  text: {
    maxWidth: '100vw',
    marginTop: '5%',
    marginLeft: '20%',
  },
});

export const Changelog: React.FunctionComponent = () => {
  const backendVersion = iceProperties.getProperty('Webapp.Version');

  const [changelog, setChangelog] = useState('');
  useEffect(() => {
    const getChangelog = async () => {
      const changelog: string = await fetch(changelogUri).then((data) =>
        data.text(),
      );
      // Вырезаем текст про правила ведение ченжлога.
      const latestVersionIndex = changelog.search(/\n##/);
      console.log(latestVersionIndex);
      setChangelog(changelog.substring(latestVersionIndex));
    };
    getChangelog();
  }, []);

  return (
    <div className={classes.root}>
      <NavigationBar />
      <div className={classes.text}>
        <b>Backend version: {backendVersion}</b>
        <ReactMarkdown>{changelog}</ReactMarkdown>
      </div>
    </div>
  );
};
