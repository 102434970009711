import {MenuItem} from '@blueprintjs/core';
import * as React from 'react';

import {useAppRouteTo} from './utils/useAppRouteTo';

type MenuItemRouteToProps = {
  to: string;
  text: string;
  icon?: any;
};

/**
 * Элемент меню с возможностью перехода по внутренней ссылке
 */
export const MenuItemRouteTo: React.FunctionComponent<MenuItemRouteToProps> = ({
  to,
  text,
  icon,
  ...rest
}) => {
  const goTo = useAppRouteTo(to);

  return <MenuItem text={text} onClick={goTo} icon={icon} {...rest} />;
};
