import {Colors} from '@blueprintjs/core';
import {types as typestyleTypes} from 'typestyle';

import {Gazebo} from '@slices/Gazebo';

const clubs = {'&::before': {content: "'♣'"}} as const;
const clubsColor = Colors.GREEN3;
const diamonds = {'&::before': {content: "'♦'"}} as const;
const diamondsColor = Colors.BLUE3;
const hearts = {'&::before': {content: "'♥'"}} as const;
const heartsColor = Colors.RED3;
const spades = {'&::before': {content: "'♠'"}} as const;
const spadesColor = Colors.DARK_GRAY3;
const deuce = {'&::after': {content: "'2'"}} as const;
const three = {'&::after': {content: "'3'"}} as const;
const four = {'&::after': {content: "'4'"}} as const;
const five = {'&::after': {content: "'5'"}} as const;
const six = {'&::after': {content: "'6'"}} as const;
const seven = {'&::after': {content: "'7'"}} as const;
const eight = {'&::after': {content: "'8'"}} as const;
const nine = {'&::after': {content: "'9'"}} as const;
const ten = {'&::after': {content: "'T'"}} as const;
const jack = {'&::after': {content: "'J'"}} as const;
const queen = {'&::after': {content: "'Q'"}} as const;
const king = {'&::after': {content: "'K'"}} as const;
const ace = {'&::after': {content: "'A'"}} as const;

const reduce = (
  styles: CardsStyles,
  name: string,
  color: string,
  suite: any,
  value: any,
) => ({
  ...styles,
  [`&.${name}`]: {
    color,
    $nest: {...suite, ...value},
  },
});
type CardsStyles = typestyleTypes.NestedCSSSelectors;
export const cardsStyles = [...Object.values(Gazebo.Tables.Poker.Card)]
  .filter(
    (item): item is Gazebo.Tables.Poker.Card =>
      item instanceof Gazebo.Tables.Poker.Card,
  )
  .reduce(
    (styles: CardsStyles, item): CardsStyles => {
      switch (item.name as Gazebo.Tables.Poker.CardName) {
        case 'DeuceClubs':
          return reduce(styles, item.name, clubsColor, clubs, deuce);
        case 'ThreeClubs':
          return reduce(styles, item.name, clubsColor, clubs, three);
        case 'FourClubs':
          return reduce(styles, item.name, clubsColor, clubs, four);
        case 'FiveClubs':
          return reduce(styles, item.name, clubsColor, clubs, five);
        case 'SixClubs':
          return reduce(styles, item.name, clubsColor, clubs, six);
        case 'SevenClubs':
          return reduce(styles, item.name, clubsColor, clubs, seven);
        case 'EightClubs':
          return reduce(styles, item.name, clubsColor, clubs, eight);
        case 'NineClubs':
          return reduce(styles, item.name, clubsColor, clubs, nine);
        case 'TenClubs':
          return reduce(styles, item.name, clubsColor, clubs, ten);
        case 'JackClubs':
          return reduce(styles, item.name, clubsColor, clubs, jack);
        case 'QueenClubs':
          return reduce(styles, item.name, clubsColor, clubs, queen);
        case 'KingClubs':
          return reduce(styles, item.name, clubsColor, clubs, king);
        case 'AceClubs':
          return reduce(styles, item.name, clubsColor, clubs, ace);
        case 'DeuceDiamonds':
          return reduce(styles, item.name, diamondsColor, diamonds, deuce);
        case 'ThreeDiamonds':
          return reduce(styles, item.name, diamondsColor, diamonds, three);
        case 'FourDiamonds':
          return reduce(styles, item.name, diamondsColor, diamonds, four);
        case 'FiveDiamonds':
          return reduce(styles, item.name, diamondsColor, diamonds, five);
        case 'SixDiamonds':
          return reduce(styles, item.name, diamondsColor, diamonds, six);
        case 'SevenDiamonds':
          return reduce(styles, item.name, diamondsColor, diamonds, seven);
        case 'EightDiamonds':
          return reduce(styles, item.name, diamondsColor, diamonds, eight);
        case 'NineDiamonds':
          return reduce(styles, item.name, diamondsColor, diamonds, nine);
        case 'TenDiamonds':
          return reduce(styles, item.name, diamondsColor, diamonds, ten);
        case 'JackDiamonds':
          return reduce(styles, item.name, diamondsColor, diamonds, jack);
        case 'QueenDiamonds':
          return reduce(styles, item.name, diamondsColor, diamonds, queen);
        case 'KingDiamonds':
          return reduce(styles, item.name, diamondsColor, diamonds, king);
        case 'AceDiamonds':
          return reduce(styles, item.name, diamondsColor, diamonds, ace);
        case 'DeuceHearts':
          return reduce(styles, item.name, heartsColor, hearts, deuce);
        case 'ThreeHearts':
          return reduce(styles, item.name, heartsColor, hearts, three);
        case 'FourHearts':
          return reduce(styles, item.name, heartsColor, hearts, four);
        case 'FiveHearts':
          return reduce(styles, item.name, heartsColor, hearts, five);
        case 'SixHearts':
          return reduce(styles, item.name, heartsColor, hearts, six);
        case 'SevenHearts':
          return reduce(styles, item.name, heartsColor, hearts, seven);
        case 'EightHearts':
          return reduce(styles, item.name, heartsColor, hearts, eight);
        case 'NineHearts':
          return reduce(styles, item.name, heartsColor, hearts, nine);
        case 'TenHearts':
          return reduce(styles, item.name, heartsColor, hearts, ten);
        case 'JackHearts':
          return reduce(styles, item.name, heartsColor, hearts, jack);
        case 'QueenHearts':
          return reduce(styles, item.name, heartsColor, hearts, queen);
        case 'KingHearts':
          return reduce(styles, item.name, heartsColor, hearts, king);
        case 'AceHearts':
          return reduce(styles, item.name, heartsColor, hearts, ace);
        case 'DeuceSpades':
          return reduce(styles, item.name, spadesColor, spades, deuce);
        case 'ThreeSpades':
          return reduce(styles, item.name, spadesColor, spades, three);
        case 'FourSpades':
          return reduce(styles, item.name, spadesColor, spades, four);
        case 'FiveSpades':
          return reduce(styles, item.name, spadesColor, spades, five);
        case 'SixSpades':
          return reduce(styles, item.name, spadesColor, spades, six);
        case 'SevenSpades':
          return reduce(styles, item.name, spadesColor, spades, seven);
        case 'EightSpades':
          return reduce(styles, item.name, spadesColor, spades, eight);
        case 'NineSpades':
          return reduce(styles, item.name, spadesColor, spades, nine);
        case 'TenSpades':
          return reduce(styles, item.name, spadesColor, spades, ten);
        case 'JackSpades':
          return reduce(styles, item.name, spadesColor, spades, jack);
        case 'QueenSpades':
          return reduce(styles, item.name, spadesColor, spades, queen);
        case 'KingSpades':
          return reduce(styles, item.name, spadesColor, spades, king);
        case 'AceSpades':
          return reduce(styles, item.name, spadesColor, spades, ace);
        case 'JokerRed':
          return styles;
        case 'JokerBlack':
          return styles;
        default:
          throw new Error('Unexpected card name');
      }
    },
    [
      Colors.TURQUOISE5,
      Colors.VIOLET5,
      Colors.VERMILION5,
      Colors.RED5,
      Colors.LIME5,
      Colors.ORANGE5,
      Colors.INDIGO5,
      Colors.BLUE5,
      Colors.GOLD5,
    ].reduce((css, color, index) => {
      return {
        ...css,
        [`&[data-is-in-combination="${index}"]`]: {
          boxShadow: `5px 5px 15px ${color}`,
        },
        [`&[data-is-in-combination-${index}="${index}"]`]: {
          boxShadow: `5px 5px 15px ${color}`,
        },
      };
    }, {}),
  );
